export default {
    openConnectSmartMeterModal: false,
    openConfirmationModal: false,
    isSmartMeterConnected: false,
    openUtilityModal: false,
    openEnergyEfficiencyModal: false,
    openEditPropertyDetailsModal: false,
    openEnergyEfficiencyRatingModalfalse: false,
    openFinanceModal: false,
    utilities: [],
    defaultTrialSubscriptionInDays: '7'
};
