import { RolesEnum } from '@/core/packages/shared-library';
export const BILLING_ROUTE = 'billing';
export default {
    path: '/properties/:id/billing',
    name: BILLING_ROUTE,
    component: () => import('@/modules/properties-billing/pages/PropertiesBillingPage.vue'),
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Property listing',
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
