export default {
    setOpenConnectSmartMeterModal(state, value) {
        state.openConnectSmartMeterModal = value;
    },
    setOpenConfirmationModal(state, value) {
        state.openConfirmationModal = value;
    },
    setIsSmartMeterConnected(state, value) {
        state.isSmartMeterConnected = value;
    },
    setOpenUtilityModal(state, value) {
        state.openUtilityModal = value;
    },
    setOpenEnergyEfficiencyModal(state, value) {
        state.openEnergyEfficiencyModal = value;
    },
    setOpenEditPropertyDetailsModal(state, value) {
        state.openEditPropertyDetailsModal = value;
    },
    setOpenEnergyEfficiencyRatingModalfalse(state, value) {
        state.openEnergyEfficiencyRatingModalfalse = value;
    },
    setOpenFinanceModal(state, value) {
        state.openFinanceModal = value;
    },
    setUtilities(state, value) {
        state.utilities = value;
    },
};
