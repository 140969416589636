import estimateState from '@/core/constants/estimate';
export default {
    defaultProjectQuoteState: {
        notes: '',
        projectQuoteAttachments: [],
        projectId: null,
        projectQuoteStatusId: undefined,
        projectStages: [],
        confirmed: undefined,
        agreeTermsConsumerDisclosure: undefined,
        suggestedStartDate: null,
    },
    defaultStageState: {
        name: '',
        stageType: 'labour',
        projectStageStatusId: undefined,
        projectStageTasks: [],
        stageCostType: 'task',
        stageCost: 0,
        chipMessageStatusId: undefined,
        attachments: [],
        subTotal: 0,
        vat: 0,
        enableDuration: false,
        enableCost: false,
        totalDuration: 0,
    },
    defaultTaskState: {
        name: '',
        duration: null,
        quantity: null,
        amount: null,
        status: undefined,
    },
    totalFee: 0,
    type: estimateState.VARIABLE
};
