export default {
    currentMenuId: 1,
    tradePassportMenus: [
        {
            id: 1,
            name: 'Overview',
            icon: 'home-filled-icon.svg',
            route: 'trade-passport-overview',
            isDisabled: false
        },
        {
            id: 2,
            name: 'Specialism',
            icon: 'project-icon.svg',
            route: 'trade-passport-specialism',
            isDisabled: false
        },
        {
            id: 3,
            name: 'Certifications',
            icon: 'document-icon.svg',
            route: 'trade-passport-certifications',
            isDisabled: false
        },
        {
            id: 4,
            name: 'Companies',
            icon: 'services-icon.svg',
            route: 'trade-passport-companies',
            isDisabled: true
        },
        {
            id: 5,
            name: 'Projects',
            icon: 'tp-avatar.svg',
            route: 'trade-passport-projects',
            isDisabled: true
        },
        {
            id: 6,
            name: 'Insurances',
            icon: 'insurance-icon-2.svg',
            route: 'trade-passport-insurances',
            isDisabled: false
        },
        {
            id: 7,
            name: 'Reviews',
            icon: 'star-icon.svg',
            route: 'trade-passport-reviews',
            isDisabled: true
        },
        {
            id: 8,
            name: 'Gallery',
            icon: 'gallery.svg',
            route: 'trade-passport-gallery',
            isDisabled: true
        },
        {
            id: 9,
            name: 'Recommendations',
            icon: 'recommendations.svg',
            route: 'trade-passport-recommendations',
            isDisabled: true
        },
    ],
};
