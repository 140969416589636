import propertiesService from '@/core/services/properties.service';
export default {
    getProperties(context, filters = {}) {
        return propertiesService.getProperties(filters);
    },
    getProperty(context, id) {
        return propertiesService.getProperty(id);
    },
    createProperties(context, formData) {
        return propertiesService.createProperties(formData);
    },
    updateProperty(context, params) {
        const { propertyId, formData } = params;
        return propertiesService.updateProperty(propertyId, formData);
    },
    setServicesOnMenu({ commit }, value) {
        commit('setServicesOnMenu', value);
    },
    setProperty({ commit }, value) {
        commit('setProperty', value);
    },
    setPropertyMembers({ commit }, value) {
        commit('setPropertyMembers', value);
    },
    setUnsavedPropertyMembers({ commit }, values) {
        commit('setUnsavedPropertyMembers', values);
    },
    setAddedPropertyMembers({ commit }, value) {
        commit('setAddedPropertyMembers', value);
    },
    setOpenViewPropertyMapDialog({ commit }, value) {
        commit('setOpenViewPropertyMapDialog', value);
    },
    getMemberPermissions() {
        return propertiesService.getMemberPermissions();
    },
    getPropertyMembers(context, propertyId) {
        return propertiesService.getPropertyMembers(propertyId);
    },
    setIsMapView({ commit }, value) {
        commit('setIsMapView', value);
    },
    createPropertyMembers(context, params) {
        const { propertyId, formData } = params;
        return propertiesService.createPropertyMembers(propertyId, formData);
    },
    updatePropertyMember(context, params) {
        const { propertyId, propertyMemberId, formData } = params;
        return propertiesService.updatePropertyMember(propertyId, propertyMemberId, formData);
    },
    deletePropertyMember(context, params) {
        const { propertyId, propertyMemberId } = params;
        return propertiesService.deletePropertyMember(propertyId, propertyMemberId);
    },
};
