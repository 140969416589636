import * as CryptoJS from 'crypto-js';
import { AUTH_SESSION_EXPIRY, AUTH_TOKEN, AUTH_USER, CRYPTO_SECRET_KEY } from '@/core/constants';
import { RolesEnum } from '@/core/packages/shared-library';
import AuthService from '@/core/services/authentication.service';
import ImageTokenHelper from '../../../core/helpers/image-token.helper';
import { setLocalStorageWithExpiry } from '../../../core/utils/common';
export default {
    /**
     * Dispatch action for login. Sets the auth token in the login
     * @param context Vuex Context
     * @param authLogin
     * @returns
     */
    login(context, authLogin) {
        const { username, password } = authLogin;
        return AuthService.login(username, password)
            .then((response) => {
            const token = response.IdToken;
            if (token) {
                setLocalStorageWithExpiry(token, AUTH_TOKEN, AUTH_SESSION_EXPIRY);
                ImageTokenHelper.validateImageExpirationToken();
                return response;
            }
            return null;
        });
    },
    /**
     * Registers the user based on the selected role - TP or PO
     * @param context
     * @param userForm
     * @param role
     * @returns
     */
    register(context, userForm) {
        let path = '';
        const payload = {
            ...userForm,
        };
        if (payload.role === RolesEnum.PROJECT_OWNER) {
            path = 'property-owner';
        }
        else if (payload.role === RolesEnum.TRADESPERSON) {
            path = 'tradesperson';
        }
        // eslint-disable-next-line no-param-reassign
        delete payload.role;
        const currentUser = payload;
        if (currentUser.password && currentUser.confirmPassword) {
            currentUser.password = CryptoJS.AES.encrypt(currentUser.password, CRYPTO_SECRET_KEY).toString();
            currentUser.confirmPassword = CryptoJS.AES.encrypt(currentUser.confirmPassword, CRYPTO_SECRET_KEY).toString();
        }
        return AuthService.register(currentUser, path);
    },
    /**
     *
     * @param context
     * @param email
     * @returns
     */
    resendVerificationCode(context, email) {
        return AuthService.resendVerificationCode(email);
    },
    /**
     *
     * @param context
     * @param verificationCode
     * @returns
     */
    verifyVerificationCode(context, verificationCode) {
        return AuthService.verifyVerificationCode(verificationCode);
    },
    /**
     * Sends link to user's email
     * @param context
     * @param email
     * @returns
     */
    resetPassword(context, email) {
        return AuthService.resetPassword(email);
    },
    /**
     * Verify user reset password token
     * @param context
     * @param token
     * @returns
     */
    async verifyResetPasswordToken({ commit }, token) {
        const requestVerifyResetPasswordToken = await AuthService.verifyResetPasswordToken(token);
        if (requestVerifyResetPasswordToken) {
            const { id, email } = requestVerifyResetPasswordToken;
            commit('setForgotPasswordTempUser', { userId: id, email });
        }
        return requestVerifyResetPasswordToken;
    },
    setForgotPasswordTempUser({ commit }, params) {
        commit('setForgotPasswordTempUser', params);
    },
    /**
     * Change user password
     * @param getters
     * @param payload
     * @returns
     */
    async changePassword({ getters, commit }, payload) {
        const formData = {
            ...payload,
            userId: getters.forgotPasswordTempUser.userId,
            email: getters.forgotPasswordTempUser.email,
        };
        const requestChangePassword = await AuthService.changePassword(formData);
        if (requestChangePassword) {
            commit('setForgotPasswordTempUser', { userId: 0, email: '' });
        }
        return requestChangePassword;
    },
    /**
     * Dispatch action for logout
     * @returns
     */
    logout() {
        window.localStorage.removeItem(AUTH_TOKEN);
        window.localStorage.removeItem(AUTH_USER);
        window.localStorage.removeItem('hst');
        if (window?.Intercom) {
            window.Intercom('update', {
                hide_default_launcher: true
            });
        }
    },
};
