import UserWorkstationMemberService from '@/core/services/user-workstation-member.service';
export default {
    setInviteMembersModal({ commit }, value) {
        commit('setInviteMembersModal', value);
    },
    setRecentlyAdded({ commit }, value) {
        commit('setRecentlyAdded', value);
    },
    setUserWorkstationMembers({ commit }, userWorkstationMembers) {
        commit('setUserWorkstationMembers', userWorkstationMembers);
    },
    getUserWorkstationMembers(context, { userId, keyword }) {
        return UserWorkstationMemberService.getUserWorkstationMembers(userId, encodeURIComponent(keyword));
    },
    getMyUserWorkstationMembers(context, userId) {
        return UserWorkstationMemberService.getMyUserWorkstationMembers(userId);
    },
    createUserWorkstationMembers(context, { userId, members }) {
        return UserWorkstationMemberService.createUserWorkstationMembers(userId, { members });
    },
    updateUserWorkstationMembers(context, params) {
        const { id, userId, role } = params;
        return UserWorkstationMemberService.updateUserWorkstationMember(userId, id, { role });
    },
    acceptUserWorkstationMember(context, params) {
        const { userId, key } = params;
        return UserWorkstationMemberService.acceptUserWorkstationMember(userId, { key });
    },
    activateUserWorkstationMember(context, params) {
        const { id, userId } = params;
        return UserWorkstationMemberService.activateUserWorkstationMember(userId, id);
    },
    deleteUserWorkstationMember({ commit, getters }, { userId, userWorkstationMemberId }) {
        return UserWorkstationMemberService.deleteUserWorkstationMember(userId, userWorkstationMemberId)
            .then((response) => {
            const { userWorkstationMembers } = getters;
            let currentList = userWorkstationMembers;
            if (response) {
                currentList = currentList.filter((item) => item.id !== userWorkstationMemberId);
            }
            commit('setUserWorkstationMembers', currentList);
        });
    }
};
