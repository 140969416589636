import SearchConfigService from '@/core/services/search-config.service';
import { USERS_STORE } from '../users';
export default {
    async saveUserSearchConfig({ getters, dispatch }, userSearchConfig) {
        if (getters.userSearchConfig && getters.userSearchConfig.id) {
            dispatch('updateUserSearchConfig', userSearchConfig);
        }
        else {
            dispatch('createUserSearchConfig', userSearchConfig);
        }
    },
    async createUserSearchConfig({ commit }, userSearchConfig) {
        const saveUserConfigResponse = await SearchConfigService.createUserSearchConfig(userSearchConfig);
        commit('userSearchConfig', saveUserConfigResponse);
        return saveUserConfigResponse;
    },
    async updateUserSearchConfig({ commit, getters }, userSearchConfig) {
        const saveUserConfigResponse = await SearchConfigService.updateUserSearchConfig(getters.userSearchConfig.id, userSearchConfig);
        commit('userSearchConfig', saveUserConfigResponse);
        return saveUserConfigResponse;
    },
    async getUserSearchConfigByFilterKey({ commit, rootGetters }, search = {}) {
        const authenticatedUserDetails = rootGetters[`${USERS_STORE}/user`];
        const getUserSearchConfigResponse = await SearchConfigService.getUserSearchConfigByFilterKey(authenticatedUserDetails.id, search);
        commit('userSearchConfig', getUserSearchConfigResponse);
        return getUserSearchConfigResponse;
    }
};
