import StringHelper from '@/core/helpers/string.helper';
import { AspspPaymentDestinationTypeEnum } from '@/core/packages/shared-library';
import ProjectStageDepositsService from '@/core/services/project-stage-deposits.service';
export default {
    /**
     * Create project stage deposit
     * @param context
     * @param formData
     * @returns any[]
     */
    async createProjectStageDeposit(context, formData) {
        const payload = {
            ...formData,
        };
        delete payload.method; // remove method banklist before submitting
        const response = await ProjectStageDepositsService.createProjectStageDeposit(payload);
        return response;
    },
    /**
     * Create project stage deposit all
     * @param context
     * @param formData
     * @returns any[]
     */
    async createAllProjectStageDeposit(context, formData) {
        const payload = {
            ...formData,
        };
        delete payload.method; // remove method banklist before submitting
        const response = await ProjectStageDepositsService.createAllProjectStageDeposit(payload);
        return response;
    },
    /**
     * Verify project stage status deposit
     * @param context
     * @param paymentInitationId
     * @returns any[]
     */
    verifyProjectStageDeposit(context, paymentInitationId) {
        return ProjectStageDepositsService.verifyProjectStageDeposit(paymentInitationId);
    },
    /**
     * Approve release project stage deposit
     * @param context
     * @param formData
     * @returns any[]
     */
    async approveReleaseProjectStageDeposit({ dispatch }, payload) {
        try {
            // fetch Project Account ID
            const formData = await dispatch('transformApproveReleaseStageRequestDto', { ...payload });
            return ProjectStageDepositsService.approveReleaseProjectStageDeposit(formData);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Get project stage deposit status
     * @param context
     * @param paymentInitationId
     * @returns any[]
     */
    getProjectStageDepositStatus(context, paymentInitationId) {
        return ProjectStageDepositsService.getProjectStageDepositStatus(paymentInitationId);
    },
    /**
     * Get project stage deposit
     * @param context
     * @param paymentInitationId
     * @returns any[]
     */
    getProjectStageDeposit(context, paymentInitationId) {
        return ProjectStageDepositsService.getProjectStageDeposit(paymentInitationId);
    },
    transformApproveReleaseStageRequestDto(context, payload) {
        const formData = {
            amount: parseFloat(parseFloat(payload.stage.stageCost).toFixed(2)),
            destination: {
                type: AspspPaymentDestinationTypeEnum.ACCOUNT,
                id: payload.userCustomerAccount.accountId,
            },
            reference: StringHelper.cleanPaymentReference(payload.stage.refId),
            sourceAccountId: payload.projectAccount.accountId,
            stageServiceFee: payload.stageServiceFee
        };
        return formData;
    },
};
