import { RolesEnum } from '@/core/packages/shared-library';
import { PROJECTS_ROUTE } from '../../projects/routes';
/**
 * Project Quotes Overview page
 */
export const PROJECT_QUOTE_OVERVIEW_ROUTE = 'project-quote-overview';
export default {
    path: '/projects/:projectRefId/quote-overview',
    name: PROJECT_QUOTE_OVERVIEW_ROUTE,
    props: {
        default: (route) => ({ projectRefId: route.params.projectRefId })
    },
    components: {
        default: () => import('@/modules/project-quote-overview/pages/ProjectQuoteOverviewPage.vue'),
        headerMenu: () => import('@/core/components/project/ProjectOverviewMenu.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Quote Overview',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: false
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-restricted-globals
        if (!to.params.projectRefId /* || isNaN(to.params.projectRefId) */) {
            next({ name: PROJECTS_ROUTE });
        }
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
