import { RolesEnum } from '@/core/packages/shared-library';
import PropertyDetailsChildrenRoute from '@/modules/property-details-children/routes';
export const PROPERTY_DETAILS = 'property-details';
export default {
    path: '/properties/:id/property-details/',
    name: PROPERTY_DETAILS,
    redirect: '/properties/property-details/home',
    components: {
        default: () => import('@/modules/property-details/pages/PropertyDetailsPage.vue'),
        headerMenu: () => import('@/core/components/property-details/PropertyDetailsMenu.vue')
    },
    children: [
        ...PropertyDetailsChildrenRoute
    ],
    meta: {
        disableRoute: false,
        title: 'Property Details',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
