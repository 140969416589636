/**
 * property analytics route page
 */
export const PROPERTY_ANALYTICS_PLANNING_ROUTE = 'property-analytics-planning';
export default {
    path: '/property-analytics-planning',
    name: PROPERTY_ANALYTICS_PLANNING_ROUTE,
    component: () => import('@/modules/property-analytics-planning/pages/PropertyAnalyticsPlanningPage.vue'),
    meta: {
        disableRoute: false,
        validateAuth: true,
    }
};
