export default {
    insurances: [],
    currentJourney: 1,
    previousJourney: null,
    isCompletedJourney: false,
    firstApplicantData: {
        applicantTitle: '',
        firstName: 'Allar',
        lastName: 'Ahtmann',
        dob: '26/01/1989',
        quoteNumber: 'DFSG35545',
        postcode: 'KT199JG'
    },
    secondApplicantData: {
        applicantTitle: '',
        firstName: '',
        lastName: '',
        dob: '',
    },
    isApplyInsurance: false,
    agreedAllAgreement: false,
    phoneAndBikesModal: false,
    addSecondApplicantModal: false,
};
