export default {
    resetJobClientFormState(state) {
        state.jobClientForm = state.defaultJobClientForm;
    },
    setJobClientForm(state, value) {
        state.jobClientForm = value;
    },
    resetAddressFormState(state) {
        state.addressForm = state.defaultAddressForm;
    },
    setAddressForm(state, value) {
        state.addressForm = value;
    },
    setConfirmDeleteModal(state, value) {
        state.confirmDeleteModal = value;
    },
    setConfirmDeleteAddressModal(state, value) {
        state.confirmDeleteAddressModal = value;
    },
    setSelectedId(state, value) {
        state.selectedId = value;
    },
    setSelectedAddressId(state, value) {
        state.selectedAddressId = value;
    },
    setSelectedClient(state, value) {
        state.selectedClient = value;
    },
    setSelectedAddress(state, value) {
        state.selectedAddress = value;
    },
};
