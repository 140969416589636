/**
 * Cohorts route page
 */
export const COHORTS_ROUTE = 'cohorts';
export default {
    path: '/cohorts',
    name: COHORTS_ROUTE,
    component: () => import('@/modules/cohorts/pages/CohortsPage.vue'),
    meta: {
        title: 'Cohorts',
    },
};
