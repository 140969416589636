import $axios from '../utils/axios-api-config';
export default {
    /**
     * create project stage deposit
     * @returns any[]
     */
    createProjectStageDeposit(formData) {
        return $axios.post('/project-stage-deposits', formData);
    },
    /**
     * create project stage deposit all
     * @returns any[]
     */
    createAllProjectStageDeposit(formData) {
        return $axios.post('/project-stage-deposits/pay-all', formData);
    },
    /**
     * Verify project stage status deposit
     * @param paymentInitationId
     * @returns any[]
     */
    verifyProjectStageDeposit(paymentInitationId) {
        return $axios.post(`/project-stage-deposits/${paymentInitationId}/verify-stage-deposit-payment`);
    },
    /**
     * Approve release project stage deposit
     * @param formData ProjectApproveReleaseStageDepositRequestDto
     * @returns any[]
     */
    approveReleaseProjectStageDeposit(formData) {
        return $axios.post('project-stage-deposits/approve-release', formData);
    },
    /**
     * Get project stage deposit status
     * @param paymentInitationId
     * @returns any[]
     */
    getProjectStageDepositStatus(paymentInitationId) {
        return $axios.get(`/project-stage-deposits/${paymentInitationId}/status`);
    },
    /**
     * Get project stage deposit
     * @param paymentInitationId
     * @returns any[]
     */
    getProjectStageDeposit(paymentInitationId) {
        return $axios.get(`/project-stage-deposits/${paymentInitationId}`);
    },
};
