export default {
    setCoursePrice(state, payload) {
        state.coursePrice = payload;
    },
    setServiceCharge(state, payload) {
        state.serviceCharge = payload;
    },
    setCourseDetails(state, payload) {
        state.courseDetails = payload;
    },
    setPaymentMethod(state, payload) {
        state.paymentMethod = payload;
    }
};
