import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Post project route
 */
export const PROJECT_POST_ROUTE = 'post-project';
export default {
    path: '/properties/:propertyId/post-project',
    // path: '/post-project',
    name: PROJECT_POST_ROUTE,
    components: {
        default: () => import('@/modules/project-post/pages/PostProjectPage.vue'),
        headerMenu: () => import('@/core/components/project-post/ProjectPostHeaderMenuComponent.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        // title: 'Start a new project',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: false
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
