import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
import ProjectService from '@/core/services/projects.service';
export default {
    /**
     * Get all category list
     * @param commit
     * @returns Category[]
     */
    async getCategories({ commit }) {
        const categories = await ProjectService.getCategories();
        // We also update the state every call on this method to update the project categories
        if (categories) {
            commit('setCategories', categories);
        }
        return categories;
    },
    /**
     * Get all category list
     * @param commit
     * @returns Category[]
     */
    async getProjectJobsCategories({ commit }) {
        const searchCategories = await ProjectService.getProjectJobsCategories();
        if (searchCategories) {
            commit('setSearchCategories', searchCategories);
        }
        return searchCategories;
    },
    /**
     * Get all category list
     * @param commit
     * @returns Categories[]
     */
    async getProjectJobsCategoriesByKeywords({ commit }, keywords) {
        const searchCategories = await ProjectService.getProjectJobsCategoriesByKeywords(keywords);
        console.log(searchCategories, 'searchCategories actionsss');
        if (searchCategories) {
            commit('setSearchCategories', searchCategories);
        }
        return searchCategories;
    },
    /**
     * Get all project quote status with projectQuoteCount
     * @param context
     * @param type
     * @returns ProjectJobStatusListResponseDto
     */
    async getProjectQuoteStatusWithCountState(context, type) {
        return ProjectService.getProjectStatusWithCountStatus(type);
    },
    /**
     * Get all project job status with projectCount
     * @param context
     * @param type
     * @returns ProjectJobStatusListResponseDto
     */
    async getProjectJobStatusWithCountState(context, type) {
        return ProjectService.getProjectStatusWithCountStatus(type);
    },
    /**
     * Get all project list
     * @param context
     * @param filters
     * @returns ProjectListResponseDto
     */
    async getProjectPageList(context, filters = {}) {
        return ProjectService.getProjectPageList(filters);
    },
    /**
     * Get all project job list
     * @param context
     * @param filters
     * @returns JobListRequestDto
     */
    async getProjectJobList(context, filters = {}) {
        return ProjectService.getProjectJobs(filters);
    },
    /**
     * Get all project my quotes list
     * @param context
     * @param filters
     * @returns JobListRequestDto
     */
    async getProjectQuotesList(context, filters = {}) {
        return ProjectService.getProjectMyQuotes(filters);
    },
    /**
     * Get all project job list
     * @param context
     * @param filters
     * @returns JobListRequestDto
     */
    async getProjectMyJobList(context, filters = {}) {
        return ProjectService.getProjectMyJobs(filters);
    },
    /**
     * Set the project statuses
     * @param context
     * @returns ProjectStatusListResponseDto
     */
    async getProjectStatuses({ commit }) {
        return ProjectService.getProjectStatus()
            .then((response) => {
            commit('setProjectStatuses', response.data);
            return response;
        });
    },
    /**
     * Get project details
     * @param context
     * @param projectRefId
     * @returns GetProjectResponseDto
     */
    getProjectByRefId(context, projectRefId) {
        return ProjectService.getProjectByRefId(projectRefId);
    },
    /**
     * Cancel existing project
     * @param projectId
     * @return
     */
    cancelProject(context, projectId) {
        const payload = {
            projectJobStatusId: ProjectJobStatusEnum.CANCELLED,
        };
        return ProjectService.updateProject(projectId, payload);
    },
    /**
     * Delete existing project
     * @param projectId
     * @return
     */
    deleteProject(context, projectId) {
        return ProjectService.deleteProject(projectId);
    },
    /**
     * Update existing project
     * @param context
     * @param formData
     * @returns
     */
    updateProject(context, { projectId, formData }) {
        const payload = {
            ...formData
        };
        // const removeUnchangedAttributes = [
        //   'dateCreated', 'dateUpdated', 'dateDeleted',
        //   'refId', 'geofenceId', 'latitude',
        //   'longitude', 'projectQuoteCount', 'projectAttachments',
        //   'projectJobStatus', 'projectStatus'];
        // removeUnchangedAttributes.forEach((key) => {
        //   delete payload[key];
        // });
        // if (payload?.categories?.length) {
        //   payload.categories = payload.categories.map((category) => {
        //     const [, subCategory] = category;
        //     return subCategory;
        //   });
        // }
        return ProjectService.updateProject(projectId, payload);
    },
    setCurrentPageViewMapping(context, menu) {
        context.commit('setCurrentPageViewMapping', menu);
    },
    setSelectedProjectJobStatus({ commit }, projectJobStatus) {
        commit('setSelectedProjectJobStatus', projectJobStatus);
    },
    getProjectJobs(context, filters = {}) {
        return ProjectService.getProjectJobs(filters);
    },
    setOpenSummaryEstimateDrawer({ commit }, value) {
        commit('setOpenSummaryEstimateDrawer', value);
    },
    setOpenEscrowDrawer({ commit }, value) {
        commit('setOpenEscrowDrawer', value);
    },
    setEnableProjectEscrowSidebar({ commit }, value) {
        commit('setEnableProjectEscrowSidebar', value);
    },
    setHasGetProjectFinished({ commit }, value) {
        commit('setHasGetProjectFinished', value);
    },
    setIsProjectReadyToWork({ commit }, value) {
        commit('setIsProjectReadyToWork', value);
    },
    setCurrentSelectedProject({ commit }, value) {
        commit('setCurrentSelectedProject', value);
    },
    setProjectAccountBalance({ commit }, value) {
        commit('setProjectAccountBalance', value);
    },
    setProjects({ commit }, value) {
        commit('setProjects', value);
    },
    setOpenReviewModal({ commit }, value) {
        commit('setOpenReviewModal', value);
    },
    setSubCategories({ commit }, value) {
        commit('setSubCategories', value);
    },
    setMainCategories({ commit }, value) {
        commit('setMainCategories', value);
    },
    setToBePassedCategories({ commit }, value) {
        commit('setToBePassedCategories', value);
    },
    setFindJobsSearchValue({ commit }, value) {
        commit('setFindJobsSearchValue', value);
    },
    setCategoryList({ commit }, value) {
        commit('setCategoryList', value);
    },
};
