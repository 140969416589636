import $axios from '@/core/utils/axios-api-config';
export default {
    getUserWorkstationMembers(userId, keyword) {
        return $axios.get(`user-workstations/${userId}/members?search=${keyword}`);
    },
    getMyUserWorkstationMembers(userId) {
        return $axios.get(`user-workstations/${userId}/members/${userId}`);
    },
    createUserWorkstationMembers(userId, userWorkstationMembers) {
        return $axios.post(`user-workstations/${userId}/members`, userWorkstationMembers);
    },
    updateUserWorkstationMember(userId, id, userWorkstationMember) {
        return $axios.put(`user-workstations/${userId}/members/${id}`, userWorkstationMember);
    },
    acceptUserWorkstationMember(userId, userWorkstationMember) {
        return $axios.put(`user-workstations/${userId}/members/accept`, userWorkstationMember);
    },
    activateUserWorkstationMember(userId, id) {
        return $axios.put(`user-workstations/${userId}/members/${id}/activate`);
    },
    deleteUserWorkstationMember(userId, id) {
        return $axios.delete(`user-workstations/${userId}/members/${id}`);
    },
};
