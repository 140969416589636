import { NotificationStateEnum } from '@/core/packages/shared-library';
import NotificationService from '@/core/services/notifications.service';
export default {
    // init({ dispatch }: { dispatch: any }) {
    //   // fetch notification list
    //   return dispatch('fetchNotificationList');
    // },
    async fetchNotificationList({ commit }) {
        const response = await NotificationService.getUserNotifications();
        commit('setNotificationList', response);
        return response;
    },
    async pushNotificationItem({ commit, dispatch }, record) {
        commit('pushNotificationItem', record);
        await dispatch('setHasNewNotifications', true);
    },
    toggleDrawer({ commit, getters }) {
        commit('setToggleDrawer');
        if (getters.drawerOpen)
            commit('setHasNewNotifications', !getters.drawerOpen);
    },
    setHasNewNotifications({ commit }, value) {
        commit('setHasNewNotifications', value);
    },
    markAllNotificationAsRead({ dispatch, getters }) {
        if (getters.notificationList.length) {
            const unreadNotificationList = getters.notificationList.filter((notification) => notification.state === NotificationStateEnum.UNREAD);
            if (unreadNotificationList?.length) {
                unreadNotificationList.forEach((notification) => {
                    dispatch('updateNotificationState', notification);
                });
            }
        }
    },
    async updateNotificationState({ commit }, payload) {
        const formData = {};
        if (payload.state) {
            formData.state = payload.state === NotificationStateEnum.UNREAD ? NotificationStateEnum.READ : NotificationStateEnum.UNREAD;
        }
        const response = await NotificationService.updateNotification(payload.id, formData);
        commit('updateNotificationState', response);
        return response;
    },
    async removeNotification({ commit }, payload) {
        const response = await NotificationService.removeNotification(payload.id);
        commit('removeNotification', payload);
        return response;
    }
};
