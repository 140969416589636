import $axios from '../utils/axios-api-config';
export default {
    getCourses(options) {
        const params = new URLSearchParams(options);
        return $axios.get(`/courses?${params.toString()}`);
    },
    getCourse(courseId) {
        return $axios.get(`/courses/${courseId}`);
    },
    getUserCourses(userId, options) {
        const params = new URLSearchParams(options);
        return $axios.get(`/user/${userId}/courses?${params.toString()}`);
    },
};
