export default {
    setDateWithTime(state, dateWithTime) {
        state.dateWithTime = dateWithTime;
    },
    setOriginalSchedule(state, originalSchedule) {
        state.originalSchedule = originalSchedule;
    },
    resetCalendarState(state) {
        state.scheduleForm = state.defaultScheduleForm;
    },
    setViewEventDetailsModal(state, value) {
        state.viewEventDetailsModal = value;
    },
};
