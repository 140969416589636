import ProjectsService from '@/core/services/projects.service';
export default {
    /**
     * Reset project details state
     * @param none
     * @returns
     */
    resetProjectState(context) {
        context.commit('resetProjectState');
    },
    /**
     * Remove the project atttachment file and update the attachments state
     * @param state
     * @param file
     */
    removeProjectAttachmentFile({ commit }, file) {
        commit('removeProjectAttachmentFile', file);
    },
    /**
     * Add new attachment file to the project attachment entity
     * @param param0
     * @param file
     */
    updateProjectAttachments({ commit }, file) {
        commit('updateProjectAttachments', file);
    },
    /**
     * Saving new project
     * @param context
     * @param createProjectRequestDto CreateProjectRequestDto
     * @returns
     */
    async saveProject(context, createProjectRequestDto) {
        return ProjectsService.saveProject(createProjectRequestDto)
            .then((response) => {
            // console.log(context.state.defaultProjectState);
            context.commit('resetProjectState', context.state.defaultProjectState);
            return response;
        });
    },
    /**
     * Saving draft project
     * @param context
     * @param createProjectRequestDto CreateProjectRequestDto
     * @returns
     */
    async saveDraftProject(context, createProjectRequestDto) {
        return ProjectsService.saveProject(createProjectRequestDto)
            .then((response) => {
            // console.log(context.state.defaultProjectState);
            context.commit('resetProjectState', context.state.defaultProjectState);
            return response;
        });
    },
    setEpcRecommendationProjectDetails({ commit }, value) {
        commit('setEpcRecommendationProjectDetails', value);
    },
    setOpenProjectDetailsFormModal({ commit }, value) {
        commit('setOpenProjectDetailsFormModal', value);
    },
    setOpenProjectStatusModal({ commit }, value) {
        commit('setOpenProjectStatusModal', value);
    },
};
