import { RolesEnum } from '@/core/packages/shared-library';
export const DASHBOARD_ROUTE = 'dashboard';
export default {
    path: '/dashboard',
    name: DASHBOARD_ROUTE,
    components: {
        default: () => import('@/modules/dashboard/pages/DashboardPage.vue'),
        profileStatus: () => import('@/modules/dashboard/components/DashboardHeaderStatus.vue'),
    },
    props: (route) => ({
        withdrawalVerified: route.params.withdrawalVerified,
        openWithdrawalForm: route.params.openWithdrawalForm,
        withdrawalDetails: route.params.withdrawalDetails,
        openUserReferAndEarnModal: route.params.openUserReferAndEarnModal,
        openUserBuildProfileModal: route.params.openUserBuildProfileModal,
        openUserWelcomeDialog: route.params.openUserWelcomeDialog,
    }),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER],
        title: 'Your Dashboard',
        useSecondaryHeader: true,
        showProfile: true
    },
};
