export default {
    setCurrentJourney(state, value) {
        state.currentJourney = value;
    },
    resetJourneyCompleted(state) {
        state.journeyCompleted = [];
    },
    setJourneyCompleted(state, value) {
        state.journeyCompleted.push(value);
    },
    setStatusProjectType(state, payload) {
        state.status = payload;
    },
    setProjectDescription(state, payload) {
        state.description = payload;
    },
    setProjectCategories(state, payload) {
        state.categories = payload;
    },
    setProjectMembers(state, value) {
        state.projectMembers = value;
    },
    setUnsavedProjectMembers(state, values) {
        state.unsavedProjectMembers = values;
    },
    setAddedProjectMembers(state, value) {
        state.addedProjectMembers = value;
    },
    setProjectBudget(state, value) {
        state.projectBudget = value;
    },
    setLeavePageModal(state, payload) {
        state.leavePageModal = payload;
    },
    setCancelPageModal(state, payload) {
        state.cancelPageModal = payload;
    },
    setPostProjectConfirmationModal(state, payload) {
        state.postProjectConfirmationModal = payload;
    },
};
