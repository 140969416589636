export default [
    {
        path: '/new-project',
        name: 'new-project',
        component: () => import('@/modules/project-dashboard-children/pages/NewProjectPage.vue'),
    },
    {
        path: '/active-project',
        name: 'active-project',
        component: () => import('@/modules/project-dashboard-children/pages/ActiveProjectPage.vue'),
    },
    {
        path: '/completed-project',
        name: 'completed-project',
        component: () => import('@/modules/project-dashboard-children/pages/CompletedProject.vue'),
    },
];
