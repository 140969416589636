import jobTemplateService from '@/core/services/job-template.service';
export default {
    getMyJobTemplates() {
        return jobTemplateService.getMyJobTemplates();
    },
    getJobTemplate(context, id) {
        return jobTemplateService.getJobTemplate(id);
    },
    createMyJobTemplate(context, formData) {
        return jobTemplateService.createMyJobTemplate(formData);
    },
    updateMyJobTemplate(context, options) {
        const { id, formData } = options;
        return jobTemplateService.updateMyJobTemplate(id, formData);
    },
    deleteMyJobTemplate(context, id) {
        return jobTemplateService.deleteMyJobTemplate(id);
    },
    setEstimateForm({ commit }, value) {
        commit('setEstimateForm', value);
    },
    setSelectedPhaseIndex({ commit }, value) {
        commit('setSelectedPhaseIndex', value);
    },
    setTemplateName({ commit }, value) {
        commit('setTemplateName', value);
    },
    setSelectedJobTemplate({ commit }, value) {
        commit('setSelectedJobTemplate', value);
    },
    addProjectStage({ commit }) {
        const newProjectStage = {
            name: '',
            stageType: 'labour',
            totalDuration: 0,
            durationType: 1,
            stageCost: 0,
            projectStageTasks: [],
            isNew: true,
            isUsePhaseTotal: null,
            requiredDeposit: false,
            vat: 0,
            subTotal: null,
            subTotalDisplay: '',
            note: '',
            startDate: null,
        };
        commit('addProjectStageMutation', newProjectStage);
    },
    setShowPhaseUploadAttachmentModal({ commit }, value) {
        commit('setShowPhaseUploadAttachmentModal', value);
    },
    setShowTaskUploadAttachmentModal({ commit }, value) {
        commit('setShowTaskUploadAttachmentModal', value);
    },
    setShowCreateTemplateConfirmationModal({ commit }, value) {
        commit('setShowCreateTemplateConfirmationModal', value);
    },
    setShowUseTemplateModal({ commit }, value) {
        commit('setShowUseTemplateModal', value);
    },
    setShowSubmitEstimateConfirmationModal({ commit }, value) {
        commit('setShowSubmitEstimateConfirmationModal', value);
    },
    setIsOnEstimateForm({ commit }, value) {
        commit('setIsOnEstimateForm', value);
    },
    setConfirmDeleteModal({ commit }, value) {
        commit('setConfirmDeleteModal', value);
    },
    setIsFormReady({ commit }, value) {
        commit('setIsFormReady', value);
    },
    setConfirmLoadTemplateModal({ commit }, value) {
        commit('setConfirmLoadTemplateModal', value);
    },
    setSelectedTemplateId({ commit }, value) {
        commit('setSelectedTemplateId', value);
    },
    setFormData({ commit }, value) {
        commit('setFormData', value);
    },
    setSelectDepositModal({ commit }, value) {
        commit('setSelectDepositModal', value);
    },
    setIsTemplateCreate({ commit }, value) {
        commit('setIsTemplateCreate', value);
    },
    setIsEstimateLoadedModified({ commit }, value) {
        commit('setIsEstimateLoadedModified', value);
    },
    setHasLoadedTemplate({ commit }, value) {
        commit('setHasLoadedTemplate', value);
    },
    setLoadedEstimate({ commit }, value) {
        commit('setLoadedEstimate', value);
    },
    setIsFormReadOnly({ commit }, value) {
        commit('setIsFormReadOnly', value);
    },
    setLockTemplateModifiedChecker({ commit }, value) {
        commit('setLockTemplateModifiedChecker', value);
    },
    setSelectedAssignMembers({ commit }, value) {
        commit('setSelectedAssignMembers', value);
    },
    setAssignMembersModal({ commit }, value) {
        commit('setAssignMembersModal', value);
    },
    setAssignMembers({ commit }, value) {
        commit('setAssignMembers', value);
    },
    setMemberList({ commit }, value) {
        commit('setMemberList', value);
    },
    setShowUpdateEstimateConfirmationModal({ commit }, value) {
        commit('setShowUpdateEstimateConfirmationModal', value);
    },
};
