/**
 * Clone the object using the native of clonning
 * @param value
 * @returns
 */
export const cloneObject = (value) => {
    return JSON.parse(JSON.stringify(value));
};
/** Local Storage set key with expiry
 * @param value
 * @param key
 * @param ttl
  */
export const setLocalStorageWithExpiry = (value, key, ttl) => {
    const now = new Date();
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value,
        expiry: now.getTime() + ttl,
    };
    window.localStorage.setItem(key, JSON.stringify(item));
};
/** Local Storage get key with expiry
 * @param value
 * @param key
 * @param ttl
  */
export const getLocalStorageWithExpiry = (key) => {
    const itemStr = window.localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        window.localStorage.removeItem(key);
        return null;
    }
    return item.value;
};
/** Remove local storage by key
 * @param value
 * @param key
 * @param ttl
  */
export const removeLocalStorageByKey = (key) => {
    window.localStorage.removeItem(key);
};
