export default {
    estimateForm: (state) => state.estimateForm,
    defaultEstimateForm: (state) => state.defaultEstimateForm,
    selectedPhaseIndex: (state) => state.selectedPhaseIndex,
    templateName: (state) => state.templateName,
    showPhaseUploadAttachmentModal: (state) => state.showPhaseUploadAttachmentModal,
    showTaskUploadAttachmentModal: (state) => state.showTaskUploadAttachmentModal,
    showCreateTemplateConfirmationModal: (state) => state.showCreateTemplateConfirmationModal,
    showUseTemplateModal: (state) => state.showUseTemplateModal,
    showSubmitEstimateConfirmationModal: (state) => state.showSubmitEstimateConfirmationModal,
    selectedJobTemplate: (state) => state.selectedJobTemplate,
    isOnEstimateForm: (state) => state.isOnEstimateForm,
    confirmDeleteModal: (state) => state.confirmDeleteModal,
    isFormReady: (state) => state.isFormReady,
    defaultProjectStageForm: (state) => state.defaultProjectStageForm,
    confirmLoadTemplateModal: (state) => state.confirmLoadTemplateModal,
    selectedTemplateId: (state) => state.selectedTemplateId,
    formData: (state) => state.formData,
    selectDepositModal: (state) => state.selectDepositModal,
    isTemplateCreate: (state) => state.isTemplateCreate,
    isEstimateLoadedModified: (state) => state.isEstimateLoadedModified,
    hasLoadedTemplate: (state) => state.hasLoadedTemplate,
    loadedEstimate: (state) => state.loadedEstimate,
    isFormReadOnly: (state) => state.isFormReadOnly,
    lockTemplateModifiedChecker: (state) => state.lockTemplateModifiedChecker,
    selectedAssignMembers: (state) => state.selectedAssignMembers,
    assignMembersModal: (state) => state.assignMembersModal,
    assignMembers: (state) => state.assignMembers,
    memberList: (state) => state.memberList,
    showUpdateEstimateConfirmationModal: (state) => state.showUpdateEstimateConfirmationModal,
};
