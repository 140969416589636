import { RolesEnum } from '@/core/packages/shared-library';
export const PROJECT_ESTIMATE_ROUTE = 'project-estimate';
export default {
    path: '/projects/:projectRefId/estimate',
    name: PROJECT_ESTIMATE_ROUTE,
    components: {
        default: () => import('@/modules/project-estimate/pages/EstimateFormPage.vue')
    },
    meta: {
        title: 'Estimate',
        authorize: [RolesEnum.TRADESPERSON],
        showBackButton: true
    }
};
