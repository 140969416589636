import projectService from '@/core/services/projects.service';
export default {
    setCurrentJourney({ commit }, value) {
        commit('setCurrentJourney', value);
    },
    resetJourneyCompleted({ commit }) {
        commit('resetJourneyCompleted');
    },
    setJourneyCompleted({ commit }, value) {
        commit('setJourneyCompleted', value);
    },
    setStatusProjectType({ commit }, payload) {
        commit('setStatusProjectType', payload);
    },
    setProjectDescription({ commit }, payload) {
        commit('setProjectDescription', payload);
    },
    setProjectCategories({ commit }, payload) {
        commit('setProjectCategories', payload);
    },
    setProjectMembers({ commit }, value) {
        commit('setProjectMembers', value);
    },
    setUnsavedProjectMembers({ commit }, values) {
        commit('setUnsavedProjectMembers', values);
    },
    setAddedProjectMembers({ commit }, value) {
        commit('setAddedProjectMembers', value);
    },
    getMemberPermissions() {
        return projectService.getMemberPermissions();
    },
    getProjectMembers(context, projectId) {
        return projectService.getProjectMembers(projectId);
    },
    getProjectMembersPokedState(context, projectId) {
        return projectService.getProjectMembersPokedState(projectId);
    },
    createProjectMembers(context, params) {
        const { projectId, formData } = params;
        return projectService.createProjectMembers(projectId, formData);
    },
    updateProjectMember(context, params) {
        const { projectId, projectMemberId, formData } = params;
        return projectService.updateProjectMember(projectId, projectMemberId, formData);
    },
    deleteProjectMember(context, params) {
        const { projectId, projectMemberId } = params;
        return projectService.deleteProjectMember(projectId, projectMemberId);
    },
    setProjectBudget({ commit }, value) {
        commit('setProjectBudget', value);
    },
    setLeavePageModal({ commit }, value) {
        commit('setLeavePageModal', value);
    },
    setCancelPageModal({ commit }, value) {
        commit('setCancelPageModal', value);
    },
    setPostProjectConfirmationModal({ commit }, value) {
        commit('setPostProjectConfirmationModal', value);
    },
};
