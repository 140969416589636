export default {
    insurances: (state) => state.insurances,
    getCurrentJourney: (state) => state.currentJourney,
    getPreviousJourney: (state) => state.previousJourney,
    getFirstApplicantData: (state) => state.firstApplicantData,
    getSecondApplicantData: (state) => state.secondApplicantData,
    getIsApplyInsurance: (state) => state.isApplyInsurance,
    getAgreedAllAgreement: (state) => state.agreedAllAgreement,
    getIsCompletedJourney: (state) => state.isCompletedJourney,
    getPhoneAndBikesModal: (state) => state.phoneAndBikesModal,
    getAddSecondApplicantModal: (state) => state.addSecondApplicantModal,
};
