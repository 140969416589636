export default {
    path: '/users/:userId/profile',
    name: 'account/profile',
    props: {
        default: (route) => ({ userId: route.params.userId }),
    },
    component: () => import('@/modules/account-profile/pages/AccountProfilePage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Profile'
    }
};
