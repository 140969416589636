export default {
    setCoursePrice({ commit }, value) {
        commit('setCoursePrice', value);
    },
    setServiceCharge({ commit }, value) {
        commit('setServiceCharge', value);
    },
    setCourseDetails({ commit }, value) {
        commit('setCourseDetails', value);
    },
    setPaymentMethod({ commit }, value) {
        commit('setPaymentMethod', value);
    }
};
