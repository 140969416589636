import $axios from '../utils/axios-api-config';
export default {
    getUserDocuments(userId, options) {
        const params = new URLSearchParams(options);
        return $axios.get(`/users/${userId}/documents?${params.toString()}`);
    },
    getUserDocumentCalculatedSize(userId, userDocumentId) {
        return $axios.get(`/users/${userId}/documents/${userDocumentId}/calc-size`);
    },
    createUserDocument(userId, formData) {
        return $axios.post(`/users/${userId}/documents?`, formData);
    },
    updateUserDocument(userId, id, formData) {
        return $axios.put(`/users/${userId}/documents/${id}`, formData);
    },
    moveUserDocuments(userId, formData) {
        return $axios.put(`/users/${userId}/documents/move`, formData);
    },
    deleteUserDocuments(userId, formData) {
        return $axios.delete(`/users/${userId}/documents`, { data: formData });
    },
};
