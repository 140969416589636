import { RolesEnum } from '@/core/packages/shared-library';
export const PROJECT_SEARCH_ROUTE = 'find-jobs';
/**
 * Project Search route page
 */
export default {
    path: '/find-jobs',
    name: PROJECT_SEARCH_ROUTE,
    components: {
        default: () => import('@/modules/project-search/pages/FindJobsPage.vue'),
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Find Jobs',
        authorize: [RolesEnum.TRADESPERSON],
    }
};
