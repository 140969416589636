export default [
    {
        path: '/courses-opportunities',
        name: 'courses-opportunities',
        component: () => import('@/modules/courses-children/pages/CoursesOpportunitiesPage.vue'),
    },
    {
        path: '/events',
        name: 'courses-events',
        component: () => import('@/modules/courses-children/pages/CoursesEventsPage.vue'),
    },
    {
        path: '/enrolled',
        name: 'courses-enrolled',
        component: () => import('@/modules/courses-children/pages/CoursesEnrolledPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/completed',
        name: 'courses-completed',
        component: () => import('@/modules/courses-children/pages/CoursesCompletedPage.vue'),
    },
    {
        path: '/course/:courseId',
        name: 'course-details',
        component: () => import('@/modules/courses-children/pages/CourseDetailsPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
];
