import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Members
 */
export const MEMBERS_ROUTE = 'manage-members';
export default {
    path: '/members',
    name: MEMBERS_ROUTE,
    components: {
        default: () => import('@/modules/members/pages/MembersPage.vue'),
        headerMenu: () => import('@/core/components/members/MembersHeader.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        title: 'Members'
    },
};
