import $axios from '../utils/axios-api-config';
export default {
    getProperties(params = {}) {
        return $axios.get('/properties', { params });
    },
    /**
       * get property by id
       * @returns any
       */
    getProperty(id) {
        return $axios.get(`/properties/${id}`);
    },
    /**
     * create properties
     * @returns any
     */
    createProperties(formData) {
        return $axios.post('/properties', formData);
    },
    updateProperty(propertyId, formData) {
        return $axios.put(`/properties/${propertyId}`, formData);
    },
    getMemberPermissions() {
        return $axios.get('/member-permissions');
    },
    getPropertyMembers(propertyId) {
        return $axios.get(`/properties/${propertyId}/members`);
    },
    createPropertyMembers(propertyId, formData) {
        return $axios.post(`/properties/${propertyId}/members`, formData);
    },
    updatePropertyMember(propertyId, propertyMemberId, formData) {
        return $axios.put(`/properties/${propertyId}/members/${propertyMemberId}`, formData);
    },
    deletePropertyMember(propertyId, propertyMemberId) {
        return $axios.delete(`/properties/${propertyId}/members/${propertyMemberId}`);
    },
    createPropertyDataUprnTitle(propertyId, data) {
        return $axios.post(`/property/${propertyId}/data/uprn-title`, data);
    },
    createPropertyDataTitle(propertyId, data) {
        return $axios.post(`/property/${propertyId}/data/title`, data);
    },
    createPropertyDataTitleClass(propertyId, data) {
        return $axios.post(`/property/${propertyId}/data/title-class`, data);
    },
};
