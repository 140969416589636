export default {
    projectEntity: (state) => {
        return state.project;
    },
    projectFileAttachments: (state) => {
        return state.project.attachments.images;
    },
    epcRecommendationProjectDetails: (state) => {
        return state.epcRecommendationProjectDetails;
    },
    openProjectDetailsFormModal: (state) => state.openProjectDetailsFormModal,
    openProjectStatusModal: (state) => state.openProjectStatusModal,
};
