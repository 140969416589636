// Reference for the local storage auth token
export const AUTH_TOKEN = 'auth_token';
// Reference for the local storage authenticated user info
export const AUTH_USER = 'auth_user';
// Reference for auth user session expiry in milliseconds
export const AUTH_SESSION_EXPIRY = 3600000;
export const AUTH_USER_NAV_COLLAPSE = 'auth_user_nav_collapse';
// Crypto secret key
export const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY ?? '';
// Defautl current symbol
export const DEFAULT_CURRENCY = '£';
// app standard date format
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DISPLAY_DATE_FORMAT = 'ddd, D MMM YYYY';
export const SYSTEM_DATE_FORMAT = 'YYYY-MM-DD';
export const PAGE_LIMIT = 20;
export const IMAGE_EXPIRATION_KEY = 'image_expiration_token';
export const IMAGE_EXPIRATION_TOKEN = 'image_expiration_storage';
export const IMAGE_EXPIRATION_DURATION = 70; // in minutes
// Pusher config
export const PUSHER_API_KEY = process.env.VUE_APP_PUSHER_API_KEY ?? '';
export const PUSHER_CLUSTER = process.env.VUE_APP_PUSHER_CLUSTER ?? '';
// Area distance max in miles
export const AREA_DISTANCE_MAX_RADIUS = 50;
// Area distance min in miles
export const AREA_DISTANCE_MIN_RADIUS = 1;
// Area distance default in miles
export const AREA_DISTANCE_DEFAULT_RADIUS = 5;
export const ACTIVE_MESSAGE_INPUT_WIDGETS = ['image-attachment'];
export const FILE_UPLOAD_ACCEPTED_FILE_TYPES = ['image/*', '.pdf'];
export const CALENDAR_EVENT_TYPE_CALENDAR = 'calendar';
export const CALENDAR_EVENT_TYPE_PROJECT = 'project';
export const CALENDAR_EVENT_TYPE_PROPERTY = 'property';
