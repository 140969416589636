import { PROJECT_QUOTES_CACHE, PROJECT_STAGES_CACHE, PROJECTS_CACHE, USER_CERTIFICATES_CACHE, USER_INSURANCES_CACHE, USER_MESSAGE_GROUP_CACHE, USER_MESSAGES_CACHE, USER_PUBLIC_GALLERY_CACHE, USER_PUBLIC_PROFILE_CACHE, USER_RATING_REVIEWS_CACHE } from './constants';
export default {
    [USER_PUBLIC_PROFILE_CACHE]: [],
    [USER_PUBLIC_GALLERY_CACHE]: [],
    [USER_CERTIFICATES_CACHE]: [],
    [USER_INSURANCES_CACHE]: [],
    [USER_RATING_REVIEWS_CACHE]: [],
    [PROJECTS_CACHE]: [],
    [PROJECT_QUOTES_CACHE]: [],
    [PROJECT_STAGES_CACHE]: [],
    [USER_MESSAGES_CACHE]: [],
    [USER_MESSAGE_GROUP_CACHE]: []
};
