export default {
    setMainLayoutInitialized(state, value) {
        state.hasMainLayoutInitialized = value;
    },
    /**
     * Set the user state
     * @param state
     * @param payload {User} entity
     */
    setUser(state, payload) {
        state.user = payload;
    },
    setAccountStatuses(state, payload) {
        state.accountStatuses = payload;
    },
    setIsProfileVerifiable(state, value) {
        state.isProfileVerifiable = value;
    },
    setCanUserAddWorkstation(state, value) {
        state.canUserAddWorkstation = value;
    },
    setCertificates: (state, certificates) => {
        state.userCertificates = certificates;
    },
    setNewUserCertificate: (state, certificate) => {
        state.userCertificates = [...state.userCertificates, certificate];
    },
    removeUserCertificates: (state, userCertificateId) => {
        if (userCertificateId) {
            state.userCertificates = state.userCertificates.filter((certificate) => certificate.id !== userCertificateId);
        }
    },
    setInsurances: (state, insurances) => {
        state.userInsurances = insurances;
    },
    setNewUserInsurance: (state, insurance) => {
        state.userInsurances = [...state.userInsurances, insurance];
    },
    removeUserInsurance: (state, userInsuranceId) => {
        if (userInsuranceId) {
            state.userInsurances = state.userInsurances.filter((insurance) => insurance.id !== userInsuranceId);
        }
    },
    setTransactionLogLastTimeCategory(state, value) {
        state.userTransactionLogLastTimeCategory = value;
    },
    setAccountSetupCache(state, payload) {
        state.user.accountSetupCache = JSON.stringify(payload);
    },
    setCohort(state, payload) {
        state.isCohort = payload;
    },
    setPassportOverview(state, payload) {
        state.isOnPassportOverview = payload;
    },
    setIsHosted(state, value) {
        state.isHosted = value;
    },
    setInvitedUser(state, value) {
        state.invitedUser = value;
    },
    setViewedUser(state, value) {
        state.viewedUser = value;
    },
    setWsRole(state, value) {
        state.wsRole = value;
    },
    setNotInMainLayout(state, payload) {
        state.notInMainLayout = payload;
    },
};
