/**
 * Cohort post route page
 */
export const COHORT_POST_ROUTE = 'add-cohort';
export default {
    path: '/add-cohort',
    name: COHORT_POST_ROUTE,
    component: () => import('@/modules/cohort-post/pages/AddCohortPage.vue'),
    meta: {
        title: '',
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
