import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Projects route page
 */
export const PROJECTS_ROUTE = 'projects';
export default {
    path: '/projects',
    name: PROJECTS_ROUTE,
    components: {
        default: () => import('@/modules/projects/pages/ProjectPage.vue'),
        headerMenu: () => import('@/core/components/project/ProjectPageHeader.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.PROJECT_OWNER],
    }
};
