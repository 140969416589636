import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Post project route
 */
export const OPPORTUNITY_DETAILS_ROUTE = 'opportunity-details';
export default {
    path: 'opportunity/course/:courseId',
    name: OPPORTUNITY_DETAILS_ROUTE,
    component: () => import('@/modules/opportunity-details/pages/OpportunityDetailsPage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Course',
        authorize: [RolesEnum.TRADESPERSON],
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
