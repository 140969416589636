import { RolesEnum } from '@/core/packages/shared-library';
export const VERIFY_WITHDRAWAL_ROUTE = 'verify-withdrawal';
export default {
    path: '/verify-withdrawal',
    name: VERIFY_WITHDRAWAL_ROUTE,
    component: () => import('@/modules/dashboard/pages/VerifyWithdrawalPage.vue'),
    props: (route) => ({
        withdrawalDetails: route.params.withdrawalDetails,
    }),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Verify Withdrawal',
        authorize: [RolesEnum.TRADESPERSON]
    }
};
