import { RolesEnum } from '@/core/packages/shared-library';
export default [
    {
        path: 'overview',
        name: 'overview',
        component: () => import('@/modules/project-details-children/pages/ProjectOverviewPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'estimate',
        name: 'estimate',
        component: () => import('@/modules/project-details-children/pages/EstimateOverviewPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        icon: 'details-menu-icon.svg',
        menuName: 'Estimate'
    },
    {
        path: 'schedule',
        name: 'schedule',
        component: () => import('@/modules/project-details-children/pages/SchedulePage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
    },
    {
        path: 'estimate-form',
        name: 'estimate-form',
        component: () => import('@/modules/project-estimate/pages/EstimateFormPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'estimate-schedule',
        name: 'estimate-schedule',
        component: () => import('@/modules/project-schedule/pages/ProjectScheduleAndEstimatePage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
    },
    {
        path: 'estimates',
        name: 'estimates',
        component: () => import('@/modules/project-details-children/pages/EstimateReceivePage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'attachments',
        name: 'attachments',
        component: () => import('@/modules/project-details-children/pages/ProjectAttachmentsPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'members',
        name: 'members',
        component: () => import('@/modules/project-details-children/pages/ProjectMembersPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'chat',
        name: 'chat',
        component: () => import('@/modules/project-details-children/pages/ProjectChatPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
];
