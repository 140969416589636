import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Select Property route
 */
export const SELECT_PROPERTY_ROUTE = 'select-property';
export default {
    path: '/select-property',
    name: SELECT_PROPERTY_ROUTE,
    component: () => import('@/modules/select-property/pages/SelectPropertyPage.vue'),
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Start a new project',
        authorize: [RolesEnum.PROJECT_OWNER],
    }
};
