export default {
    /**
     * Format to title case
     * @param value
     * @returns
     */
    formatToTitleCase(value) {
        return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    }
};
