// import * as CryptoJS from 'crypto-js';
import appFilters from '@/filters';
// no need to type check, we only display images for now..
// const IMAGE_SRC_PREFIX = 'data:image/png;base64,';
export default {
    async getAttachment({ commit, getters }, { name, attachment, originalName, setEntity = true, appendEntity = false, includeUrl = false, includeOriginalName = true, id = null }) {
        let currentValue = getters[name];
        let newValue;
        let entity;
        // let url: any;
        if (Array.isArray(currentValue)) {
            currentValue = currentValue.filter((value) => value.attachment === attachment);
            currentValue = currentValue[0] || null;
        }
        if (!currentValue) {
            newValue = await appFilters.getEncodedAttachment(attachment);
        }
        else if (currentValue) {
            if (currentValue.attachment !== attachment) {
                newValue = await appFilters.getEncodedAttachment(attachment);
            }
            else {
                return Promise.resolve(currentValue);
            }
        }
        if (newValue) {
            // const encoded = CryptoJS.AES.decrypt(
            //   (newValue as any),
            //   process.env.VUE_APP_CRYPTO_SECRET_KEY
            // ).toString(CryptoJS.enc.Utf8);
            if (includeUrl) {
                // url = await appFilters.formatToImageSourceUrl(attachment);
            }
            entity = {
                // src: `${IMAGE_SRC_PREFIX}${encoded}`,
                src: newValue?.publicUrl,
                attachment,
                // url,
                url: newValue?.publicUrl,
                originalName
            };
            if (includeOriginalName) {
                entity.originalName = originalName;
            }
            // we need to add the id as an option
            // as some components are using it to load as source for upload
            if (id) {
                entity.id = id;
            }
            if (setEntity) {
                commit('setEntity', { name, entity });
            }
            if (appendEntity) {
                commit('appendEntity', { name, entity });
            }
        }
        return Promise.resolve(entity);
    },
    async getAttachments({ commit, dispatch }, { name, attachments, setEntity = false, mergeEntity = true, includeOriginalName = true, returnFirstOnly = false, includeUrl = false, }) {
        const attachmentsPromises = [];
        let entities = [];
        attachments.filter(Boolean).forEach(({ id, attachment, originalName }) => {
            const request = {
                name,
                attachment,
                originalName,
                setEntity,
                includeUrl,
                includeOriginalName,
                id
            };
            if (attachment) {
                const entity = dispatch('getAttachment', request);
                attachmentsPromises.push(entity);
            }
        });
        const promiseList = await Promise.allSettled(attachmentsPromises);
        promiseList.forEach((item) => {
            const { value } = item;
            if (value) {
                entities.push(value);
            }
        });
        if (setEntity) {
            commit('setEntity', { name, entity: entities });
        }
        if (mergeEntity) {
            commit('mergeEntity', { name, entity: entities });
        }
        if (returnFirstOnly) {
            entities = entities[0];
        }
        return Promise.resolve(entities);
    },
    resetCacheEntity({ commit }, name) {
        commit('resetEntityState', name);
    }
};
