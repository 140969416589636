import { RolesEnum } from '@/core/packages/shared-library';
/**
 * E-wallet route
 */
export const E_WALLET_ROUTE = 'e-wallet';
export default {
    path: '/e-wallet',
    name: E_WALLET_ROUTE,
    component: () => import('@/modules/e-wallet/pages/EwalletPage.vue'),
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'E-wallet',
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
    }
};
