import { RolesEnum } from '@/core/packages/shared-library';
import { PROJECTS_ROUTE } from '../../projects/routes';
/**
 * Project Stages and Tasks page
 */
export const PROJECT_STAGES_TASKS_ROUTE = 'project-stages-tasks';
export default {
    path: '/projects/:projectRefId/stages-tasks',
    name: PROJECT_STAGES_TASKS_ROUTE,
    props: {
        default: (route) => ({ projectRefId: route.params.projectRefId }),
    },
    components: {
        default: () => import('@/modules/project-stages-tasks/pages/ProjectStagesTasksPage.vue'),
        headerMenu: () => import('@/core/components/project/ProjectOverviewMenu.vue')
    },
    meta: {
        disableRoute: false,
        title: 'Stages & Tasks',
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        showBackButton: false
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-restricted-globals
        if (!to.params.projectRefId /* || isNaN(to.params.projectRefId) */) {
            next({ name: PROJECTS_ROUTE });
        }
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    },
};
