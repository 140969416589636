import $axios from '../utils/axios-api-config';
export default {
    /**
     * Get notification list
     * @returns
     */
    getUserNotifications() {
        return $axios.get('/user-notifications');
    },
    /**
     * Update notification record
     * @param notificationId
     * @param payload
     * @returns
     */
    updateNotification(notificationId, payload) {
        return $axios.put(`/user-notifications/${notificationId}`, payload);
    },
    /**
     * Remove notification record
     * @param notificationId
     * @returns number
     */
    removeNotification(notificationId) {
        return $axios.delete(`/user-notifications/${notificationId}`);
    }
};
