import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Project jobs route page
 */
export const PROJECT_JOBS_ROUTE = 'my-jobs';
export default {
    path: '/my-jobs',
    name: PROJECT_JOBS_ROUTE,
    components: {
        default: () => import('@/modules/project-jobs/pages/ProjectJobPage.vue'),
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.TRADESPERSON],
    }
};
