export default {
    hasMainLayoutInitialized: false,
    user: null,
    invitedUser: null,
    accountStatuses: null,
    userCertificates: [],
    userInsurances: [],
    userTransactionLogLastTimeCategory: '',
    isProfileVerifiable: false,
    canUserAddWorkstation: false,
    isCohort: false,
    isHosted: false,
    viewedUser: null,
    wsRole: null,
    notInMainLayout: false,
    isOnPassportOverview: false,
};
