import userDocumentService from '@/core/services/user-document.service';
export default {
    setOpenShareModal({ commit }, value) {
        commit('setOpenShareModal', value);
    },
    setOpenRenameModal({ commit }, value) {
        commit('setOpenRenameModal', value);
    },
    setOpenMoveModal({ commit }, value) {
        commit('setOpenMoveModal', value);
    },
    setOpenDetailsModal({ commit }, value) {
        commit('setOpenDetailsModal', value);
    },
    setOpenDeleteModal({ commit }, value) {
        commit('setOpenDeleteModal', value);
    },
    setOpenNewFolderModal({ commit }, value) {
        commit('setOpenNewFolderModal', value);
    },
    setOpenNewFileUploadModal({ commit }, value) {
        commit('setOpenNewFileUploadModal', value);
    },
    setFolderList({ commit }, value) {
        commit('setFolderList', value);
    },
    setOpenedFolder({ commit }, value) {
        commit('setOpenedFolder', value);
    },
    setSelectedDocument({ commit }, value) {
        commit('setSelectedDocument', value);
    },
    setFileList({ commit }, value) {
        commit('setFileList', value);
    },
    setIsPropertyDocument({ commit }, value) {
        commit('setIsPropertyDocument', value);
    },
    getUserDocuments({ getters }, params) {
        const { userId, options } = params;
        if (getters.isPropertyDocument) {
            // eslint-disable-next-line no-param-reassign
            params.options = {
                ...options,
                entityId: '1'
            };
        }
        return userDocumentService.getUserDocuments(userId, params.options);
    },
    getUserDocumentCalculatedSize(context, params) {
        const { userId, userDocumentId } = params;
        return userDocumentService.getUserDocumentCalculatedSize(userId, userDocumentId);
    },
    createUserDocument(context, params) {
        const { userId, formData } = params;
        return userDocumentService.createUserDocument(userId, formData);
    },
    updateUserDocument(context, params) {
        const { userId, id, formData } = params;
        return userDocumentService.updateUserDocument(userId, id, formData);
    },
    moveUserDocuments(context, params) {
        const { userId, formData } = params;
        return userDocumentService.moveUserDocuments(userId, formData);
    },
    deleteUserDocuments(context, params) {
        const { userId, formData } = params;
        return userDocumentService.deleteUserDocuments(userId, formData);
    },
    setOpenDocumentBillingModal({ commit }, value) {
        commit('setOpenDocumentBillingModal', value);
    },
    setDocumentList({ commit }, value) {
        commit('setDocumentList', value);
    },
};
