export default {
    setUserContractors(state, userContractors) {
        state.userContractors = userContractors;
    },
    setRecentlyAdded(state, userContractor) {
        state.recentlyAdded = userContractor;
    },
    setToRemoveContractorId(state, id) {
        state.toRemoveContractorId = id;
    },
    setToManageContractor(state, userContractor) {
        state.toManageContractor = userContractor;
    },
};
