/**
 * epc recommendation route page
 */
export const EPC_RECOMMENDATION_ROUTE = 'epc-recommendation';
export default {
    path: '/epc-recommendation',
    name: EPC_RECOMMENDATION_ROUTE,
    component: () => import('@/modules/epc-recommendation/pages/EpcRecommendationPage.vue'),
    meta: {
        disableRoute: false,
        validateAuth: true,
    }
};
