/**
 * Login route page
 */
// export const REGISTRATION_ROUTE = 'registration';
export const VERIFY_REGISTRATION_ROUTE = 'verify-registration';
export default [
    // {
    //   path: '/registration',
    //   name: REGISTRATION_ROUTE,
    //   component: () => import('@/modules/registration/pages/RegistrationPage.vue'),
    //   meta: {
    //     validateAuth: true,
    //     authorize: []
    //   }
    // },
    {
        path: '/verify-code',
        name: VERIFY_REGISTRATION_ROUTE,
        props: (route) => ({ email: route.params.email }),
        component: () => import('@/modules/login/pages/LoginPage.vue'),
        beforeEnter: (to, from, next) => {
            if (typeof to.params.email !== 'undefined') {
                next();
            }
            else {
                next('/login');
            }
        }
    }
];
