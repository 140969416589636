/**
 * property analytics route page
 */
export const PROPERTY_ANALYTICS_ROUTE = 'property-analytics';
export default {
    path: '/property-analytics',
    name: PROPERTY_ANALYTICS_ROUTE,
    component: () => import('@/modules/property-analytics/pages/PropertyAnalyticsPage.vue'),
    meta: {
        disableRoute: false,
        validateAuth: true,
    }
};
