import { RolesEnum } from '@/core/packages/shared-library';
export const ACCOUNT_ROLE_SETUP = 'account-role-setup';
export default {
    path: '/account-role-setup',
    name: ACCOUNT_ROLE_SETUP,
    component: () => import('@/modules/account-role-setup/pages/AccountRolePage.vue'),
    meta: {
        validateAuth: true,
        isRequireAuth: true,
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER]
    }
};
