import $axios from '@/core/utils/axios-api-config';
export default {
    getPropertyInsurance(propertyId, id) {
        return $axios.get(`/property/${propertyId}/insurances/${id}`);
    },
    getPropertyInsurances(propertyId) {
        return $axios.get(`/property/${propertyId}/insurances`);
    },
    async createPropertyInsurance(propertyId, formData) {
        return $axios.post(`/property/${propertyId}/insurances`, formData);
    },
    async updatePropertyInsurance(propertyId, id, formData) {
        return $axios.put(`/property/${propertyId}/insurances/${id}`, formData);
    },
};
