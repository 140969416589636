import { RolesEnum } from '@/core/packages/shared-library';
export default [
    {
        path: 'jobs',
        name: 'jobs',
        component: () => import('@/modules/project-jobs/pages/ProjectJobPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'templates',
        name: 'templates',
        component: () => import('@/modules/manage-job-children/pages/TemplatePage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'templates/:id/edit',
        name: 'template-edit',
        component: () => import('@/modules/manage-job-children/pages/TemplatePage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'clients',
        name: 'clients',
        component: () => import('@/modules/manage-job-children/pages/ClientPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
];
