/**
 * Messages route page
 */
export const MESSAGES_ROUTE = 'messages';
export default {
    path: '/messages',
    name: MESSAGES_ROUTE,
    component: () => import('@/modules/messages/pages/MessagesPage.vue'),
    meta: {
        disableRoute: false,
        title: 'Chat',
    },
};
