import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
export const PROJECT_STAGE_DEPOSITS_STORE = 'projectStageDeposits';
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
