import { RolesEnum } from '@/core/packages/shared-library';
export const SCHEDULE_ROUTE = 'project-schedule';
export default {
    path: '/projects/:projectRefId/schedule/:quoteId',
    name: SCHEDULE_ROUTE,
    components: {
        default: () => import('@/modules/project-schedule/pages/ProjectScheduleAndEstimatePage.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Schedule',
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
    }
};
