/**
 * Documents route page
 */
export const DOCUMENTS_ROUTE = 'documents';
export default {
    path: '/documents',
    name: DOCUMENTS_ROUTE,
    component: () => import('@/modules/documents/pages/DocumentsPage.vue'),
    meta: {
        title: 'Documents',
    },
};
