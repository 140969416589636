import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Post project route
 */
export const OPPORTUNITY_CALENDAR_ROUTE = 'opportunity-select-spot';
export default {
    path: 'opportunity/course/:courseId/calendar',
    name: OPPORTUNITY_CALENDAR_ROUTE,
    component: () => import('@/modules/opportunity-calendar/pages/OpportunityCalendarPage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Select your preferred course date',
        authorize: [RolesEnum.TRADESPERSON],
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
