export default {
    certificates: (state) => state.certificates,
    templates: (state) => {
        const templates = {};
        if (state.certificates && state.certificates.length) {
            state.certificates.forEach((certificate) => {
                templates[certificate.id] = certificate.template;
            });
        }
        return templates;
    }
};
