export default {
    propertyInsuranceForm: {
        type: '',
        expiryDate: '',
        coverName: '',
        coverAmount: null,
        attachments: [],
        dateIssued: ''
    },
    defaultPropertyInsuranceForm: {
        type: '',
        expiryDate: '',
        coverName: '',
        coverAmount: null,
        attachments: [],
        dateIssued: ''
    },
};
