// import { RolesEnum } from '@/core/packages/shared-library';
export const PROJECT_OVERVIEW_ROUTE = 'project-overview';
export default {
// path: '/projects/:projectRefId/overview',
// name: PROJECT_OVERVIEW_ROUTE,
// props: {
//   default: (route: { params: { projectRefId: string; }; }) => ({ projectRefId: route.params.projectRefId })
// },
// components: {
//   default: () => import('@/modules/project-overview/pages/ProjectOverviewPage.vue'),
//   headerMenu: () => import('@/core/components/project/ProjectOverviewMenu.vue')
// },
// meta: {
//   title: 'Project Overview',
//   authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER],
//   showBackButton: true
// },
// beforeEnter: (to, from, next) => {
//   // eslint-disable-next-line no-restricted-globals
//   if (!to.params.projectRefId /* || isNaN(to.params.projectRefId) */) {
//     next(from);
//   }
//   // eslint-disable-next-line no-param-reassign
//   to.meta.previousRoute = from || to;
//   next();
// }
};
