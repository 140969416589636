export default {
    accumulatedSize: (state) => state.accumulatedSize,
    getListingAmount: (state) => state.listingAmount,
    getHasPropertyListing: (state) => state.hasPropertyListing,
    getTotalMember: (state) => state.totalMember,
    getAddedMember: (state) => state.addedMember,
    getPerMemberPrice: (state) => state.perMemberPrice,
    getListingPrice: (state) => state.listingPrice,
    getPaymentMethod: (state) => state.paymentMethod,
    getStoragePlan: (state) => state.storagePlan,
    getDefaultTrialDays: (state) => state.defaultTrialDays,
    getDefaultPropertyBillingAmount: (state) => state.defaultPropertyBillingAmount,
};
