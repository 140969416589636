import { RolesEnum } from '@/core/packages/shared-library';
export default [
    {
        path: 'courses',
        name: 'opportunity/courses',
        component: () => import('@/modules/opportunity-children/pages/CoursesPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'events',
        name: 'opportunity/events',
        component: () => import('@/modules/opportunity-children/pages/EventsPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
];
