import JobClientService from '@/core/services/job-client.service';
export default {
    getUserContractors(context, userId) {
        return JobClientService.getUserContractors(userId);
    },
    addJobClient(context, params) {
        const { userId, formData } = params;
        return JobClientService.addJobClient(userId, formData)
            .then((response) => {
            context.commit('resetJobClientFormState');
            return response;
        });
    },
    addOrUpdateClientAddress(context, params) {
        const { userId, contractorId, formData } = params;
        return JobClientService.addOrUpdateClientAddress(userId, contractorId, formData)
            .then((response) => {
            context.commit('resetAddressFormState');
            return response;
        });
    },
    deleteClient(context, params) {
        const { userId, id } = params;
        return JobClientService.deleteClient(userId, id);
    },
    deleteClientAddress(context, params) {
        const { userId, id } = params;
        return JobClientService.deleteClientAddress(userId, id);
    },
    updateJobClient(context, params) {
        const { userId, id, formData } = params;
        return JobClientService.updateJobClient(userId, id, formData)
            .then((response) => {
            context.commit('resetJobClientFormState');
            return response;
        });
    },
    resetJobClientFormState({ commit }) {
        commit('resetJobClientFormState');
    },
    setJobClientForm({ commit }, value) {
        commit('setJobClientForm', value);
    },
    resetAddressFormState({ commit }) {
        commit('resetAddressFormState');
    },
    setAddressForm({ commit }, value) {
        commit('setAddressForm', value);
    },
    setConfirmDeleteModal({ commit }, value) {
        commit('setConfirmDeleteModal', value);
    },
    setConfirmDeleteAddressModal({ commit }, value) {
        commit('setConfirmDeleteAddressModal', value);
    },
    setSelectedId({ commit }, value) {
        commit('setSelectedId', value);
    },
    setSelectedAddressId({ commit }, value) {
        commit('setSelectedAddressId', value);
    },
    setSelectedClient({ commit }, value) {
        commit('setSelectedClient', value);
    },
    setSelectedAddress({ commit }, value) {
        commit('setSelectedAddress', value);
    },
};
