/**
 * Change password route page
 */
export const FORGOT_PASSWORD_ROUTE = 'forgot-password';
export default {
    path: '/forgot-password',
    name: FORGOT_PASSWORD_ROUTE,
    component: () => import('@/modules/login/pages/LoginPage.vue'),
    meta: {}
};
