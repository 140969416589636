import { MessageStateEnum } from '@/core/packages/shared-library';
import MessageService from '@/core/services/messages.service';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { AUTHENTICATION_STORE } from '../authentication';
export default {
    setOpenCreateMicroTaskModal({ commit }, value) {
        commit('setOpenCreateMicroTaskModal', value);
    },
    /*
    async setUnreadMessagesListByRoom(context: any, roomId: number) {
      const getMessages = await MessageService.updateMessagesListByRoom(roomId);
  
      return getMessages.data;
    },
  
    async getMessageHistoryRoomsByProject(context: any, { projectId }: { projectId: number }) {
      const getMessageHistoryRoomsByProject = await MessageService.getMessageHistoryRoomsByProject(projectId);
  
      return getMessageHistoryRoomsByProject.data;
    },
  
    async getMessageHistoryByUser() {
      const getMessageHistoryByUser = await MessageService.getMessageHistoryByUser();
  
      return getMessageHistoryByUser;
    },
  
    async getMessageHistoryByRoom(context: any, payload: { roomId: number; }) {
      const getMessages = await MessageService.getMessageHistoryByRoom(payload.roomId);
  
      return getMessages.data;
    }, */
    /**
     * Set total new messages for authenticated user
     * @param total
     * @returns void
     */
    setTotalNewMessages({ commit }, total) {
        commit('setTotalNewMessages', total);
    },
    getAndIdentifyUser({ rootGetters }) {
        const authenticatedUser = rootGetters[`${AUTHENTICATION_STORE}/authenticatedUser`];
        const { userId } = authenticatedUser;
        let id = userId;
        const isHosted = window.localStorage.getItem('hst');
        if (isHosted) {
            const activeWs = rootGetters[`${USER_WORKSTATION}/activeUserWorkstation`];
            if (activeWs?.user?.id) {
                id = activeWs.user?.id;
            }
        }
        return id;
    },
    /**
     * Fetch unread user messages by filters
     * @param fromUser
     * @returns UserMessage[]
     */
    async getUnreadMessagesByUser({ dispatch }, payload) {
        const type = payload.fromUser ? 'from' : 'to';
        const userId = await dispatch('getAndIdentifyUser');
        const getAllUnreadMessages = await MessageService.getUserMessagesFilters(userId, { type, state: MessageStateEnum.UNREAD });
        if (getAllUnreadMessages) {
            dispatch('setTotalNewMessages', getAllUnreadMessages?.length);
        }
    },
    /**
     * Update unread user message state by userMessageRoom
     * @param userId
     * @param projectId
     * @returns
     */
    setUnreadUserMessagesByUserMessageRoom(context, { userId, roomId }) {
        return MessageService.updateUserMessagesByUserMessageRoom(userId, roomId, { state: MessageStateEnum.READ });
    },
    /**
     * Fetch user message room by projectId, userId
     * @param userId
     * @param projectId
     * @returns UserMessageRoom
     */
    getUserMessageRoomByProjectId(context, { userId, projectId }) {
        return MessageService.getUserMessageRoomByProjectId(userId, projectId);
    },
    /**
     * Fetch all user message rooms
     * @param userId
     * @returns UserMessageRoom[]
     */
    getUserMessageRooms(context, userId) {
        return MessageService.getUserMessageRooms(userId);
    },
    /**
     * Fetch all user messages by userMessageRoomId
     * @param userId
     * @param userMessageRoomId
     * @returns UserMessage[]
     */
    getUserMessages(context, { userId, userMessageRoomId }) {
        return MessageService.getUserMessages(userId, userMessageRoomId);
    },
    getUserMessagesByGroup(context, { userId, userMessageRoomId }) {
        return MessageService.getUserMessagesByGroup(userId, userMessageRoomId);
    },
    /**
     * Fetch all group contacts
     * @param userId
     * @returns Array[]
     */
    getGroupContacts(context, userId) {
        return MessageService.getGroupContacts(userId);
    },
    /**
     * Post message in a userMessageRoom
     * @param userId
     * @param userMessageRoomId
     * @param formData CreateUserMessageRequestDto
     * @returns UserMessage
     */
    createUserMessage(context, { userId, formData }) {
        return MessageService.createUserMessage(userId, formData);
    },
    /**
     * Create user message room if not exists
     * @param userId
     * @param formData CreateUserMessageRoomRequestDto
     * @returns UserMessageRoom
     */
    createUserMessageRoom(context, { userId, formData }) {
        return MessageService.createUserMessageRoom(userId, formData);
    },
    createUserGroupMessageRoom(context, { userId, formData }) {
        return MessageService.createUserGroupMessageRoom(userId, formData);
    },
    /**
     * Upload file attachment on user message room via userMessageRoomId
     * @param userId
     * @param userMessageRoomId
     * @param file
     * @returns
     */
    createUserMessageRoomAttachment(context, { userId, userMessageRoomId, file }) {
        return MessageService.createUserMessageRoomAttachment(userId, userMessageRoomId, file);
    },
    addMemberToChatRoom(context, { member, referenceId, userMessageRooms, callback }) {
        if (member) {
            const findUserMessageRoom = userMessageRooms.find((p) => p.id === referenceId);
            if (findUserMessageRoom) {
                callback(findUserMessageRoom, member);
            }
        }
    },
    removeMemberFromChatRoom(context, { member, referenceId, userMessageRooms, callback }) {
        if (member) {
            const findUserMessageRoom = userMessageRooms.find((p) => p.id === referenceId);
            const findUserMessageRoomMembers = userMessageRooms[userMessageRooms.indexOf(findUserMessageRoom)].members.find((m) => m.id === member.id);
            if (findUserMessageRoom) {
                callback(findUserMessageRoom, findUserMessageRoomMembers);
            }
        }
    },
};
