import PropertyInsuranceService from '@/core/services/property-insurance.service';
export default {
    getPropertyInsurance(context, params) {
        const { propertyId, id } = params;
        return PropertyInsuranceService.getPropertyInsurance(propertyId, id);
    },
    getPropertyInsurances(context, propertyId) {
        return PropertyInsuranceService.getPropertyInsurances(propertyId);
    },
    createPropertyInsurance(context, params) {
        const { propertyId, formData } = params;
        return PropertyInsuranceService.createPropertyInsurance(propertyId, formData)
            .then((response) => {
            context.commit('resetPropertyInsuranceFormState');
            return response;
        });
    },
    updatePropertyInsurance(context, params) {
        const { propertyId, id, formData } = params;
        return PropertyInsuranceService.updatePropertyInsurance(propertyId, id, formData)
            .then((response) => {
            context.commit('resetPropertyInsuranceFormState');
            return response;
        });
    },
    resetPropertyInsuranceFormState({ commit }) {
        commit('resetPropertyInsuranceFormState');
    },
    setPropertyInsuranceForm({ commit }, value) {
        commit('setPropertyInsuranceForm', value);
    },
};
