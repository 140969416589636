export default {
    folderList: [],
    fileList: [],
    openedFolder: {},
    selectedDocument: {},
    isPropertyDocument: false,
    openShareModal: false,
    openRenameModal: false,
    openMoveModal: false,
    openDetailsModal: false,
    openDeleteModal: false,
    openNewFolderModal: false,
    openNewFileUploadModal: false,
    openDocumentBillingModal: false,
    documentList: [],
};
