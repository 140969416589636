import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Properties route
 */
export const PROPERTIES_ROUTE = 'properties';
export default {
    path: '/properties',
    name: PROPERTIES_ROUTE,
    component: () => import('@/modules/properties/pages/PropertiesPage.vue'),
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'List a new property',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: false
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
