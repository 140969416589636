import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Post project route
 */
export const OPPORTUNITY_BILLING_ROUTE = 'opportunity-billing';
export default {
    path: '/opportunity/billing',
    name: OPPORTUNITY_BILLING_ROUTE,
    component: () => import('@/modules/opportunity-billing/pages/OpportunityBillingPage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: '',
        authorize: [RolesEnum.TRADESPERSON],
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
