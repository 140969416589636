import $axios from '../utils/axios-api-config';
export default {
    /**
     * Fetch all modulr payment service providers
     * @returns any[]
     */
    getPaymentProviders() {
        return $axios.get('/modulr/payment-servicing-providers');
    },
    executeWithdrawFunds(payload) {
        return $axios.post('/payment/scan', payload);
    }
};
