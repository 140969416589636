export default [
    {
        path: '/overview',
        name: 'trade-passport-overview',
        component: () => import('@/modules/trades-passport-children/pages/TradesPassportOverviewPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/specialism',
        name: 'trade-passport-specialism',
        component: () => import('@/modules/trades-passport-children/pages/TradesPassportSpecialismPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/certifications',
        name: 'trade-passport-certifications',
        component: () => import('@/modules/trades-passport-children/pages/TradePassportCertificationPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/companies',
        name: 'trade-passport-companies',
        component: () => import('@/modules/trades-passport-children/pages/TradesPassportCompaniesPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/projects',
        name: 'trade-passport-projects',
        component: () => import('@/modules/trades-passport-children/pages/TradePassportProjectsPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/insurances',
        name: 'trade-passport-insurances',
        component: () => import('@/modules/trades-passport-children/pages/TradePassportInsurancePage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/reviews',
        name: 'trade-passport-reviews',
        component: () => import('@/modules/trades-passport-children/pages/TradePassportReviewsPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/gallery',
        name: 'trade-passport-gallery',
        component: () => import('@/modules/trades-passport-children/pages/TradePassportGalleryPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
    {
        path: '/recommendations',
        name: 'trade-passport-recommendations',
        component: () => import('@/modules/trades-passport-children/pages/TradePassportRecommendationPage.vue'),
        beforeEnter: (to, from, next) => {
            // eslint-disable-next-line no-param-reassign
            to.meta.previousRoute = from || to;
            next();
        }
    },
];
