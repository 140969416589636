export default {
    jobClientForm: {
        email: '',
        name: '',
        addressLine: '',
        cityTown: '',
        postcode: ''
    },
    defaultJobClientForm: {
        email: '',
        name: '',
        addressLine: '',
        cityTown: '',
        postcode: ''
    },
    addressForm: {
        addressLine: '',
        cityTown: '',
        postcode: ''
    },
    defaultAddressForm: {
        addressLine: '',
        cityTown: '',
        postcode: ''
    },
    confirmDeleteModal: false,
    confirmDeleteAddressModal: false,
    selectedId: null,
    selectedClient: null,
    selectedAddressId: null,
    selectedAddress: null,
};
