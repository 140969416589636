import $axios from '../utils/axios-api-config';
export default {
    /**
     * Fetch project stage details
     * @param projectQuoteId
     * @param projectStageId
     * @returns ProjectStage
     */
    getProjectStage(projectQuoteId, projectStageId) {
        return $axios.get(`project-quotes/${projectQuoteId}/stages/${projectStageId}`);
    },
    createProjectStageInvoice(projectQuoteId, projectStageId, requiredData) {
        return $axios.post(`project-quotes/${projectQuoteId}/stages/${projectStageId}/invoice`, requiredData);
    }
};
