import courseService from '@/core/services/course.service';
import userCourseService from '@/core/services/user-course.service';
export default {
    setOpenCheckEligibilityModal({ commit }, value) {
        commit('setOpenCheckEligibilityModal', value);
    },
    setCurrentCourse({ commit }, value) {
        commit('setCurrentCourse', value);
    },
    setCourses({ commit }, value) {
        commit('setCourses', value);
    },
    getCourses(context, params) {
        return courseService.getCourses(params);
    },
    getCourse(context, courseId) {
        return courseService.getCourse(courseId);
    },
    createUserCourseSchedule(context, params) {
        const { userCourseId, formData } = params;
        return userCourseService.createUserCourseSchedule(userCourseId, formData);
    },
    getUserCourses(context, params) {
        const { userId, filter } = params;
        return courseService.getUserCourses(userId, filter);
    },
    setCoursesMenu({ commit }, value) {
        commit('setCoursesMenu', value);
    },
    setCoursesCurrentMenuId({ commit }, value) {
        commit('setCoursesCurrentMenuId', value);
    },
};
