import UserService from '@/core/services/users.service';
import { AUTHENTICATION_STORE } from '../authentication';
import { UserWorkstationConstant } from './constants';
export default {
    setTypeWorkstation({ commit }, value) {
        commit('setTypeWorkstation', value);
    },
    setSelectedWorkstationDetail({ commit }, value) {
        commit('setSelectedWorkstationDetail', value);
    },
    getUserWorkstations({ commit }, userId) {
        return UserService.getUserWorkstations(userId)
            .then((response) => {
            commit('setUserWorkstations', response);
        });
    },
    getUserWorkstation({ commit }, params) {
        const { userId, userWorkstationId } = params;
        return UserService.getUserWorkstation(userId, userWorkstationId)
            .then((response) => {
            commit('setActiveUserWorkstation', response);
        });
    },
    async getActiveUserWorkstation({ getters, dispatch }) {
        const { activeUserWorkstation } = getters;
        if (!activeUserWorkstation) {
            await dispatch('setActiveUserWorkstation');
        }
        return getters.activeUserWorkstation;
    },
    setUserWorkstations({ commit }, userWorkstations) {
        commit('setUserWorkstations', userWorkstations);
    },
    clearActiveUserWorkstation({ commit }) {
        commit('clearActiveUserWorkstation');
    },
    async setActiveUserWorkstation({ commit, getters, rootGetters }, userWorkstation = null) {
        const { activeUserWorkstation } = getters;
        let value = userWorkstation;
        if (!activeUserWorkstation) {
            const authenticatedUser = rootGetters[`${AUTHENTICATION_STORE}/authenticatedUser`];
            await UserService.getUserWorkstations(authenticatedUser.userId)
                .then((userWorkstations) => {
                commit('setUserWorkstations', userWorkstations);
                if (userWorkstations.length) {
                    const foundActive = userWorkstations.find((item) => item.status === UserWorkstationConstant.USER_WORKSTATION_STATE.ACTIVE);
                    value = foundActive;
                }
            });
        }
        commit('setActiveUserWorkstation', value);
    },
    activateWorkstation({ commit }, params) {
        const { userId, userWorkstationId } = params;
        return UserService.activateWorkstation(userId, userWorkstationId)
            .then((response) => {
            commit('setActiveUserWorkstation', response);
        });
    },
    // we don't need to request to activate as this is to emulate local hosted
    activateHostedWorkstation({ commit }, userWorkstation) {
        commit('setActiveUserWorkstation', userWorkstation);
    },
    updateUserWorkstationStatus({ commit }, params) {
        const { userId, userWorkstationId, formData } = params;
        return UserService.updateUserWorkstationStatus(userId, userWorkstationId, formData)
            .then((response) => {
            commit('setActiveUserWorkstation', response);
        });
    },
    setModifiedactiveUserWorkstation({ commit }, userWorkstation) {
        commit('setActiveUserWorkstation', userWorkstation);
    }
};
