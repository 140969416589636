/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import * as CryptoJS from 'crypto-js';
import { createRouter, createWebHistory } from 'vue-router';
import { RolesEnum } from '@/core/packages/shared-library';
import AccountRoute from '@/modules/account/routes';
import AccountProfileRoute from '@/modules/account-profile/routes';
import AccountRoleSetupRoute, { ACCOUNT_ROLE_SETUP } from '@/modules/account-role-setup/routes';
import AccountSettingsRoute from '@/modules/account-settings/routes';
import AccountSetupRoute from '@/modules/account-setup/routes';
import CalendarRoute from '@/modules/calendar/routes';
import ChangePasswordRoute from '@/modules/change-password/routes';
import CohortInvoiceRoute from '@/modules/cohort-invoice/routes';
import CohortPostRoute from '@/modules/cohort-post/routes';
import CohortsRoute from '@/modules/cohorts/routes';
import CoursesRoute from '@/modules/courses/routes';
import CreatePropertyMapRoute from '@/modules/create-property-map/routes';
import DashboardRoute, { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
// import DashboardRoute from '@/modules/dashboard/routes';
import VerifyWithdrawalRoute from '@/modules/dashboard/routes/verify-withdrawal';
import DocumentsRoute from '@/modules/documents/routes';
import EwalletRoute from '@/modules/e-wallet/routes';
import EpcDataRoute from '@/modules/epc-data/routes';
import EpcRecommendationRoute from '@/modules/epc-recommendation/routes';
import ForgotPasswordRoute from '@/modules/forgot-password/routes';
import InsuranceRoute from '@/modules/insurance/routes';
import LockedPageRoute from '@/modules/locked-page/routes';
import LoginRoute from '@/modules/login/routes';
import ManageJobsRoute from '@/modules/manage-job/routes';
import MembersRoute from '@/modules/members/routes';
import MessagesRoute from '@/modules/messages/routes';
import OpportunityRoute from '@/modules/opportunity/routes';
import OpportunityBillingRoute from '@/modules/opportunity-billing/routes';
import OpportunityCalendarRoute from '@/modules/opportunity-calendar/routes';
import OpportunityDetailsRoute from '@/modules/opportunity-details/routes';
import PassportOverviewRoute from '@/modules/passport-overview/routes';
import PostOnboardRoute from '@/modules/post-onboarding/routes';
import ProfileRoute from '@/modules/profile/routes';
import ProjectDashboardRoute from '@/modules/project-dashboard/routes';
import ProjectDetailsRoute from '@/modules/project-details/routes';
import ProjectEstimateRoute from '@/modules/project-estimate/routes';
import ProjectJobRoute from '@/modules/project-jobs/routes';
import ProjectMessages from '@/modules/project-messages/routes';
import ProjectPostRoute from '@/modules/project-post/routes';
// import ProjectOverviewRoute from '@/modules/project-overview/routes';
import ProjectPostOverviewRoute from '@/modules/project-post-overview/routes';
import ProjectQuoteCreateRoute from '@/modules/project-quote-create/routes';
import ProjectQuoteOverviewRoute from '@/modules/project-quote-overview/routes';
import ProjectQuoteRoute from '@/modules/project-quotes/routes';
import ProjectScheduleRoute from '@/modules/project-schedule/routes';
// main pages
import ProjectSearchRoute from '@/modules/project-search/routes';
import ProjectStagesTasksRoute from '@/modules/project-stages-tasks/routes';
import ProjectStageDepositPaymentRoute from '@/modules/project-stages-tasks/routes/project-stage-deposit-payment';
import ProjectRoute from '@/modules/projects/routes';
import PropertiesRoute from '@/modules/properties/routes';
import PropertiesBillingRoute from '@/modules/properties-billing/routes';
import PropertyAnalyticsRoute from '@/modules/property-analytics/routes';
import PropertyAnalyticsPlanningRoute from '@/modules/property-analytics-planning/routes';
import PropertyDetailsRoute from '@/modules/property-details/routes';
import PropertyListingRoute from '@/modules/property-listing/routes';
import RegistrationRoute from '@/modules/registration/routes';
import SearchMcsLookupDataRoute from '@/modules/search-mcs-lookup-data/routes';
import SelectPropertyRoute from '@/modules/select-property/routes';
import TradePassportRoute from '@/modules/trade-passport/routes';
import UnAuthorizeRoute from '@/modules/unauthorize/routes';
import WorkstationSetup from '@/modules/workstation-setup/routes';
import store from '@/store/index';
const routes = [
    {
        path: '/',
        component: () => import('@/core/components/layouts/MainLayout.vue'),
        name: 'main.layout',
        redirect: () => {
            // const authenticatedUser = store.getters['authentication/authenticatedUser'];
            // if (!authenticatedUser && (authenticatedUser.roleId === RolesEnum.TRADESPERSON)) {
            //   return '/dashboard';
            // }
            return '/dashboard';
        },
        meta: {
            requiresAuth: true,
            authorize: [],
        },
        children: [
            CalendarRoute,
            InsuranceRoute,
            DocumentsRoute,
            OpportunityBillingRoute,
            OpportunityCalendarRoute,
            OpportunityDetailsRoute,
            OpportunityRoute,
            ProjectDetailsRoute,
            ProfileRoute,
            SelectPropertyRoute,
            PropertiesRoute,
            ProjectSearchRoute,
            ProjectQuoteRoute,
            ProjectMessages,
            ProjectJobRoute,
            ProjectRoute,
            ProjectPostRoute,
            // ProjectOverviewRoute,
            DashboardRoute,
            VerifyWithdrawalRoute,
            ProjectQuoteCreateRoute,
            ProjectQuoteOverviewRoute,
            ProjectStagesTasksRoute,
            ProjectStageDepositPaymentRoute,
            AccountRoute,
            AccountSetupRoute,
            AccountSettingsRoute,
            AccountProfileRoute,
            MessagesRoute,
            AccountRoleSetupRoute,
            ProjectEstimateRoute,
            ProjectScheduleRoute,
            EwalletRoute,
            PropertyListingRoute,
            PropertiesBillingRoute,
            PropertyDetailsRoute,
            CohortsRoute,
            CohortPostRoute,
            CohortInvoiceRoute,
            CreatePropertyMapRoute,
            ProjectPostOverviewRoute,
            MembersRoute,
            ManageJobsRoute,
            PostOnboardRoute,
            PassportOverviewRoute,
            TradePassportRoute,
            WorkstationSetup,
            LockedPageRoute,
            CoursesRoute,
            ProjectDashboardRoute
        ]
    },
    LoginRoute,
    ForgotPasswordRoute,
    ChangePasswordRoute,
    ...RegistrationRoute,
    UnAuthorizeRoute,
    SearchMcsLookupDataRoute,
    EpcRecommendationRoute,
    EpcDataRoute,
    PropertyAnalyticsRoute,
    PropertyAnalyticsPlanningRoute,
    // otherwise redirect to main page
    { path: '/:catchAll(.*)', redirect: '/' },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        const scroll = document.getElementById('page-content-scroll');
        if (scroll) {
            const wrap = scroll.firstElementChild;
            if (wrap) {
                wrap.scrollTop = 0;
            }
        }
    },
});
router.beforeResolve((to, from, next) => {
    next((vm) => {
        // console.log(vm);
        // eslint-disable-next-line no-param-reassign
        vm.prevRoute = from;
    });
});
let intercomScriptLoaded = false;
router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters['authentication/isAuthenticated'];
    // Check if page route requires authentication
    const isRequireAuth = to.matched.some((route) => route.meta.requiresAuth === true);
    // validateAuth flag checks if page need redirect only if it's authenticated
    const isValidateAuth = to.matched.some((route) => route.meta.validateAuth === true);
    // Check if page is requires an authentication
    if ((isRequireAuth) && !isAuthenticated) {
        next({ path: '/login' });
    }
    else if (isRequireAuth && isAuthenticated) {
        const authorize = to.meta.authorize;
        // route.meta = { authorize: [<RolesEnum config>] } is required if requiresAuth is defined
        if (isRequireAuth && authorize === undefined) {
            throw new Error('Error configuration in route. Property "authorize" is required in meta route');
        }
        const userState = store.getters['authentication/authenticatedUser'];
        const notInMainLayout = store.getters['users/notInMainLayout'];
        if (isAuthenticated && userState) {
            const { hasChosenRole, hasAccountSetup, hasBusinessType } = userState;
            // const { hasChosenRole } = userState;
            if (to.name !== ACCOUNT_ROLE_SETUP && !hasChosenRole) {
                return next({ name: ACCOUNT_ROLE_SETUP });
            }
            if (to.meta && to.name !== DASHBOARD_ROUTE && (hasChosenRole && !hasAccountSetup && !notInMainLayout)) {
                window.location.href = `/${DASHBOARD_ROUTE}`; // tmp reload
            }
            if (to.meta && to.name !== DASHBOARD_ROUTE && (hasAccountSetup && !hasBusinessType && !notInMainLayout)) {
                window.location.href = `/${DASHBOARD_ROUTE}`; // tmp reload
            }
            if (to.meta && to.name !== DASHBOARD_ROUTE && to.meta?.disableRoute && !notInMainLayout) {
                window.location.href = `/${DASHBOARD_ROUTE}`; // tmp reload
            }
            if ((authorize && authorize.length) && !authorize.includes(userState.roleId)) {
                // remove temporarily as it is causing issues
                // return next('/unauthorize');
                return next('/');
            }
        }
        if (window?.Intercom) {
            window.Intercom('update', {
                // hide_default_launcher: (to.name === 'messages' || to.name === 'project-messages')
                hide_default_launcher: to.name !== 'dashboard'
            });
        }
    }
    else if (isValidateAuth && isAuthenticated) {
        return next('/');
    }
    if (!intercomScriptLoaded) {
        let authUserData;
        const checkUserData = () => {
            setTimeout(() => {
                authUserData = store.getters['users/user'];
                const roleId = authUserData?.role?.id;
                if (!authUserData) {
                    checkUserData();
                }
                else {
                    const userHash = CryptoJS.HmacSHA256(authUserData.email, process.env.VUE_APP_INTERCOM_SECRET_KEY).toString();
                    let jobTitle;
                    if (roleId && roleId === RolesEnum.PROJECT_OWNER) {
                        jobTitle = 'Property Owner';
                    }
                    else if (roleId && roleId === RolesEnum.TRADESPERSON) {
                        jobTitle = 'Tradesperson';
                    }
                    window.intercomSettings = {
                        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
                        name: `${authUserData.firstName} ${authUserData.lastName}`,
                        email: authUserData.email,
                        created_at: authUserData.createdAt,
                        user_hash: userHash,
                        job_title: jobTitle,
                        user_id: authUserData.id,
                        phone: authUserData.contactNo,
                        hide_default_launcher: false
                        // company_name: ??? user_business_details.company_name
                    };
                    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/f1slas2m'
                    const intercom = () => {
                        var w = window;
                        var ic = w.Intercom;
                        if (typeof ic === 'function') {
                            ic('reattach_activator');
                            ic('update', w.intercomSettings);
                        }
                        else {
                            var d = document;
                            var i = function () {
                                (i || {}).c(arguments);
                            };
                            i.q = [];
                            i.c = function (args) {
                                i.q.push(args);
                            };
                            w.Intercom = i;
                            var l = function () {
                                var s = d.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = `https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}`;
                                var x = d.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                            };
                            if (document.readyState === 'complete') {
                                l();
                            }
                            else if (w.attachEvent) {
                                w.attachEvent('onload', l);
                            }
                            else {
                                w.addEventListener('load', l, false);
                            }
                        }
                    };
                    intercom();
                    intercomScriptLoaded = true;
                }
            }, 300);
        };
        checkUserData();
    }
    setTimeout(() => {
        store.dispatch('setIsLoading', true);
    }, 100);
    return next();
});
// router.afterEach(() => {
//   store.dispatch('setIsLoading', false);
// });
export default router;
