import ProjectStageService from '@/core/services/project-stages.service';
export default {
    /**
     * Fetch project stage details
     * @param projectQuoteId
     * @param projectStageId
     * @returns ProjectStage
     */
    getProjectStage(context, { projectQuoteId, projectStageId }) {
        return ProjectStageService.getProjectStage(projectQuoteId, projectStageId);
    },
    /**
     * Fetch project stage details
     * @param projectQuoteId
     * @param projectStageId
     * @returns ProjectStage
     */
    createProjectStageInvoice(context, { projectQuoteId, projectStageId, ...requiredData }) {
        return ProjectStageService.createProjectStageInvoice(projectQuoteId, projectStageId, requiredData);
    },
    setSelectedStagesToPay({ commit }, value) {
        commit('setSelectedStagesToPay', value);
    }
};
