/**
 * search-mcs-lookup-data route page
 */
export const SEARCH_MCS_LOOKUP_DATA_ROUTE = 'search-mcs-lookup-data';
export default {
    path: '/search-mcs-lookup-data',
    name: SEARCH_MCS_LOOKUP_DATA_ROUTE,
    component: () => import('@/modules/search-mcs-lookup-data/pages/SearchMcsLookupDataPage.vue'),
    meta: {
        disableRoute: false,
        validateAuth: true,
    }
};
