import { RolesEnum } from '@/core/packages/shared-library';
import ProjectDetailsChildrenRoute from '@/modules/project-details-children/routes';
export const PROJECT_DETAILS = 'project-details';
export default {
    path: '/projects/:projectRefId/project-details/',
    name: PROJECT_DETAILS,
    redirect: '/projects/:projectRefId/project-details/overview',
    props: {
        default: (route) => ({ projectRefId: route.params.projectRefId })
    },
    components: {
        default: () => import('@/modules/project-details/pages/ProjectDetailsPage.vue'),
        headerMenu: () => import('@/core/components/project/ProjectDetailsHeaderMenu.vue')
        // headerMenu: () => import('@/core/components/project/ProjectDetailsMenu.vue')
    },
    children: [
        ...ProjectDetailsChildrenRoute
    ],
    meta: {
        title: 'Project details',
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER],
    },
};
