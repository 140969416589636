import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Property listing
 */
export const PROPERTY_LISTING = 'property-listing';
export default {
    path: '/property-listing',
    name: PROPERTY_LISTING,
    components: {
        default: () => import('@/modules/property-listing/pages/PropertyListingPage.vue'),
        headerMenu: () => import('@/core/components/property/PropertyPageHeader.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.PROJECT_OWNER],
    },
};
