import $axios from '../utils/axios-api-config';
export default {
    /**
     * Update project quote stage status
     * @param projectQuoteId
     * @param projectStageId
     * @param updateProjectStageStatusRequestDto
     * @returns
     */
    updateProjectStageStatus(projectQuoteId, projectStageId, updateProjectStageStatusRequestDto) {
        return $axios.put(`/project-quotes/${projectQuoteId}/stages/${projectStageId}/status`, updateProjectStageStatusRequestDto);
    },
    /**
     * Update project quote stage status
     * @param projectQuoteId
     * @param projectStageId
     * @param updateProjectStageRequestDto
     * @returns
     */
    updateProjectStage(projectQuoteId, projectStageId, updateProjectStageRequestDto) {
        return $axios.put(`/project-quotes/${projectQuoteId}/stages/${projectStageId}`, updateProjectStageRequestDto);
    },
    /**
     * delete project quote stage attachment
     * @param projectQuoteId
     * @param projectStageId
     * @param projectStageAttachmentId
     * @returns
     */
    deleteProjectStageAttachment(projectQuoteId, projectStageId, projectStageAttachmentId) {
        return $axios.delete(`/project-quotes/${projectQuoteId}/stages/${projectStageId}/attachment/${projectStageAttachmentId}`);
    },
    /**
     * Update project quote stage task status
     * @param projectQuoteId
     * @param projectStageId
     * @param projectStageTaskId
     * @param projectStageTaskStatus
     * @returns
     */
    updateProjectStageTaskStatus(projectQuoteId, projectStageId, projectStageTaskId, projectStageTaskStatus) {
        return $axios.put(`/project-quotes/${projectQuoteId}/stages/${projectStageId}/tasks/${projectStageTaskId}`, { done: projectStageTaskStatus });
    },
};
