export default {
    openCheckEligibilityModal: false,
    currentCourse: null,
    courses: [],
    coursesMenu: [
        {
            id: 1,
            name: 'Courses',
            icon: 'home-filled-icon.svg',
            route: 'courses-opportunities',
            isDisabled: false
        },
        {
            id: 2,
            name: 'Events',
            icon: 'project-icon.svg',
            route: 'courses-events',
            isDisabled: true
        },
        {
            id: 3,
            name: 'Enrolled',
            icon: 'document-icon.svg',
            route: 'courses-enrolled',
            isDisabled: false
        },
        {
            id: 4,
            name: 'Completed',
            icon: 'services-icon.svg',
            route: 'courses-completed',
            isDisabled: true
        },
    ],
    coursesCurrentMenuId: 1,
};
