import $axios from '@/core/utils/axios-api-config';
export default {
    getUserContractors(userId) {
        return $axios.get(`/user/${userId}/contractors`);
    },
    async addJobClient(userId, formData) {
        return $axios.post(`/user/${userId}/contractors`, formData);
    },
    async addOrUpdateClientAddress(userId, contractorId, formData) {
        return $axios.post(`/user/${userId}/contractors/address/${contractorId}`, formData);
    },
    deleteClient(userId, id) {
        return $axios.delete(`/user/${userId}/contractors/${id}`);
    },
    deleteClientAddress(userId, id) {
        return $axios.delete(`/user/${userId}/contractors/address/${id}`);
    },
    async updateJobClient(userId, id, formData) {
        return $axios.put(`/user/${userId}/contractors/${id}`, formData);
    },
};
