export default {
    currentDashboardMenuId: 1,
    projectDashboardMenu: [
        {
            id: 1,
            name: 'New Projects',
            icon: 'home-filled-icon.svg',
            route: 'new-project',
            isDisabled: false,
        },
        {
            id: 2,
            name: 'Active Projects',
            icon: 'project-icon.svg',
            route: 'active-project',
            isDisabled: false,
        },
        {
            id: 3,
            name: 'Completed Projects',
            icon: 'document-icon.svg',
            route: 'completed-project',
            isDisabled: false,
        },
    ],
};
