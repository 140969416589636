export default {
    setCategories(state, categories) {
        state.categories = categories;
    },
    setProjectStatuses(state, projectStatuses) {
        state.projectStatuses = projectStatuses;
    },
    setSelectedProjectJobStatus(state, projectJobStatus) {
        state.selectedProjectJobStatus = projectJobStatus;
    },
    setCurrentPageViewMapping(state, currentMenu) {
        state.currentPageViewMapping = { ...state.currentPageViewMapping, ...currentMenu };
    },
    setOpenSummaryEstimateDrawer(state, value) {
        state.openSummaryEstimateDrawer = value;
    },
    setOpenEscrowDrawer(state, value) {
        state.openEscrowDrawer = value;
    },
    setEnableProjectEscrowSidebar(state, value) {
        state.enableProjectEscrowSidebar = value;
    },
    setHasGetProjectFinished(state, value) {
        state.hasGetProjectFinished = value;
    },
    setIsProjectReadyToWork(state, value) {
        state.isProjectReadyToWork = value;
    },
    setCurrentSelectedProject(state, value) {
        state.currentSelectedProject = value;
    },
    setProjectAccountBalance(state, value) {
        state.projectAccountBalance = value;
    },
    setSelectedStagesToPay(state, value) {
        state.selectedStagesToPay = value;
    },
    setProjects(state, value) {
        state.projects = value;
    },
    setOpenReviewModal(state, value) {
        state.openReviewModal = value;
    },
    setToBePassedCategories(state, value) {
        state.toBePassedCategories = value;
    },
    setMainCategories(state, value) {
        state.mainCategories = value;
    },
    setSubCategories(state, value) {
        state.subCategories = value;
    },
    setFindJobsSearchValue(state, value) {
        state.findJobsSearchValue = value;
    },
    setSearchCategories(state, searchCategories) {
        state.searchCategories = searchCategories;
    },
    setCategoryList(state, categoryList) {
        state.categoryList = categoryList;
    },
};
