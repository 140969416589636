import $axios from '../utils/axios-api-config';
export default {
    /**
     * Authenticate user using the credentials provided
     * @param username
     * @param password
     * @returns
     */
    login(username, password) {
        return $axios.post('/auth/login', { username, password });
    },
    /**
     * Registers user either TP or PO
     * @param user
     * @param path
     */
    register(createUserRequestDto, path) {
        return $axios.post(`/auth/register/${path}`, createUserRequestDto);
    },
    /**
     * Verify if email exists or not
     *
     * @param value
     * @returns
     */
    authenticateEmail(value) {
        return $axios.get(`/auth/verify/email/${value}`);
    },
    /**
     * Checks if phone number already exists
     * @param value
     * @returns
     */
    authenticatePhoneNumber(value) {
        return $axios.get(`/auth/verify/phone-number/${value}`);
    },
    /**
     * Resend verification code to user's email
     * @param email
     * @returns
     */
    resendVerificationCode(email) {
        return $axios.post('/auth/verify/resend', { email });
    },
    /**
     * Verify verification code
     * @param data
     * @returns
     */
    verifyVerificationCode(data) {
        return $axios.post('/auth/verify', data);
    },
    /**
     * Send link to user's email for the change password
     * @param email
     * @returns
     */
    resetPassword(email) {
        return $axios.post('/forgot-password/reset', { email });
    },
    /**
     * Verify the reset password token
     * @param token
     * @returns
     */
    verifyResetPasswordToken(token) {
        return $axios.get(`/forgot-password/${encodeURIComponent(token)}/verify`);
    },
    /**
     * Change user password
     * @param data
     * @returns
     */
    changePassword(data) {
        return $axios.put('/forgot-password/change/new-password', data);
    },
};
