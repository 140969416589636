export default {
    setEstimateForm(state, value) {
        if (value) {
            state.estimateForm = JSON.parse(JSON.stringify(value));
        }
        else {
            state.estimateForm.projectStages = [JSON.parse(JSON.stringify(state.defaultProjectStageForm))];
        }
    },
    setSelectedPhaseIndex(state, value) {
        state.selectedPhaseIndex = value;
    },
    setTemplateName(state, value) {
        state.templateName = value;
    },
    addProjectStageMutation(state, newProjectStage) {
        state.estimateForm.projectStages.push(newProjectStage);
    },
    setShowPhaseUploadAttachmentModal(state, value) {
        state.showPhaseUploadAttachmentModal = value;
    },
    setShowTaskUploadAttachmentModal(state, value) {
        state.showTaskUploadAttachmentModal = value;
    },
    setShowCreateTemplateConfirmationModal(state, value) {
        state.showCreateTemplateConfirmationModal = value;
    },
    setShowUseTemplateModal(state, value) {
        state.showUseTemplateModal = value;
    },
    setShowSubmitEstimateConfirmationModal(state, value) {
        state.showSubmitEstimateConfirmationModal = value;
    },
    setSelectedJobTemplate(state, value) {
        state.selectedJobTemplate = value;
    },
    setIsOnEstimateForm(state, value) {
        state.isOnEstimateForm = value;
    },
    setConfirmDeleteModal(state, value) {
        state.confirmDeleteModal = value;
    },
    setIsFormReady(state, value) {
        state.isFormReady = value;
    },
    setConfirmLoadTemplateModal(state, value) {
        state.confirmLoadTemplateModal = value;
    },
    setSelectedTemplateId(state, value) {
        state.selectedTemplateId = value;
    },
    setFormData(state, value) {
        state.formData = value;
    },
    setSelectDepositModal(state, value) {
        state.selectDepositModal = value;
    },
    setIsTemplateCreate(state, value) {
        state.isTemplateCreate = value;
    },
    setIsEstimateLoadedModified(state, value) {
        state.isEstimateLoadedModified = value;
    },
    setHasLoadedTemplate(state, value) {
        state.hasLoadedTemplate = value;
    },
    setLoadedEstimate(state, value) {
        state.loadedEstimate = value;
    },
    setIsFormReadOnly(state, value) {
        state.isFormReadOnly = value;
    },
    setLockTemplateModifiedChecker(state, value) {
        state.lockTemplateModifiedChecker = value;
    },
    setSelectedAssignMembers(state, value) {
        state.selectedAssignMembers = value;
    },
    setAssignMembersModal(state, value) {
        state.assignMembersModal = value;
    },
    setAssignMembers(state, value) {
        state.assignMembers = value;
    },
    setMemberList(state, value) {
        state.memberList = value;
    },
    setShowUpdateEstimateConfirmationModal(state, value) {
        state.showUpdateEstimateConfirmationModal = value;
    },
};
