export default {
    setInsurances(state, payload) {
        state.insurances = payload;
    },
    setCurrentJourney(state, value) {
        state.currentJourney = value;
    },
    setPreviousJourney(state, value) {
        state.previousJourney = value;
    },
    setIsCompletedJourney(state, value) {
        state.isCompletedJourney = value;
    },
    setFirstApplicantDetailsTitle(state, value) {
        state.firstApplicantData.applicantTitle = value;
    },
    setSecondApplicantDetails(state, value) {
        state.secondApplicantData.applicantTitle = value.applicantTitle;
        state.secondApplicantData.firstName = value.firstName;
        state.secondApplicantData.lastName = value.lastName;
        state.secondApplicantData.dob = value.dob;
    },
    setIsApplyInsurance(state, value) {
        state.isApplyInsurance = value;
    },
    setAgreedAllAgreement(state, value) {
        state.agreedAllAgreement = value;
    },
    setPhoneAndBikesModal(state, value) {
        state.phoneAndBikesModal = value;
    },
    setAddSecondApplicantModal(state, value) {
        state.addSecondApplicantModal = value;
    },
};
