import InsuranceService from '@/core/services/insurances.service';
export default {
    getInsurances({ commit }) {
        return InsuranceService.getInsurances()
            .then((response) => {
            commit('setInsurances', response);
        });
    },
    setCurrentJourney({ commit }, value) {
        commit('setCurrentJourney', value);
    },
    setPreviousJourney({ commit }, value) {
        commit('setPreviousJourney', value);
    },
    setIsCompletedJourney({ commit }, value) {
        commit('setIsCompletedJourney', value);
    },
    setFirstApplicantDetailsTitle({ commit }, value) {
        commit('setFirstApplicantDetailsTitle', value);
    },
    setSecondApplicantDetails({ commit }, value) {
        commit('setSecondApplicantDetails', value);
    },
    setIsApplyInsurance({ commit }, value) {
        commit('setIsApplyInsurance', value);
    },
    setAgreedAllAgreement({ commit }, value) {
        commit('setAgreedAllAgreement', value);
    },
    setPhoneAndBikesModal({ commit }, value) {
        commit('setPhoneAndBikesModal', value);
    },
    setAddSecondApplicantModal({ commit }, value) {
        commit('setAddSecondApplicantModal', value);
    }
};
