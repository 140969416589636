import * as CryptoJS from 'crypto-js';
import ImageTokenHelper from '@/core/helpers/image-token.helper';
import { API_URL } from '../config';
import $axios from '../core/utils/axios-api-config';
function getImageApiUrl(value, encoded = false) {
    let encodedParam = '';
    const expiration = ImageTokenHelper.getImageExpirationTokenStorage();
    const encryptedToken = CryptoJS.AES.encrypt(expiration ?? '', process.env.VUE_APP_CRYPTO_SECRET_KEY).toString();
    if (encoded) {
        encodedParam = 'encoded=1&';
    }
    const url = `${API_URL}/upload?${encodedParam}key=${value}&token=${encodeURIComponent(encryptedToken)}`;
    return url;
}
export default {
    /**
     * Format to image source url
     * @param value
     * @returns
     */
    async formatToImageSourceUrl(value) {
        const url = getImageApiUrl(value);
        const sourceUrl = await $axios.get(url);
        return sourceUrl;
    },
    formatToFileType: (file) => file.split('.')[file.split('.').length - 1],
    async getEncodedAttachment(value) {
        const url = getImageApiUrl(value, true);
        const encoded = await $axios.get(url);
        return encoded;
    },
    async deleteAttachment(value) {
        const url = getImageApiUrl(value);
        return $axios.delete(url);
    }
};
