import { RolesEnum } from '@/core/packages/shared-library';
// import { PROPERTIES_ROUTE } from '@/modules/properties/routes';
import { CALENDAR_ROUTE } from '@/modules/calendar/routes';
import { COURSES_ROUTE } from '@/modules/courses/routes';
import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
import { DOCUMENTS_ROUTE } from '@/modules/documents/routes';
import { E_WALLET_ROUTE } from '@/modules/e-wallet/routes';
import { MANAGE_JOB_ROUTE } from '@/modules/manage-job/routes';
import { MEMBERS_ROUTE } from '@/modules/members/routes';
import { MESSAGES_ROUTE } from '@/modules/messages/routes';
import { OPPORTUNITY_ROUTE } from '@/modules/opportunity/routes';
// import { PROJECT_JOBS_ROUTE } from '@/modules/project-jobs/routes';
// import { PROJECT_POST_ROUTE } from '@/modules/project-post/routes';
// import { PROJECT_QUOTES_ROUTE } from '@/modules/project-quotes/routes';
import { PROJECT_DASHBOARD_ROUTE } from '@/modules/project-dashboard/routes';
import { PROJECT_SEARCH_ROUTE } from '@/modules/project-search/routes';
import { PROJECTS_ROUTE } from '@/modules/projects/routes';
import { PROPERTY_LISTING } from '@/modules/property-listing/routes';
import { SELECT_PROPERTY_ROUTE } from '@/modules/select-property/routes';
import { TRADE_PASSPORT_ROUTE } from '@/modules/trade-passport/routes';
export default {
    isLoading: false,
    // initialize to null. Initialize the value to the main layout depending on the device
    isNavCollapse: null,
    openUserReferAndEarnModal: false,
    openUnverifiedAccountModal: false,
    openUserBuildProfileModal: false,
    openAddNewSkillRedirectModal: false,
    openEwalletConfirmInterestModal: false,
    openEwalletThankYouModal: false,
    openWorkstationChooseModal: false,
    openWorkstationCompanyModal: false,
    openWorkstationPlanModal: false,
    openWorkstationInviteModal: false,
    openWorkstationProfileModal: false,
    openWorkstationPaymentConfirmationModal: false,
    openProjectDescriptionModal: false,
    openProjectManageMembersModal: false,
    openProjectAttachmentsModal: false,
    openProjectBudgetModal: false,
    openAccountSetupModal: false,
    openPassportPreviewModal: false,
    openPaymentSuccessfulModal: false,
    openPaymentUnsuccessfulModal: false,
    openSoleTraderBillinglModal: false,
    workstationSetupModal: false,
    soleTraderSetupModal: false,
    limitedCompanySetupModal: false,
    // isCohort: null as boolean | null,
    // isCohort: 4,
    // new sidebar
    sidebarMenu: [
        {
            label: 'Chat',
            icon: 'chat.svg',
            name: MESSAGES_ROUTE,
            route: '/messages',
            class: '',
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'Home',
            icon: 'home.svg',
            name: DASHBOARD_ROUTE,
            route: '/dashboard',
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'Trades Passport',
            icon: 'post-project-2.svg',
            name: TRADE_PASSPORT_ROUTE,
            route: `/${TRADE_PASSPORT_ROUTE}`,
            authorize: [RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'Post Project',
            icon: 'post-project-2.svg',
            name: SELECT_PROPERTY_ROUTE,
            route: `/${SELECT_PROPERTY_ROUTE}`,
            authorize: [RolesEnum.PROJECT_OWNER],
            active: true,
            hide: false
        },
        {
            label: 'Properties',
            icon: 'properties-icon.svg',
            name: PROPERTY_LISTING,
            route: '/property-listing',
            authorize: [RolesEnum.PROJECT_OWNER],
            active: true,
            hide: false
        },
        {
            label: 'Projects',
            icon: 'my-document.svg',
            name: PROJECT_DASHBOARD_ROUTE,
            route: `/${PROJECT_DASHBOARD_ROUTE}`,
            authorize: [RolesEnum.PROJECT_OWNER],
            active: true,
            hide: false
        },
        {
            label: 'Find Jobs',
            icon: 'lens.svg',
            name: PROJECT_SEARCH_ROUTE,
            route: '/find-jobs',
            authorize: [RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        // {
        //   label: 'My Quotes',
        //   icon: 'quotes.svg',
        //   name: PROJECT_QUOTES_ROUTE,
        //   route: '/my-quotes',
        //   authorize: [RolesEnum.TRADESPERSON],
        //   active: true
        // },
        // {
        //   label: 'My Jobs',
        //   icon: 'my-document.svg',
        //   name: PROJECT_JOBS_ROUTE,
        //   route: '/my-jobs',
        //   authorize: [RolesEnum.TRADESPERSON],
        //   active: true,
        //   hide: false
        // },
        {
            label: 'Manage Jobs',
            icon: 'my-document.svg',
            name: MANAGE_JOB_ROUTE,
            route: '/manage-jobs',
            authorize: [RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'Courses',
            icon: 'opportunities-icon.svg',
            name: COURSES_ROUTE,
            route: '/courses',
            authorize: [RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'Opportunities',
            icon: 'opportunities-icon.svg',
            name: OPPORTUNITY_ROUTE,
            route: '/opportunity',
            authorize: [RolesEnum.TRADESPERSON],
            active: true,
            hide: true
        },
        {
            label: 'Calendar',
            icon: 'calendar-icon.svg',
            name: CALENDAR_ROUTE,
            route: '/calendar',
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'My Projects',
            icon: 'my-document.svg',
            name: PROJECTS_ROUTE,
            route: '/projects',
            authorize: [RolesEnum.PROJECT_OWNER],
            active: true,
            hide: false
        },
        {
            label: 'Documents',
            icon: 'document-icon.svg',
            name: DOCUMENTS_ROUTE,
            route: '/documents',
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'E-wallet',
            icon: 'wallet.svg',
            name: E_WALLET_ROUTE,
            route: '/e-wallet',
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
        {
            label: 'Members',
            icon: 'members-icon.svg',
            name: MEMBERS_ROUTE,
            route: '/members',
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
            active: true,
            hide: false
        },
    ],
    userMockData: [
        {
            id: 1,
            firstName: 'Jasper',
            lastName: 'Gar',
            email: 'jaspergar@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg'
        },
        {
            id: 2,
            firstName: 'Phil',
            lastName: 'Mendoza',
            email: 'philmendoza@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg'
        },
        {
            id: 3,
            firstName: 'Keano',
            lastName: 'Chang',
            email: 'keano@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg'
        },
        {
            id: 4,
            firstName: 'Allar',
            lastName: 'Klein',
            email: 'allar@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg'
        },
    ],
    userMockDataWithRole: [
        {
            id: 1,
            firstName: 'Sheryl',
            lastName: 'Hon',
            email: 'sherylhon@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg',
            role: 'View',
        },
        {
            id: 2,
            firstName: 'Martin',
            lastName: 'Lopez',
            email: 'martin@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg',
            role: 'View',
        },
        {
            id: 3,
            firstName: 'Albin',
            lastName: 'Chang',
            email: 'albin@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg',
            role: 'Edit',
        },
        {
            id: 4,
            firstName: 'Tommy',
            lastName: 'Klein',
            email: 'tommy@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg',
            role: 'View',
        },
    ],
    twoUserMockDataWithRole: [
        {
            id: 1,
            firstName: 'Jasper',
            lastName: 'Gar',
            email: 'jaspergar@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg',
            role: 'Edit',
        },
        {
            id: 2,
            firstName: 'Phil',
            lastName: 'Mendoza',
            email: 'philmendoza@gmail.com',
            userPublicProfile: '1083/profiles/1094/1691989404998_aYbjQn7kJ8wX5.jpg',
            role: 'View',
        },
    ],
    generalSortFields: [{ label: 'Date Created', sortBy: 'dateCreated', sortOrder: 'DESC' }],
};
