import { RolesEnum } from '@/core/packages/shared-library';
export const PASSPORT_OVERVIEW = 'passport-overview';
export default {
    path: '/passport-overview',
    name: PASSPORT_OVERVIEW,
    component: () => import('@/modules/passport-overview/pages/PassportOverviewPage.vue'),
    meta: {
        validateAuth: true,
        isRequireAuth: true,
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER]
    }
};
