import $axios from '../utils/axios-api-config';
export default {
    getUserCourses(userId, options) {
        const params = new URLSearchParams(options);
        return $axios.get(`/users/${userId}/courses?${params.toString()}`);
    },
    createUserCourse(userId, formData) {
        return $axios.post(`/users/${userId}/courses?`, formData);
    },
    updateUserCourse(userId, id, formData) {
        return $axios.put(`/users/${userId}/courses/${id}`, formData);
    },
    deleteUserCourse(userId, formData) {
        return $axios.delete(`/users/${userId}/courses`, { data: formData });
    },
    createUserCourseSchedule(userCourseId, formData) {
        return $axios.post(`/user-course/${userCourseId}/schedules`, formData);
    },
};
