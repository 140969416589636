export default {
    getOpenConnectSmartMeterModal: (state) => state.openConnectSmartMeterModal,
    getOpenConfirmationModal: (state) => state.openConfirmationModal,
    getIsSmartMeterConnected: (state) => state.isSmartMeterConnected,
    getOpenUtilityModal: (state) => state.openUtilityModal,
    getOpenEnergyEfficiencyModal: (state) => state.openEnergyEfficiencyModal,
    getOpenEditPropertyDetailsModal: (state) => state.openEditPropertyDetailsModal,
    getOpenEnergyEfficiencyRatingModalfalse: (state) => state.openEnergyEfficiencyRatingModalfalse,
    getOpenFinanceModal: (state) => state.openFinanceModal,
    getUtilities: (state) => state.utilities,
    defaultTrialSubscriptionInDays: (state) => state.defaultTrialSubscriptionInDays
};
