import { MaterialAvailabilityEnum } from '@/core/packages/shared-library';
import { PROJECT_SEARCH_ROUTE } from '@/modules/project-search/routes';
import { PROJECT_MESSAGES_ROUTE } from '../../../modules/project-messages/routes';
import { PROJECT_OVERVIEW_ROUTE } from '../../../modules/project-overview/routes';
import { PROJECT_QUOTE_BUILDER_ROUTE } from '../../../modules/project-quote-create/routes';
import { PROJECT_QUOTE_OVERVIEW_ROUTE } from '../../../modules/project-quote-overview/routes';
import { PROJECT_STAGES_TASKS_ROUTE } from '../../../modules/project-stages-tasks/routes';
export default {
    projects: [],
    hasGetProjectFinished: false,
    enableProjectEscrowSidebar: false,
    isProjectReadyToWork: false,
    currentSelectedProject: null,
    projectAccountBalance: null,
    categories: [],
    projectStatuses: [],
    currentPageViewMapping: {
        [PROJECT_SEARCH_ROUTE]: null,
    },
    selectedProjectJobStatus: null,
    topRightSidebarMenu: [
        {
            label: 'Project Overview',
            route: PROJECT_OVERVIEW_ROUTE,
            routeParams: ['projectId'],
        },
        {
            label: 'Quote Builder',
            route: PROJECT_QUOTE_BUILDER_ROUTE,
            routeParams: ['projectId'],
        },
        {
            label: 'Quote Overview',
            route: PROJECT_QUOTE_OVERVIEW_ROUTE,
            routeParams: ['projectId'],
        },
        {
            label: 'Stages & Tasks',
            route: PROJECT_STAGES_TASKS_ROUTE,
            routeParams: ['projectId'],
        },
        {
            label: 'Messages',
            route: PROJECT_MESSAGES_ROUTE,
            routeParams: ['projectId'],
        },
    ],
    materialAvailabilityLabel: [
        { value: MaterialAvailabilityEnum.DONT_HAVE_MATERIALS, label: "I Don't Have Any Materials" },
        { value: MaterialAvailabilityEnum.HAVE_ALL_MATERIALS, label: 'I Have All Materials' },
        { value: MaterialAvailabilityEnum.HAVE_MATERIALS, label: 'I Have Some Materials' },
        { value: MaterialAvailabilityEnum.DONT_KNOW, label: "I Don't Know" },
    ],
    openSummaryEstimateDrawer: false,
    openEscrowDrawer: false,
    openReviewModal: false,
    toBePassedCategories: [],
    mainCategories: [],
    subCategories: [],
    findJobsSearchValue: {
        postcodeValue: '',
        distanceValue: ''
    },
    searchCategories: [],
    categoryList: [],
};
