import { RolesEnum } from '@/core/packages/shared-library';
export const POST_ONBOARD = 'post-onboard';
export default {
    path: '/post-onboard',
    name: POST_ONBOARD,
    component: () => import('@/modules/post-onboarding/pages/PostOnboardPage.vue'),
    meta: {
        validateAuth: true,
        isRequireAuth: true,
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER]
    }
};
