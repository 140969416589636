import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Post project route
 */
export const CALENDAR_ROUTE = 'calendar';
export default {
    path: '/calendar',
    name: CALENDAR_ROUTE,
    component: () => import('@/modules/calendar/pages/CalendarPage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER],
        title: 'Calendar',
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
