import moment from 'moment';
import { DATE_FORMAT } from '@/core/constants';
const DAYS = 6;
export default {
    /**
     * Formats standard date for the app
     * @param value
     * @returns
     */
    formatToDate: (value, customFormat = '') => {
        const currentDate = moment(value);
        let dateFormat = DATE_FORMAT;
        if (!currentDate.isValid) {
            throw new Error(`Invalid date: ${value}`);
        }
        if (customFormat) {
            dateFormat = customFormat;
        }
        return currentDate.format(dateFormat);
    },
    /**
     * Date time format
     * @param value
     * @param format
     */
    formatToDatetime: (value, format) => {
        return moment(value).format(format || 'DD/MM/YYYY HH:mm');
    },
    formatToDatetimeWithMeridian: (value, format) => {
        return moment(value).format(format || 'DD/MM/YYYY HH:mm A');
    },
    /**
     * Date time format with time ago
     * @param value
     */
    formatToDatetimeAgo: (value) => {
        return moment(value).fromNow();
    },
    getDaysInCurrentWeek: () => {
        const dateFormat = 'dddd, D MMMM YYYY';
        const days = [];
        const finalDays = [];
        const daysRequired = DAYS;
        for (let i = 0; i <= daysRequired; i++) {
            days.push(moment().add(i, 'days').format(dateFormat));
        }
        for (let x = 0; x < days.length; x++) {
            const index = moment(days[x], dateFormat).day();
            const abbrv = moment(days[x], dateFormat).format('ddd');
            const number = moment(days[x], dateFormat).format('D');
            finalDays.push({ index, abbrv, number });
        }
        return finalDays;
    },
    rawCurrentDateTime() {
        return moment();
    }
};
