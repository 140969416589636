import _ from 'lodash';
const estimateForm = {
    projectStages: [
        {
            name: '',
            stageType: 'labour',
            totalDuration: 0,
            durationType: 1,
            stageCost: 0,
            projectStageTasks: [],
            isNew: true,
            isUsePhaseTotal: null,
            requiredDeposit: false,
            vat: 0,
            subTotal: 0,
            subTotalDisplay: '',
            note: '',
            startDate: null,
        }
    ]
};
export default {
    estimateForm,
    defaultEstimateForm: _.cloneDeep(estimateForm),
    defaultProjectStageForm: {
        name: '',
        stageType: 'labour',
        totalDuration: 0,
        durationType: 1,
        stageCost: 0,
        projectStageTasks: [],
        isNew: true,
        isUsePhaseTotal: null,
        requiredDeposit: false,
        vat: 0,
        subTotal: 0,
        subTotalDisplay: '',
        note: '',
        startDate: null,
    },
    selectedPhaseIndex: 0,
    templateName: '',
    selectedJobTemplate: '',
    showPhaseUploadAttachmentModal: false,
    showTaskUploadAttachmentModal: false,
    showCreateTemplateConfirmationModal: false,
    showUseTemplateModal: false,
    showSubmitEstimateConfirmationModal: false,
    isOnEstimateForm: false,
    confirmDeleteModal: false,
    isFormReady: false,
    confirmLoadTemplateModal: false,
    selectedTemplateId: null,
    formData: {
        suggestedStartDate: null,
    },
    selectDepositModal: false,
    isTemplateCreate: false,
    isEstimateLoadedModified: false,
    hasLoadedTemplate: false,
    loadedEstimate: null,
    isFormReadOnly: false,
    lockTemplateModifiedChecker: false,
    selectedAssignMembers: null,
    assignMembersModal: false,
    assignMembers: [],
    memberList: [],
    showUpdateEstimateConfirmationModal: false,
};
