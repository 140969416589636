import UserContractorService from '@/core/services/user-contractor.service';
export default {
    setUserContractors({ commit }, userContractors) {
        commit('setUserContractors', userContractors);
    },
    getUserContractors({ commit }, userId) {
        return UserContractorService.getUserContractors(userId)
            .then((response) => {
            commit('setUserContractors', response);
        });
    },
    createUserContractor({ commit }, { userId, userContractor }) {
        return UserContractorService.createUserContractor(userId, userContractor)
            .then((response) => {
            commit('setRecentlyAdded', response);
        });
    },
    deleteUserContractor({ commit, getters }, { userId, userContractorId }) {
        return UserContractorService.deleteUserContractor(userId, userContractorId)
            .then((response) => {
            const { userContractors } = getters;
            let currentList = userContractors;
            if (response) {
                currentList = currentList.filter((item) => item.id !== userContractorId);
            }
            commit('setUserContractors', currentList);
        });
    },
    setRecentlyAdded({ commit }, userContractor) {
        commit('setRecentlyAdded', userContractor);
    },
    setToRemoveContractorId({ commit }, id) {
        commit('setToRemoveContractorId', id);
    },
    setToManageContractor({ commit }, userContractor) {
        commit('setToManageContractor', userContractor);
    },
};
