import { RolesEnum } from '@/core/packages/shared-library';
export default [
    {
        path: 'details',
        name: 'account/details',
        component: () => import('@/modules/account-details/pages/AccountStatusPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'public-profile',
        name: 'account/public-profile',
        component: () => import('@/modules/account-details/pages/ProfilePage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'business-details',
        name: 'account/business-details',
        component: () => import('@/modules/account-details/pages/BusinessDetailPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'identification',
        name: 'account/identification-check',
        component: () => import('@/modules/account-details/pages/IdentificationPage.vue'),
        props: (route) => ({
            transactionReference: route.query.transactionReference,
            customerInternalReference: route.query.customerInternalReference,
            transactionStatus: route.query.transactionStatus,
        }),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'certifcation',
        name: 'account/certification',
        component: () => import('@/modules/account-details/pages/CertificationPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'insurance',
        name: 'account/insurance',
        component: () => import('@/modules/account-details/pages/InsurancePage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    },
    {
        path: 'skills',
        name: 'account/skills',
        component: () => import('@/modules/account-details/pages/SkillsPage.vue'),
        authorize: [RolesEnum.TRADESPERSON],
    }
];
