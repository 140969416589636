import moment from 'moment';
export default {
    activeQuotes: (state) => state.activeQuotes,
    activeJobs: (state) => state.activeJobs,
    graphSeriesDataLoaded: (state) => state.graphSeriesDataLoaded,
    wallet: (state) => state.wallet,
    boxDetails: (state) => state.boxDetails,
    months: (state) => state.months,
    seriesActiveJobsByMonth: (state) => (filterMonth) => {
        const series = {
            name: 'Active Jobs',
            data: []
        };
        const currentDaysInMonth = moment().daysInMonth();
        const days = [...Array(currentDaysInMonth).keys()].map((n) => n + 1);
        days.forEach((day, index) => {
            series.data[index] = 0;
        });
        if (state.activeJobs && state.activeJobs.length) {
            state.activeJobs.forEach((job) => {
                const getMonthCreated = new Date(job.dateCreated).getMonth();
                if (filterMonth === getMonthCreated) {
                    const getDate = new Date(job.dateCreated).getDate();
                    // eslint-disable-next-line no-plusplus
                    series.data[getDate]++;
                }
            });
        }
        return series;
    },
    seriesActiveQuotesByMonth: (state) => (filterMonth) => {
        const series = {
            name: 'Active Quotes',
            data: []
        };
        const currentDaysInMonth = moment().daysInMonth();
        const days = [...Array(currentDaysInMonth).keys()].map((n) => n + 1);
        days.forEach((day, index) => {
            series.data[index] = 0;
        });
        if (state.activeQuotes && state.activeQuotes.length) {
            state.activeQuotes.forEach((job) => {
                const getMonthCreated = new Date(job.dateCreated).getMonth();
                if (filterMonth === getMonthCreated) {
                    const getDate = new Date(job.dateCreated).getDate();
                    // eslint-disable-next-line no-plusplus
                    series.data[getDate]++;
                }
            });
        }
        return series;
    },
    seriesIncomingJobsByMonth: (state, getters) => (filterMonth) => {
        const series = {
            name: 'Incoming Jobs',
            data: []
        };
        const currentDaysInMonth = moment().daysInMonth();
        const days = [...Array(currentDaysInMonth).keys()].map((n) => n + 1);
        days.forEach((day, index) => {
            series.data[index] = 0;
        });
        if (getters.incomingJobs && getters.incomingJobs.length) {
            getters.incomingJobs.forEach((job) => {
                const getMonthCreated = new Date(job.dateCreated).getMonth();
                if (filterMonth === getMonthCreated) {
                    const getDate = new Date(job.dateCreated).getDate();
                    // eslint-disable-next-line no-plusplus
                    series.data[getDate]++;
                }
            });
        }
        return series;
    },
    seriesActiveJobsByYear: (state) => (filterYear) => {
        const series = {
            name: 'Active Jobs',
            data: []
        };
        state.months.forEach((m, index) => {
            series.data[index] = 0;
        });
        if (state.activeJobs && state.activeJobs.length) {
            state.activeJobs.forEach((job) => {
                const getMonthCreated = new Date(job.dateCreated).getMonth();
                if (filterYear === new Date(job.dateCreated).getFullYear()) {
                    // eslint-disable-next-line no-plusplus
                    series.data[getMonthCreated]++;
                }
            });
        }
        return series;
    },
    seriesActiveQuotesByYear: (state) => (filterYear) => {
        const series = {
            name: 'Active Quotes',
            data: []
        };
        state.months.forEach((m, index) => {
            series.data[index] = 0;
        });
        if (state.activeQuotes && state.activeQuotes.length) {
            state.activeQuotes.forEach((job) => {
                const getMonthCreated = new Date(job.dateCreated).getMonth();
                if (filterYear === new Date(job.dateCreated).getFullYear()) {
                    // eslint-disable-next-line no-plusplus
                    series.data[getMonthCreated]++;
                }
            });
        }
        return series;
    },
    seriesIncomingJobsByYear: (state, getters) => (filterYear) => {
        const series = {
            name: 'Incoming Jobs',
            data: []
        };
        state.months.forEach((m, index) => {
            series.data[index] = 0;
        });
        if (getters.incomingJobs && getters.incomingJobs.length) {
            getters.incomingJobs.forEach((job) => {
                const getMonthCreated = new Date(job.dateCreated).getMonth();
                if (filterYear === new Date(job.dateCreated).getFullYear()) {
                    // eslint-disable-next-line no-plusplus
                    series.data[getMonthCreated]++;
                }
            });
        }
        return series;
    },
    openMoneyInRightbar: (state) => state.openMoneyInRightbar,
    openMoneyInPayManuallyRightbar: (state) => state.openMoneyInPayManuallyRightbar,
    openMoneyInSuccessRightbar: (state) => state.openMoneyInSuccessRightbar,
    openMoneyOutRightbar: (state) => state.openMoneyOutRightbar,
    openMoneyOutVerifyRightbar: (state) => state.openMoneyOutVerifyRightbar,
    openMoneyOutSuccessRightbar: (state) => state.openMoneyOutSuccessRightbar,
    openRegisterInterestModalConfirmation: (state) => state.openRegisterInterestModalConfirmation,
    openThankyouRegisterInterestModal: (state) => state.openThankyouRegisterInterestModal,
    openCurrentMembersPlanModal: (state) => state.openCurrentMembersPlanModal,
    openConfirmDeleteMembersPlanModal: (state) => state.openConfirmDeleteMembersPlanModal,
    openTransactionDetailsDrawer: (state) => state.openTransactionDetailsDrawer,
    openMoneyInDrawer: (state) => state.openMoneyInDrawer,
    openMoneyOutDrawer: (state) => state.openMoneyOutDrawer,
    withdrawalForm: (state) => state.withdrawalForm,
};
