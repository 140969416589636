export default {
    /**
     * Format project refId initText
     * @param refId
     * @returns
     */
    initText(refId, initStrLength) {
        return refId.substring(0, initStrLength);
    },
    /**
     * Format project refId remainingText
     * @param refId
     * @returns
     */
    remainingText(refId, initStrLength) {
        return refId.substring(initStrLength);
    }
};
