import axios from 'axios';
import { API_URL } from '../../config';
import { AUTH_TOKEN, AUTH_USER } from '../constants';
import { getLocalStorageWithExpiry } from './common';
const axiosRequest = axios.create({
    baseURL: API_URL,
    timeout: 60 * 1000,
});
// TODO: Need to refactor on how to dynamically list all api that doesn't request Authorization
axiosRequest.interceptors.request.use((config) => {
    const configureConfig = { ...config };
    const token = getLocalStorageWithExpiry(AUTH_TOKEN);
    const hst = window.localStorage.getItem('hst');
    const vw = window.localStorage.getItem('vw');
    if (hst) {
        configureConfig.headers.hst = hst;
    }
    if (vw) {
        configureConfig.headers.vw = vw;
    }
    if (configureConfig && configureConfig.baseURL === API_URL && token) {
        configureConfig.headers.Authorization = `Bearer ${token}`;
    }
    if (token) {
        // alias account
        const authUser = getLocalStorageWithExpiry(AUTH_USER);
        if (authUser && authUser.aliased) {
            configureConfig.headers.Aliased = true;
        }
    }
    return configureConfig;
}, (error) => {
    return Promise.reject(error);
});
axiosRequest.interceptors.response.use((response) => {
    const res = response.data;
    return res;
}, (error) => {
    const token = getLocalStorageWithExpiry(AUTH_TOKEN);
    if (error.response?.status === 403 && !token) {
        // clear all local storage
        window.localStorage.clear();
        window.location.reload();
        // (this as any).$router.push('/login');
        return null;
    }
    return Promise.reject(error.response);
});
export default axiosRequest;
