import $axios from '../utils/axios-api-config';
export default {
    /**
     * Fetch user message room by projectId, userId
     * @param userId
     * @param projectId
     * @returns UserMessageRoom[]
     */
    getUserMessageRoomByProjectId(userId, projectId) {
        return $axios.get(`/user-messages/${userId}/project/${projectId}`);
    },
    /**
     * Fetch all user message rooms
     * @param userId
     * @returns UserMessageRoom[]
     */
    getUserMessageRooms(userId) {
        return $axios.get(`/user-messages/${userId}`);
    },
    /**
     * Fetch all user messages by userMessageRoomId
     * @param userId
     * @param userMessageRoomId
     * @returns UserMessage[]
     */
    getUserMessages(userId, userMessageRoomId) {
        return $axios.get(`/user-messages/${userId}/user-room/${userMessageRoomId}`);
    },
    getUserMessagesByGroup(userId, userMessageRoomId) {
        return $axios.get(`/user-messages/${userId}/group-room/${userMessageRoomId}`);
    },
    /**
     * Post message in a userMessageRoom
     * @param userId
     * @param userMessageRoomId
     * @param formData CreateUserMessageRequestDto
     * @returns UserMessage
     */
    createUserMessage(userId, formData) {
        return $axios.post(`/user-messages/${userId}`, formData);
    },
    /**
     * Create user message room if not exists
     * @param userId
     * @param formData CreateUserMessageRoomRequestDto
     * @returns UserMessageRoom
     */
    createUserMessageRoom(userId, formData) {
        return $axios.post(`/user-messages/${userId}/user-room`, formData);
    },
    createUserGroupMessageRoom(userId, formData) {
        return $axios.post(`/user-messages/${userId}/user-group-room`, formData);
    },
    /**
     * Update user messages by userMessageRoomId
     * @param userId
     * @param userMessageRoomId
     * @param formData
     * @returns UserMessages[]
     */
    updateUserMessagesByUserMessageRoom(userId, userMessageRoomId, formData) {
        return $axios.put(`/user-messages/${userId}/user-room/${userMessageRoomId}`, formData);
    },
    /**
     * Upload file attachment on user message room via userMessageRoomId
     * @param userId
     * @param userMessageRoomId
     * @param file
     * @returns
     */
    createUserMessageRoomAttachment(userId, userMessageRoomId, file) {
        return $axios.post(`/user-messages/${userId}/user-room/${userMessageRoomId}/attachment`, file);
    },
    /**
     * Fetch user messages by filters(params)
     * @param userId
     * @param params
     * @returns UserMessages[]
     */
    getUserMessagesFilters(userId, params = {}) {
        return $axios.get(`/user-messages/${userId}/filters/`, { params });
    },
    /**
     * Fetch all user message rooms
     * @param userId
     * @returns UserMessageRoom[]
     */
    getGroupContacts(userId) {
        return $axios.get(`/user-messages/${userId}/contacts`);
    },
};
