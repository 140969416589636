import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Project quotes route page
 */
export const PROJECT_QUOTES_ROUTE = 'project-quotes';
export default {
    path: '/my-quotes',
    name: PROJECT_QUOTES_ROUTE,
    components: {
        default: () => import('@/modules/project-quotes/pages/ProjectQuotePage.vue'),
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'My Quotes',
        authorize: [RolesEnum.TRADESPERSON],
    }
};
