import $axios from '../utils/axios-api-config';
export default {
    /**
     * Get project quote statuses with count state
     * @param params
     * @returns ProjectQuoteStatusListResponseDto
     */
    /* getProjectQuoteStatusWithCountStatus(params): Promise<ProjectQuoteStatusListResponseDto> {
      return $axios.get('/project-filters/my-quotes', { params });
    }, */
    /**
     * Get project quote/job statuses with count state
     * @param type
     * @param params
     * @returns ProjectJobStatusListResponseDto
     */
    getProjectStatusWithCountStatus(type) {
        return $axios.get(`/project-filters/${type}`);
    },
    /**
     * Get the project categories
     * @returns
     */
    getCategories() {
        return $axios.get('/categories');
    },
    /**
     * Get the project list
     * @param params
     * @returns ProjectListResponseDto
     */
    getProjectPageList(params) {
        return $axios.get('/projects', { params });
    },
    /**
     * Get the projecy ById
     * @param projectId
     * @returns GetProjectResponseDto
     */
    getProjectByRefId(projectRefId) {
        return $axios.get(`/projects/${projectRefId}`);
    },
    /**
     * Get project statuses
     * @returns
     */
    getProjectStatus() {
        return $axios.get('/project-status');
    },
    /**
     * Create new project record
     * @param createProjectRequestDto CreateProjectRequestDto
     * @returns CreateProjectResponseDto
     */
    async saveProject(createProjectRequestDto) {
        return $axios.post('/projects', createProjectRequestDto);
    },
    /**
     * Update existing project record
     * @param projectId
     * @param updateProjectRequestDto UpdateProjectRequestDto
     * @returns UpdateProjectResponseDto
     */
    updateProject(projectId, updateProjectRequestDto) {
        return $axios.put(`/projects/${projectId}`, updateProjectRequestDto);
    },
    /**
     * Delete existing project record
     * @param projectId
     * @returns
     */
    deleteProject(projectId) {
        return $axios.delete(`/projects/${projectId}`);
    },
    /**
     * Get project jobs
     * @returns JobListRequestDto
     */
    getProjectJobs(params = {}) {
        return $axios.get('/jobs', { params });
    },
    /**
     * Get project jobs
     */
    getProjectJobsCategories(params = {}) {
        return $axios.get('/jobs/categories', { params });
    },
    /**
     * Get project jobs by keywords
     */
    getProjectJobsCategoriesByKeywords(keywords) {
        return $axios.get(`/jobs/categories?keywords[]=${keywords}`);
    },
    /**
     * Get project jobs by categories filter
     */
    getProjectJobsByCategoriesFilter(keywords = []) {
        const url = '/jobs?keywords[]='.concat(encodeURIComponent(keywords.join('&keywords[]=')));
        return $axios.get(url);
    },
    /**
     * Get my jobs
     * @returns JobListRequestDto
     */
    getProjectMyJobs(params = {}) {
        return $axios.get('/projects', { params });
    },
    /**
     * Get project quotes by projectId
     * @returns
     */
    getProjectMyQuotes(params = {}) {
        return $axios.get('/projects', { params });
    },
    /**
     * Get project account details by projectId
     * @returns
     */
    getProjectAccountDetails(projectId) {
        return $axios.get(`/project-accounts/${projectId}`);
    },
    getMemberPermissions() {
        return $axios.get('/member-permissions');
    },
    getProjectMembers(projectId) {
        return $axios.get(`/projects/${projectId}/members`);
    },
    getProjectMembersPokedState(projectId) {
        return $axios.get(`/projects/${projectId}/members/poked`);
    },
    createProjectMembers(projectId, formData) {
        return $axios.post(`/projects/${projectId}/members`, formData);
    },
    updateProjectMember(projectId, projectMemberId, formData) {
        return $axios.put(`/projects/${projectId}/members/${projectMemberId}`, formData);
    },
    deleteProjectMember(projectId, projectMemberId) {
        return $axios.delete(`/projects/${projectId}/members/${projectMemberId}`);
    },
};
