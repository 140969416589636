import $axios from '../utils/axios-api-config';
export default {
    /**
     * Get user information by token
     * @param userId
     * @returns
     */
    getUser(userId) {
        return $axios.get(`/users/${userId}`);
    },
    /**
     * Resets user password
     * @param email
     * @returns
     */
    resetPassword(email) {
        return $axios.post('/forgot-password/reset', { email });
    },
    /**
     * Get user by id
     * @param id
     * @returns
     */
    getUserById(id) {
        return $axios.get(`/users/${id}`);
    },
    /**
     * Get all users
     * @returns
     */
    getUsers() {
        return $axios.get('/users');
    },
    /**
     * Activate the user account
     * @param userId
     * @returns
     */
    activateUserAccount(userId) {
        return $axios.put(`/users/${userId}/activate`);
    },
    /**
     * Update user information
     * @param userId
     * @param user
     * @returns
     */
    updateUser(userId, user) {
        return $axios.put(`/users/${userId}`, user);
    },
    selectAccount(data) {
        return $axios.post('/users/select-account', data);
    },
    selectRole(data) {
        return $axios.post('/users/select-role', data);
    },
    /**
     *
     * @param userId
     * @param data
     * @returns
     */
    verifyWithdrawal(userId, data) {
        return $axios.post(`/users/${userId}/accounts/verify/code`, data);
    },
    /**
     *
     * @param userId
     * @returns
     */
    sendVerificationWithdrawal(userId) {
        return $axios.get(`/users/${userId}/accounts/send-verification`);
    },
    /**
     *
     * @param userId
     * @returns
     */
    getUserCustomerAccount(userId) {
        return $axios.get(`/users/${userId}/accounts`);
    },
    getUserCustomerAccountDetails(userId, accountId) {
        return $axios.get(`/users/${userId}/accounts/${accountId}`);
    },
    /**
     * Get the user's project account balance
     * @param userId
     * @returns UserProjectAccountsBalanceResponseDto replace //TODO
     */
    getUserProjectAccountBalance(userId) {
        return $axios.get(`/users/${userId}/project-accounts-balance`);
    },
    /**
     * Get the user's oustanding balance
     * @param userId
     * @returns UserProjectAccountsBalanceResponseDto replace //TODO
     */
    getUserOutstandingBalance(userId) {
        return $axios.get(`/users/${userId}/outstanding-balance`);
    },
    /**
     * Get the transaction logs by user
     * @param userId
     * @returns UserTransactionLogResponseDto[]
     */
    getUserTransactionLogs(userId, options) {
        const params = new URLSearchParams(options);
        return $axios.get(`/users/${userId}/transaction-logs?${params.toString()}`);
    },
    /**
     * Get transaction
     * @param userId
     * @returns UserTransactionLogResponseDto[]
     */
    getUserTransactionStatement(userId, formData) {
        return $axios.post(`/users/${userId}/transaction-logs/statement`, formData);
    },
    /**
     * Get account statuses
     * @param userId
     * @returns UserAccountStatusesResponseDto
     */
    getAccountStatuses(userId) {
        return $axios.get(`/users/${userId}/statuses`);
    },
    /**
     * Get user profile by userId
     * @param userId
     * @param userPublicProfileId
     * @returns GetUserPublicProfileResponseDto
     */
    getUserProfile(userId, userPublicProfileId) {
        return $axios.get(`/users/${userId}/public-profile/${userPublicProfileId}`);
    },
    /**
     * Create user profile record
     * @param userId
     * @param createUserPublicProfileRequestDto
     * @returns CreateUserPublicProfileResponseDto
     */
    createUserProfile(userId, createUserPublicProfileRequestDto) {
        return $axios.post(`/users/${userId}/public-profile`, createUserPublicProfileRequestDto);
    },
    /**
     * Update user profile record
     * @param userId
     * @param userPublicProfileId
     * @param updateUserPublicProfileRequestDto
     * @returns UpdateUserPublicProfileResponseDto
     */
    updateUserProfile(userId, userPublicProfileId, updateUserPublicProfileRequestDto) {
        return $axios.put(`/users/${userId}/public-profile/${userPublicProfileId}`, updateUserPublicProfileRequestDto);
    },
    /**
     * Get user business details
     * @param userId
     * @param userBusinessDetailsId
     * @returns GetUserBusinessDetailsResponseDto
     */
    getBusinessDetails(userId, userBusinessDetailsId) {
        return $axios.get(`/users/${userId}/business-details/${userBusinessDetailsId}`);
    },
    /**
     * Create user business details
     * @param userId
     * @param userBusinessDetailsId
     * @param payload
     * @returns CreateUserBusinessDetailsResponseDto
     */
    createBusinessDetails(userId, createUserBusinessDetailsRequestDto) {
        return $axios.post(`/users/${userId}/business-details/`, createUserBusinessDetailsRequestDto);
    },
    /**
     * Update user business details
     * @param userId
     * @param userBusinessDetailsId
     * @param payload
     * @returns UpdateUserBusinessDetailsResponseDto
     */
    updateBusinessDetails(userId, userBusinessDetailId, updateUserBusinessDetailsRequestDto) {
        return $axios.put(`/users/${userId}/business-details/${userBusinessDetailId}`, updateUserBusinessDetailsRequestDto);
    },
    /**
     * Get user identification
     * @param userId
     * @param userIdentificationId
     * @returns GetUserIdentificationResponseDto
     */
    getUserIdentification(userId, userIdentificationId) {
        return $axios.get(`/users/${userId}/identifications/${userIdentificationId}`);
    },
    /**
     * Initiate user identification process
     * @param userId
     * @returns InitiateIdentificationVerificationResponseDto
     */
    initiateUserIdentification(userId) {
        return $axios.get(`/users/${userId}/identifications/initiate-id-verification`);
    },
    /**
     * Get user identification access
     * @param userId
     * @returns InitiateIdentificationVerificationResponseDto
     */
    getUserIdentificationAccess(userId) {
        return $axios.get(`/users/${userId}/identifications/access`);
    },
    /**
     * Get user certificates
     * @param userId
     * @param userIdentificationId
     * @returns UserCertificateResponseDto
     */
    getUserCertificates(userId) {
        return $axios.get(`users/${userId}/user-certificates`);
    },
    /**
     * Get user certificate by userInsuranceId
     * @param userId
     * @param userCertificateId
     * @returns UserCertificate
     */
    getCertificatesById(userId, userCertificateId) {
        return $axios.get(`users/${userId}/user-certificates/${userCertificateId}`);
    },
    /**
     * Remove user certificate details
     * @param userId
     * @param userCertificateId
     * @returns number
     */
    deleteCertificate(userId, userCertificateId) {
        return $axios.delete(`users/${userId}/user-certificates/${userCertificateId}`);
    },
    /**
     * Update user certificate details
     * @param userId
     * @param userCertificateId
     * @param UpdateUserCertificateRequestDto
     * @returns UserCertificateResponseDto
     */
    updateCertificate(userId, userCertificateId, updateUserCertificateRequestDto) {
        return $axios.put(`users/${userId}/user-certificates/${userCertificateId}`, updateUserCertificateRequestDto);
    },
    /**
     * Create user certificate details
     * @param userId
     * @param userBusinessDetailsId
     * @param CreateUserCertificateRequestDto
     * @returns UserCertificateResponseDto
     */
    createCertificate(createUserCertificateRequestDto) {
        return $axios.post(`users/${createUserCertificateRequestDto.userId}/user-certificates`, createUserCertificateRequestDto);
    },
    /**
     * Get user insurances
     * @param userId
     * @returns UserInsuranceResponseDto
     */
    getUserInsurances(userId) {
        return $axios.get(`users/${userId}/user-insurances`);
    },
    /**
     * Get user insurance by userInsuranceId
     * @param userId
     * @param userInsuranceId
     * @returns UserInsuranceResponseDto
     */
    getUserInsuranceById(userId, userInsuranceId) {
        return $axios.get(`users/${userId}/user-insurances/${userInsuranceId}`);
    },
    /**
     * Delete user insurance by userInsuranceId
     * @param userId
     * @param userInsuranceId
     * @returns number
     */
    deleteUserInsurance(userId, userInsuranceId) {
        return $axios.delete(`users/${userId}/user-insurances/${userInsuranceId}`);
    },
    /**
     * Create user insurance details
     * @param userId
     * @param insuranceId
     * @param createUserInsuranceRequestDto
     * @returns UserInsuranceResponseDto
     */
    createUserInsurance(createUserInsuranceRequestDto) {
        return $axios.post(`users/${createUserInsuranceRequestDto.userId}/user-insurances`, createUserInsuranceRequestDto);
    },
    /**
     * Update user insurance details
     * @param userId
     * @param insuranceId
     * @param updateUserPublicProfileRequestDto
     * @returns UserInsuranceResponseDto
     */
    updateUserInsurance(userId, userInsuranceId, updateUserPublicProfileRequestDto) {
        return $axios.put(`users/${userId}/user-insurances/${userInsuranceId}`, updateUserPublicProfileRequestDto);
    },
    createUserRating(toUserId, rating) {
        return $axios.post(`users/${toUserId}/ratings`, rating);
    },
    getUserRatingsPageList(toUserId, params) {
        return $axios.get(`users/${toUserId}/ratings`, { params });
    },
    getUserRatingSummary(toUserId) {
        return $axios.get(`users/${toUserId}/ratings/summary`);
    },
    /**
     * Get user skill categories
     * @param userId
     * @returns GetUserIdentificationResponseDto
     */
    getUserSkills(userId) {
        return $axios.get(`/users/${userId}/skills`);
    },
    /**
     * Get user skill categories verification status
     * @param userId
     * @returns UserSkillVerification
     */
    getUserSkillCategoriesVerificationStatus(userId) {
        return $axios.get(`/users/${userId}/skills/status`);
    },
    /**
     * Save user skill categories
     * @param userId
     * @param updateUserSkillsRequestDto
     * @returns UserSkill[]
     */
    saveUserSkills(userId, updateUserSkillsRequestDto) {
        return $axios.put(`/users/${userId}/skills`, updateUserSkillsRequestDto);
    },
    /**
     * Get user workstations
     * @param userId
     * @returns []
     */
    getUserWorkstations(userId) {
        return $axios.get(`/users/${userId}/workstations`);
    },
    getUserWorkstation(userId, userWorkstationId) {
        return $axios.get(`/users/${userId}/workstations/${userWorkstationId}`);
    },
    createUserWorkstation(userId, userWorkstation) {
        return $axios.post(`users/${userId}/workstations`, userWorkstation);
    },
    /**
     * active workstation
     * @param userId
     * @returns []
     */
    activateWorkstation(userId, workstationId) {
        return $axios.put(`/users/${userId}/workstations/${workstationId}/activate`);
    },
    updateUserWorkstationStatus(userId, workstationId, formData) {
        return $axios.put(`/users/${userId}/workstations/${workstationId}/status`, formData);
    },
};
