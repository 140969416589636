import PaymentsService from '@/core/services/payments.service';
export default {
    /**
     * Fetch all modulr payment service providers
     * @returns any[]
     */
    async getPaymentProviders({ commit }) {
        const request = await PaymentsService.getPaymentProviders();
        commit('setPaymentProviders', request);
        return request;
    },
    executeWithdrawFunds(context, payload) {
        return PaymentsService.executeWithdrawFunds(payload);
    },
};
