import CourseTemplateFilter from './course-templates.filter';
import CurrencyFilter from './currency.filter';
import DateFilter from './date.filter';
import ExpandCollapseText from './expand-collapse-text.filter';
import formatToImageSourceUrl from './image-source.filter';
import formatToNameInitials from './name-initials.filter';
import formatToTitleCase from './title.filter';
export default {
    ...DateFilter,
    ...CourseTemplateFilter,
    ...CurrencyFilter,
    ...formatToImageSourceUrl,
    ...formatToTitleCase,
    ...formatToNameInitials,
    ...ExpandCollapseText,
};
