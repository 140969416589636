export default {
    /**
     * Set the property-details state
     * @param state
     * @param payload {Property} entity
     */
    setProperty(state, value) {
        state.property = value;
    },
    setPropertyMembers(state, value) {
        state.propertyMembers = value;
    },
    setServicesOnMenu(state, value) {
        state.servicesOnMenu = value;
    },
    setUnsavedPropertyMembers(state, values) {
        state.unsavedPropertyMembers = values;
    },
    setAddedPropertyMembers(state, value) {
        state.addedPropertyMembers = value;
    },
    setIsMapView(state, value) {
        state.isMapView = value;
    },
    setOpenViewPropertyMapDialog(state, value) {
        state.openViewPropertyMapDialog = value;
    },
};
