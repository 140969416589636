/**
 * Forgot password route page
 */
export const CHANGE_PASSWORD_ROUTE = 'change-password';
export default {
    path: '/change-password',
    name: CHANGE_PASSWORD_ROUTE,
    component: () => import('@/modules/login/pages/LoginPage.vue'),
    meta: {
        title: 'Change My Password',
    },
    props: (route) => ({ token: route.query.token }),
};
