import { RolesEnum } from '@/core/packages/shared-library';
import AccountDetailRoute from '@/modules/account-details/routes';
export const ACCOUNT_ROUTE = 'account';
export default {
    path: '/account/',
    name: ACCOUNT_ROUTE,
    redirect: '/account/details',
    component: () => import('@/modules/account/pages/AccountPage.vue'),
    children: [
        ...AccountDetailRoute
    ],
    meta: {
        title: 'Account Details',
        authorize: [RolesEnum.TRADESPERSON],
    }
};
