const initialStatePaymentForm = ({
    projectStageId: 0,
    method: null,
    aspspId: null,
    destination: {
        id: null,
        type: 'ACCOUNT',
    },
    paymentAmount: {
        currency: 'GBP',
        value: 0,
    },
    paymentContext: {
        deliveryAddress: {
            addressLine1: 'approved',
            addressLine2: '',
            country: 'GB',
            postCode: 'approved',
            postTown: 'approved',
        },
        // merchantCategoryCode : '', //Merchant category code conform to ISO 18245, related to the type of services or goods provided for the transaction. Must be specified if paymentContextCode is either ECOMMERCEGOODS or ECOMMERCESERVICES
        // merchantCustomerIdentification : '',//Merchant customer identification, must be specified if paymentContextCode is either ECOMMERCEGOODS or ECOMMERCESERVICES
        paymentContextCode: 'PARTYTOPARTY',
    },
    paymentReference: '',
});
export default {
    paymentProviders: [],
    paymentForm: ({ ...initialStatePaymentForm })
};
