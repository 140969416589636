export default {
    totalNewMessages: 0,
    availableWidgets: [
        {
            name: 'image-attachment',
            type: 'file',
        }
    ],
    openCreateMicroTaskModal: false,
};
