import $axios from '../utils/axios-api-config';
export default {
    getUserSearchConfigByFilterKey(userId, params = {}) {
        return $axios.get(`/user-search-configs/${userId}`, { params });
    },
    createUserSearchConfig(createUserSearchConfigRequestDto) {
        return $axios.post('/user-search-configs/', createUserSearchConfigRequestDto);
    },
    updateUserSearchConfig(userSearchConfigId, updateUserSearchConfigRequestDto) {
        return $axios.put(`/user-search-configs/${userSearchConfigId}`, updateUserSearchConfigRequestDto);
    }
};
