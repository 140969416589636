/**
 * epc data route page
 */
export const EPC_DATA_ROUTE = 'epc-data';
export default {
    path: '/epc-data',
    name: EPC_DATA_ROUTE,
    component: () => import('@/modules/epc-data/pages/EpcDataPage.vue'),
    meta: {
        disableRoute: false,
        validateAuth: true,
    }
};
