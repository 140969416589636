import attachmentCache from './attachment-cache';
import authentication from './authentication';
import billing from './billing';
import calendar from './calendar';
import certificates from './certificates';
import documents from './documents';
import insurances from './insurances';
import jobClient from './job-client';
import jobTemplate from './job-template';
import messages from './messages';
import notifications from './notifications';
import opportunity from './opportunity';
import opportunityBilling from './opportunity-billing';
import payments from './payments';
import projectDashboard from './project-dashboard';
import projectPost from './project-post';
import projectQuotes from './project-quotes';
import projectStageTaskMember from './project-quotes/submodules/project-stage-task-member';
import projectStagesTasks from './project-quotes/submodules/project-stages-tasks';
import projectStageDeposits from './project-stage-deposits';
import projectStages from './project-stages';
import projects from './projects';
import properties from './properties';
import property from './property-details';
import propertyInsurance from './property-insurance';
import searchConfig from './search-config';
import settings from './settings';
import tradePassport from './trade-passport';
import userCalendar from './user-calendar';
import userContractor from './user-contractor';
import userWorkstation from './user-workstation';
import userWorkstationMember from './user-workstation-member';
import users from './users';
import wallet from './wallet';
import workstation from './workstation';
export default {
    authentication,
    users,
    projects,
    projectQuotes,
    projectStages,
    projectStagesTasks,
    projectStageTaskMember,
    certificates,
    insurances,
    wallet,
    searchConfig,
    notifications,
    opportunityBilling,
    messages,
    payments,
    projectStageDeposits,
    attachmentCache,
    properties,
    property,
    projectPost,
    workstation,
    userWorkstation,
    billing,
    opportunity,
    documents,
    calendar,
    settings,
    userContractor,
    userWorkstationMember,
    userCalendar,
    propertyInsurance,
    jobTemplate,
    jobClient,
    tradePassport,
    projectDashboard
};
