// BE API
export const API_URL = process.env.VUE_APP_API_URL;
// stripe keys
export const VUE_APP_STRIPE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY || '';
// OSDATA API
export const VUE_APP_LEAFLET_LAYER_URL = process.env.VUE_APP_LEAFLET_LAYER_URL || '';
export const VUE_APP_OS_POSTCODE_URL = process.env.VUE_APP_OS_POSTCODE_URL || '';
export const VUE_APP_OS_FIND_URL = process.env.VUE_APP_OS_FIND_URL || '';
export const VUE_APP_OS_API = process.env.VUE_APP_OS_API || '';
// EPC API
export const VUE_APP_EPC_API = process.env.VUE_APP_EPC_API || '';
export const VUE_APP_EPC_URL = process.env.VUE_APP_EPC_URL || '';
export const VUE_APP_EPC_RECOMMENDATIONS = process.env.VUE_APP_EPC_RECOMMENDATIONS || '';
// Property Data Api
export const VUE_APP_PROPERTY_DATA_API = process.env.VUE_APP_PROPERTY_DATA_API || '';
export const VUE_APP_PROPERTY_DATA_URL = process.env.VUE_APP_PROPERTY_DATA_URL || '';
// Google maps Api
export const VUE_APP_GOOGLE_MAP_API = process.env.VUE_APP_GOOGLE_MAP_API || '';
