import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USER_WORKSTATION } from './modules/user-workstation';
import { USERS_STORE } from './modules/users';
export default {
    /**
     * Toggle the navigation sidebar menu
     * @param state
     */
    setNavCollapseState({ commit }, toggleNavState) {
        commit('setNavCollapseState', toggleNavState);
    },
    checkIfUserIsVerified({ getters, commit }) {
        const activeUserWorkstation = getters[`${USER_WORKSTATION}/activeUserWorkstation`] || null;
        if (activeUserWorkstation.verificationStatus !== UserVerificationStatusEnum.VERIFIED && getters.isTradesperson) {
            commit('setUnverifiedAccountModal', true);
            return true;
        }
        return false;
    },
    checkIfUserVerifiedReferAndEarn({ getters, commit }) {
        const activeUserWorkstation = getters[`${USER_WORKSTATION}/activeUserWorkstation`] || null;
        if (activeUserWorkstation.verificationStatus === UserVerificationStatusEnum.VERIFIED) {
            commit('setUserReferAndEarnModal', true);
            return true;
        }
        return false;
    },
    checkIfUserNotVerified({ getters, commit }) {
        const activeUserWorkstation = getters[`${USER_WORKSTATION}/activeUserWorkstation`] || null;
        if (activeUserWorkstation.userVerificationStatus !== UserVerificationStatusEnum.VERIFIED && getters.isTradesperson) {
            commit('setUserBuildProfileModal', true);
            return true;
        }
        return false;
    },
    checkIfUserHasMatchedProjectSkills({ commit, getters }, selectedProject) {
        const user = getters[`${USERS_STORE}/user`] || null;
        if (user && user.userSkills && selectedProject && getters.isTradesperson) {
            const foundSkill = user.userSkills.some((skill) => selectedProject.categories.map((c) => c.subCategories[0].id).includes(skill.subCategory.id));
            if (!foundSkill) {
                commit('setAddNewSkillRedirectModal', true);
                return true;
            }
        }
        return false;
    },
    setUserReferAndEarnModal({ commit }, value) {
        commit('setUserReferAndEarnModal', value);
    },
    setUserBuildProfileModal({ commit }, value) {
        commit('setUserBuildProfileModal', value);
    },
    setUnverifiedAccountModal({ commit }, value) {
        commit('setUnverifiedAccountModal', value);
    },
    setAddNewSkillRedirectModal({ commit }, value) {
        commit('setAddNewSkillRedirectModal', value);
    },
    setEwalletConfirmInterestModal({ commit }, value) {
        commit('setEwalletConfirmInterestModal', value);
    },
    setEwalletThankYouModal({ commit }, value) {
        commit('setEwalletThankYouModal', value);
    },
    setWorkstationChooseModal({ commit }, value) {
        commit('setWorkstationChooseModal', value);
    },
    setWorkstationCompanyModal({ commit }, value) {
        commit('setWorkstationCompanyModal', value);
    },
    setWorkstationPlanModal({ commit }, value) {
        commit('setWorkstationPlanModal', value);
    },
    setWorkstationInviteModal({ commit }, value) {
        commit('setWorkstationInviteModal', value);
    },
    setWorkstationProfileModal({ commit }, value) {
        commit('setWorkstationProfileModal', value);
    },
    setWorkstationPaymentConfirmationModal({ commit }, value) {
        commit('setWorkstationPaymentConfirmationModal', value);
    },
    setProjectDescriptionModal({ commit }, value) {
        commit('setProjectDescriptionModal', value);
    },
    setProjectManageMembersModal({ commit }, value) {
        commit('setProjectManageMembersModal', value);
    },
    setProjectAttachmentsModal({ commit }, value) {
        commit('setProjectAttachmentsModal', value);
    },
    setProjectBudgetModal({ commit }, value) {
        commit('setProjectBudgetModal', value);
    },
    setAccountSetupModal({ commit }, value) {
        commit('setAccountSetupModal', value);
    },
    setPassportPreviewModal({ commit }, value) {
        commit('setPassportPreviewModal', value);
    },
    setPaymentSuccessfulModal({ commit }, value) {
        commit('setPaymentSuccessfulModal', value);
    },
    setOpenSoleTraderBillinglModal({ commit }, value) {
        commit('setOpenSoleTraderBillinglModal', value);
    },
    setIsLoading({ commit }, value) {
        commit('setIsLoading', value);
    },
    setWorkstationSetupModal({ commit }, value) {
        commit('setWorkstationSetupModal', value);
    },
    setSoleTraderSetupModal({ commit }, value) {
        commit('setSoleTraderSetupModal', value);
    },
    setLimitedCompanySetupModal({ commit }, value) {
        commit('setLimitedCompanySetupModal', value);
    },
};
