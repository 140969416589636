import TradePassportChildrenRoute from '@/modules/trades-passport-children/routes';
export const TRADE_PASSPORT_ROUTE = 'trade-passport';
export default {
    path: '/trade-passport',
    name: TRADE_PASSPORT_ROUTE,
    redirect: { name: 'trade-passport-overview' },
    components: {
        default: () => import('@/modules/trade-passport/pages/TradesPasportPage.vue'),
        profileStatus: () => import('@/modules/trade-passport/components/TradePassportHeaderStatus.vue'),
        customHeader: () => import('@/modules/trade-passport/components/TradePassportHeader.vue')
    },
    children: [
        ...TradePassportChildrenRoute
    ],
    meta: {
        title: 'Your Trades Passport',
        useSecondaryHeader: true,
        showProfile: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
