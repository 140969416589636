import UserCalendarService from '@/core/services/user-calendar.service';
export default {
    setDateWithTime({ commit }, dateWithTime) {
        commit('setDateWithTime', dateWithTime);
    },
    setOriginalSchedule({ commit }, originalSchedule) {
        commit('setOriginalSchedule', originalSchedule);
    },
    getSchedules(context, params) {
        const { userId, options } = params;
        return UserCalendarService.getUserSchedules(userId, options);
    },
    createOrUpdateScheduleRequest(context, params) {
        const { userId, formData } = params;
        return UserCalendarService.createOrUpdateSchedule(userId, formData)
            .then((response) => {
            context.commit('resetCalendarState');
            return response;
        });
    },
    deleteSchedule(context, params) {
        const { userId, userCalendarId } = params;
        return UserCalendarService.deleteSchedule(userId, userCalendarId);
    },
    resetCalendarState({ commit }) {
        commit('resetCalendarState');
    },
    setViewEventDetailsModal({ commit }, viewEventDetailsModal) {
        commit('setViewEventDetailsModal', viewEventDetailsModal);
    },
};
// reset schedule form
