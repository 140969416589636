import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Insurance route page
 */
export const INSURANCE_ROUTE = 'insurance';
export default {
    path: '/insurance',
    name: INSURANCE_ROUTE,
    components: {
        default: () => import('@/modules/insurance/pages/InsurancePage.vue'),
    },
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Insurance',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: true
    }
};
