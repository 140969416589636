/**
 * Cohort invoce route page
 */
export const COHORT_INVOICE_ROUTE = 'cohort-invoice';
export default {
    path: '/cohort-invoice',
    name: COHORT_INVOICE_ROUTE,
    component: () => import('@/modules/cohort-invoice/pages/CohortsInvoicePage.vue'),
    meta: {
        title: '',
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
