import CoursesChildrenRoute from '@/modules/courses-children/routes';
export const COURSES_ROUTE = 'courses';
export default {
    path: '/courses',
    name: COURSES_ROUTE,
    redirect: { name: 'courses-opportunities' },
    components: {
        default: () => import('@/modules/courses/pages/CoursesPage.vue'),
        customHeader: () => import('@/modules/courses/components/CoursesHeaderMenu.vue')
    },
    children: [
        ...CoursesChildrenRoute
    ],
    meta: {
        title: 'Your Opportunities',
        useSecondaryHeader: true,
        showProfile: false
    },
};
