import { RolesEnum } from '@/core/packages/shared-library';
import ProjectDashboardChildrenRoute from '@/modules/project-dashboard-children/routes';
/**
 * Project dashboard route
 */
export const PROJECT_DASHBOARD_ROUTE = 'project-dashboard';
export default {
    path: `/${PROJECT_DASHBOARD_ROUTE}`,
    name: PROJECT_DASHBOARD_ROUTE,
    redirect: { name: 'new-project' },
    components: {
        default: () => import('@/modules/project-dashboard/pages/ProjectDashboardPage.vue'),
        customHeader: () => import('@/modules/project-dashboard/components/ProjectDashboardMenu.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Your Projects',
        authorize: [RolesEnum.PROJECT_OWNER],
        useSecondaryHeader: true,
    },
    children: [
        ...ProjectDashboardChildrenRoute
    ],
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
