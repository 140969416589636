/**
 * Opportunity route page
 *
 */
import OpportunityChildrenRoute from '@/modules/opportunity-children/routes';
export const OPPORTUNITY_ROUTE = 'opportunity';
export default {
    path: '/opportunity',
    name: OPPORTUNITY_ROUTE,
    redirect: '/opportunity/courses',
    components: {
        default: () => import('@/modules/opportunity/pages/OpportunityPage.vue'),
    },
    children: [
        ...OpportunityChildrenRoute
    ],
    meta: {
        title: 'Opportunity',
    },
};
