import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Post project route
 */
export const PROJECT_POST_OVERVIEW_ROUTE = 'post-project-overview';
export default {
    path: '/properties/:propertyId/post-project-overview',
    name: PROJECT_POST_OVERVIEW_ROUTE,
    components: {
        default: () => import('@/modules/project-post-overview/pages/ProjectPostOverview.vue'),
        headerMenu: () => import('@/core/components/project-post/ProjectPostHeaderMenuComponent.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        // title: 'Start a new project',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: false
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
