import { ProjectJobStatusEnum } from '@/core/packages/shared-library';
export default {
    enableProjectEscrowSidebar: (state) => state.enableProjectEscrowSidebar,
    hasGetProjectFinished: (state) => state.hasGetProjectFinished,
    isProjectReadyToWork: (state) => state.isProjectReadyToWork,
    currentSelectedProject: (state) => state.currentSelectedProject,
    projectAccountBalance: (state) => state.projectAccountBalance,
    categories: (state) => {
        return state.categories;
    },
    projects: (state) => {
        return state.projects;
    },
    projectStatuses: (state) => {
        return state.projectStatuses;
    },
    materialAvailabilityLabel: (state) => {
        return state.materialAvailabilityLabel;
    },
    topRightSidebarMenu: (state) => state.topRightSidebarMenu,
    getProjectStatusLabel: () => {
        return (project) => {
            const projectJobStatus = project?.projectJobStatus.id.toString();
            const status = {
                name: project?.projectJobStatus.display || 'Not Started',
                type: 'warning',
            };
            switch (projectJobStatus) {
                case ProjectJobStatusEnum.DRAFT_PROJECT:
                    status.type = 'warning';
                    break;
                case ProjectJobStatusEnum.AWAITING_QUOTES:
                    status.type = 'primary';
                    break;
                case ProjectJobStatusEnum.QUOTES_RECEIVED:
                    status.type = 'warning';
                    break;
                case ProjectJobStatusEnum.QUOTE_ACCEPTED:
                    status.type = 'success';
                    break;
                case ProjectJobStatusEnum.QUOTE_CONFIRMED:
                    status.name = 'Quote Accepted';
                    status.type = 'success';
                    break;
                case ProjectJobStatusEnum.IN_PROGRESS:
                    status.type = 'primary';
                    break;
                case ProjectJobStatusEnum.COMPLETED:
                    status.type = 'success';
                    break;
                case ProjectJobStatusEnum.CANCELLED:
                    status.type = 'danger';
                    break;
                default:
            }
            return status;
        };
    },
    selectedProjectJobStatus: (state) => state.selectedProjectJobStatus,
    currentPageViewMapping: (state) => {
        return state.currentPageViewMapping;
    },
    openSummaryEstimateDrawer: (state) => state.openSummaryEstimateDrawer,
    getOpenEscrowDrawer: (state) => state.openEscrowDrawer,
    openReviewModal: (state) => state.openReviewModal,
    subCategories: (state) => state.subCategories,
    mainCategories: (state) => state.mainCategories,
    toBePassedCategories: (state) => state.toBePassedCategories,
    findJobsSearchValue: (state) => state.findJobsSearchValue,
    searchCategories: (state) => {
        return state.searchCategories;
    },
    categoryList: (state) => state.categoryList,
};
