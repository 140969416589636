export default {
    setInviteMembersModal(state, value) {
        state.inviteMembersModal = value;
    },
    setRecentlyAdded(state, userWorkstationMember) {
        state.recentlyAdded = userWorkstationMember;
    },
    setUserWorkstationMembers(state, userWorkstationMembers) {
        state.userWorkstationMembers = userWorkstationMembers;
    },
};
