import { RolesEnum } from '@/core/packages/shared-library';
import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
export const PROFILE_ROUTE = 'profile';
export default {
    path: '/profile/:userId',
    name: PROFILE_ROUTE,
    redirect: '/profile/about',
    component: () => import('@/modules/profile/pages/ProfilePage.vue'),
    props: {
        default: (route) => ({ userId: route.params.userId }),
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-restricted-globals
        if (!to.params.userId) {
            next({ name: DASHBOARD_ROUTE });
        }
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    },
    children: [
        {
            path: 'about',
            name: 'profile/about',
            component: () => import('@/modules/profile/components/ProfileAbout.vue'),
            authorize: [RolesEnum.TRADESPERSON],
        },
        {
            path: 'detail',
            name: 'profile/detail',
            component: () => import('@/modules/profile/components/ProfileDetail.vue'),
            authorize: [RolesEnum.TRADESPERSON],
        },
        {
            path: 'reviews',
            name: 'profile/reviews',
            component: () => import('@/modules/profile/components/ProfileReview.vue'),
            authorize: [RolesEnum.TRADESPERSON],
        },
        {
            path: 'gallery',
            name: 'profile/gallery',
            component: () => import('@/modules/profile/components/ProfileGallery.vue'),
            authorize: [RolesEnum.TRADESPERSON],
        }
    ],
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER],
        showBackButton: true
    },
};
