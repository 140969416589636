export default {
    removeProjectAttachmentFile(state, file) {
        const index = state.project.attachments.images.findIndex((item) => item.attachment === file);
        state.project.attachments.images.splice(index, 1);
    },
    updateProjectAttachments(state, file) {
        state.project.attachments.images.push(file);
    },
    resetProjectState(state) {
        state.project = state.defaultProjectState;
    },
    setEpcRecommendationProjectDetails(state, value) {
        state.epcRecommendationProjectDetails = value;
    },
    setOpenProjectDetailsFormModal(state, value) {
        state.openProjectDetailsFormModal = value;
    },
    setOpenProjectStatusModal(state, value) {
        state.openProjectStatusModal = value;
    },
};
