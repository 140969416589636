export default {
    getCurrentJourney: (state) => state.currentJourney,
    getJourneyCompleted: (state) => state.journeyCompleted,
    getStatusProjectType: (state) => state.status,
    getProjectDescription: (state) => state.description,
    getProjectCategories: (state) => state.categories,
    unsavedProjectMembers: (state) => state.unsavedProjectMembers,
    addedProjectMembers: (state) => state.addedProjectMembers,
    projectBudget: (state) => state.projectBudget,
    getLeavePageModal: (state) => state.leavePageModal,
    getCancelPageModal: (state) => state.cancelPageModal,
    getPostProjectConfirmationModal: (state) => state.postProjectConfirmationModal,
};
