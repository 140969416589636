export default {
    path: '/users/:userId/settings',
    name: 'account/settings',
    component: () => import('@/modules/account-settings/pages/SettingsPage.vue'),
    props: {
        default: (route) => ({ userId: route.params.userId }),
    },
    beforeRouteEnter: (to, from, next) => {
        next();
    },
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Settings'
    }
};
