import { AUTH_USER_NAV_COLLAPSE } from '../core/constants';
export default {
    /**
     * Set the navigation menu only when required
     * @param state
     * @param isOpen
     */
    setNavCollapseState(state, currentState) {
        state.isNavCollapse = currentState;
        if (currentState !== null) {
            window.localStorage.setItem(AUTH_USER_NAV_COLLAPSE, JSON.stringify({
                isNavCollapse: currentState,
            }));
        }
    },
    setUserReferAndEarnModal(state, value) {
        state.openUserReferAndEarnModal = value;
    },
    setUnverifiedAccountModal(state, value) {
        state.openUnverifiedAccountModal = value;
    },
    setUserBuildProfileModal(state, value) {
        state.openUserBuildProfileModal = value;
    },
    setAddNewSkillRedirectModal(state, value) {
        state.openAddNewSkillRedirectModal = value;
    },
    setEwalletConfirmInterestModal(state, value) {
        state.openEwalletConfirmInterestModal = value;
    },
    setEwalletThankYouModal(state, value) {
        state.openEwalletThankYouModal = value;
    },
    setWorkstationChooseModal(state, value) {
        state.openWorkstationChooseModal = value;
    },
    setWorkstationCompanyModal(state, value) {
        state.openWorkstationCompanyModal = value;
    },
    setWorkstationPlanModal(state, value) {
        state.openWorkstationPlanModal = value;
    },
    setWorkstationInviteModal(state, value) {
        state.openWorkstationInviteModal = value;
    },
    setWorkstationProfileModal(state, value) {
        state.openWorkstationProfileModal = value;
    },
    setWorkstationPaymentConfirmationModal(state, value) {
        state.openWorkstationPaymentConfirmationModal = value;
    },
    setProjectDescriptionModal(state, value) {
        state.openProjectDescriptionModal = value;
    },
    setProjectManageMembersModal(state, value) {
        state.openProjectManageMembersModal = value;
    },
    setProjectAttachmentsModal(state, value) {
        state.openProjectAttachmentsModal = value;
    },
    setProjectBudgetModal(state, value) {
        state.openProjectBudgetModal = value;
    },
    setAccountSetupModal(state, value) {
        state.openAccountSetupModal = value;
    },
    setPassportPreviewModal(state, value) {
        state.openPassportPreviewModal = value;
    },
    setPaymentSuccessfulModal(state, value) {
        state.openPaymentSuccessfulModal = value;
    },
    setOpenSoleTraderBillinglModal(state, value) {
        state.openSoleTraderBillinglModal = value;
    },
    setIsLoading(state, value) {
        state.isLoading = value;
    },
    setWorkstationSetupModal(state, value) {
        state.workstationSetupModal = value;
    },
    setLimitedCompanySetupModal(state, value) {
        state.limitedCompanySetupModal = value;
    },
    setSoleTraderSetupModal(state, value) {
        state.soleTraderSetupModal = value;
    }
};
