import * as CryptoJS from 'crypto-js';
import moment from 'moment';
import { IMAGE_EXPIRATION_DURATION, IMAGE_EXPIRATION_KEY /* CRYPTO_SECRET_KEY, */ } from '@/core/constants';
export default {
    getImageExpirationToken() {
        return this.getImageExpirationTokenStorage();
    },
    getImageExpirationTokenStorage() {
        return window.localStorage.getItem(IMAGE_EXPIRATION_KEY);
    },
    setImageExpirationToken(timestamp) {
        const utcTimestamp = timestamp;
        window.localStorage.setItem(IMAGE_EXPIRATION_KEY, utcTimestamp);
    },
    generateUTCTimestamp() {
        return moment().add(IMAGE_EXPIRATION_DURATION, 'minutes').utc().valueOf();
    },
    getImageToken() {
        const expiration = this.getImageExpirationTokenStorage();
        const encryptedToken = CryptoJS.AES.encrypt(expiration ?? '', process.env.VUE_APP_CRYPTO_SECRET_KEY).toString();
        return encodeURIComponent(encryptedToken);
    },
    validateImageExpirationToken() {
        // const expirationTimestamp = this.getImageExpirationTokenStorage();
        // if (!expirationTimestamp) {
        const newExpirationTimestamp = this.generateUTCTimestamp();
        this.setImageExpirationToken(newExpirationTimestamp);
        // }
        // this won't be used since we're setting the expiration time greater than the expiration time of the login
        // else if (expirationTimestamp) {
        //   const currentTimestamp = moment.utc().valueOf();
        //   if (currentTimestamp > +expirationTimestamp) {
        //     const generateUtcTimestamp = this.generateUTCTimestamp();
        //     this.setImageExpirationToken(generateUtcTimestamp);
        //   }
        // }
    }
};
