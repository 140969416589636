export default {
    setWallet(state, wallet = {}) {
        state.wallet = wallet;
    },
    setActiveQuotes(state, quotes = []) {
        state.activeQuotes = quotes || [];
    },
    setActiveJobs(state, jobs = []) {
        state.activeJobs = jobs || [];
    },
    setGraphSeriesDataLoaded(state, value) {
        state.graphSeriesDataLoaded = value;
    },
    setProjectBoxDetails(state, value = {}) {
        if (state.boxDetails[1].items && state.boxDetails[1].items.length) {
            state.boxDetails[1].items[0].value = value.toString();
            if (value === 0) {
                state.boxDetails[1].items[0].subInfo = '';
            }
        }
    },
    setBoxDetails(state, payload) {
        const { value } = payload;
        state.boxDetails = [
            {
                id: value.id,
                items: [
                    {
                        title: 'Available Balance',
                        value: parseFloat(value.availableBalance),
                        button: 'Withdraw',
                        fn: 'openWithdrawForm',
                    },
                ],
            },
            {
                id: 3,
                items: [
                    {
                        title: 'Total Projects Completed',
                        /* subInfo: '0% more than last month', */
                        value: 0,
                        filters: {
                            month: new Date().getMonth(),
                            year: new Date().getFullYear(),
                            // eslint-disable-next-line global-require
                            icon: require('@/assets/icons/pie-with-circle.svg')
                        }
                    },
                ]
            },
            {
                id: 4,
                items: [
                    {
                        title: 'Total Withdrawals',
                        value: 0,
                        filters: {
                            month: new Date().getMonth(),
                            year: new Date().getFullYear(),
                            // eslint-disable-next-line global-require
                            icon: require('@/assets/icons/pound-circle.svg')
                        }
                    }
                ]
            },
        ];
    },
    setTotalWithdrawal(state, logs) {
        const withdrawalAmounts = logs.map((log) => {
            return log.amount || 0;
        });
        const totalWithdrawal = withdrawalAmounts.reduce((acc, curr) => {
            return acc + curr;
        }, 0);
        state.boxDetails[2].items[0].value = totalWithdrawal;
    },
    setMoneyInRightbar(state, value) {
        state.openMoneyInRightbar = value;
    },
    setMoneyInPayManuallyRightbar(state, value) {
        state.openMoneyInPayManuallyRightbar = value;
    },
    setMoneyInSuccessRightbar(state, value) {
        state.openMoneyInSuccessRightbar = value;
    },
    setMoneyOutRightbar(state, value) {
        state.openMoneyOutRightbar = value;
    },
    setMoneyOutVerifyRightbar(state, value) {
        state.openMoneyOutVerifyRightbar = value;
    },
    setMoneyOutSuccessRightbar(state, value) {
        state.openMoneyOutSuccessRightbar = value;
    },
    setOpenRegisterInterestModalConfirmation(state, value) {
        state.openRegisterInterestModalConfirmation = value;
    },
    setOpenThankyouRegisterInterestModal(state, value) {
        state.openThankyouRegisterInterestModal = value;
    },
    setOpenCurrentMembersPlanModal(state, value) {
        state.openCurrentMembersPlanModal = value;
    },
    setOpenConfirmDeleteMembersPlanModal(state, value) {
        state.openConfirmDeleteMembersPlanModal = value;
    },
    setOpenTransactionDetailsDrawer(state, value) {
        state.openTransactionDetailsDrawer = value;
    },
    setOpenMoneyInDrawer(state, value) {
        state.openMoneyInDrawer = value;
    },
    setOpenMoneyOutDrawer(state, value) {
        state.openMoneyOutDrawer = value;
    },
    setWithdrawalForm(state, withdrawalForm) {
        state.withdrawalForm = withdrawalForm;
    },
};
