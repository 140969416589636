import { RolesEnum } from '@/core/packages/shared-library';
export const ACCOUNT_SETUP_ROUTE = 'account-setup';
export default {
    path: '/setup/',
    name: ACCOUNT_SETUP_ROUTE,
    redirect: '/setup/identification',
    component: () => import('@/modules/account-setup/AccountSetup.vue'),
    children: [
        {
            path: 'identification',
            name: 'setup/identification',
            component: () => import('@/modules/account-setup/forms/IdentificationForm.vue'),
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        },
        {
            path: 'contact',
            name: 'setup/contact',
            component: () => import('@/modules/account-setup/forms/PhoneNumberForm.vue'),
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        },
        {
            path: 'photo',
            name: 'setup/photo',
            component: () => import('@/modules/account-setup/forms/ProfilePhotoForm.vue'),
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        },
        {
            path: 'certification',
            name: 'setup/certification',
            component: () => import('@/modules/account-setup/forms/CertificationForm.vue'),
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        },
        {
            path: 'terms',
            name: 'setup/terms',
            component: () => import('@/modules/account-setup/forms/TermConditionsForm.vue'),
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        },
        // {
        //   path: 'number',
        //   name: 'setup/number',
        //   component: () => import('@/modules/account-setup/forms/NumberOTPForm.vue'),
        //   authorize: [RolesEnum.PROJECT_OWNER],
        // },
        // {
        //   path: 'address',
        //   name: 'setup/address',
        //   component: () => import('@/modules/account-setup/forms/AddressForm.vue'),
        //   authorize: [RolesEnum.PROJECT_OWNER],
        // },
        // tradesperson
        {
            path: 'skillset',
            name: 'setup/skillset',
            component: () => import('@/modules/account-setup/forms/category/CategoryForm.vue'),
            authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
        },
    ],
    meta: {
        title: 'Account Setup',
        isAccountSetupPage: true,
        authorize: [RolesEnum.PROJECT_OWNER, RolesEnum.TRADESPERSON],
    }
};
