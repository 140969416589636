import { RolesEnum } from '@/core/packages/shared-library';
export const PROJECT_MESSAGES_ROUTE = 'project-messages';
export default {
    path: '/projects/:projectRefId/messages',
    name: PROJECT_MESSAGES_ROUTE,
    props: {
        default: (route) => ({ projectRefId: route.params.projectRefId })
    },
    components: {
        default: () => import('@/modules/project-messages/pages/ProjectMessagesPage.vue'),
        headerMenu: () => import('@/core/components/project/ProjectOverviewMenu.vue')
    },
    meta: {
        disableRoute: false,
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Messages',
        authorize: [RolesEnum.TRADESPERSON, RolesEnum.PROJECT_OWNER],
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-restricted-globals
        if (!to.params.projectRefId /* || isNaN(to.params.projectRefId) */) {
            next(from);
        }
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
