export default {
    setOpenCheckEligibilityModal(state, value) {
        state.openCheckEligibilityModal = value;
    },
    setCurrentCourse(state, value) {
        state.currentCourse = value;
    },
    setCourses(state, value) {
        state.courses.push(value);
    },
    setCoursesMenu(state, value) {
        state.coursesMenu = value;
    },
    setCoursesCurrentMenuId(state, value) {
        state.coursesCurrentMenuId = value;
    },
};
