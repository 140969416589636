import { RolesEnum, UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { USER_WORKSTATION } from './modules/user-workstation';
// import { USERS_STORE } from './modules/users';
export default {
    /**
     * Retrieve sidebar menus and check the authorization
     *
     * @param state
     * @param _getters
     * @param _rootState
     * @param rootGetters
     * @returns
     */
    sidebarMenu: (state, _getters, _rootState, rootGetters) => {
        const authenticatedUser = rootGetters['authentication/authenticatedUser'];
        // const authenticatedUserDetails = rootGetters[`${USERS_STORE}/users`];
        const activeUserWorkstation = rootGetters[`${USER_WORKSTATION}/activeUserWorkstation`];
        if (authenticatedUser == null) {
            throw new Error('User is not authenticated');
        }
        const menus = state.sidebarMenu.filter((menu) => {
            const isAuthorize = menu.authorize.includes(authenticatedUser.roleId);
            // check if user is authorized and verified user
            if (menu.name === '/wallet' && activeUserWorkstation.verificationStatus !== UserVerificationStatusEnum.VERIFIED) {
                return false;
            }
            return isAuthorize;
        });
        return menus;
    },
    isNavCollapse: (state) => {
        return state.isNavCollapse;
    },
    isTradesperson: (state, _getters, _rootState, rootGetters) => {
        const authenticatedUser = rootGetters['authentication/authenticatedUser'];
        return authenticatedUser.roleId === RolesEnum.TRADESPERSON;
    },
    isProjectOwner: (state, _getters, _rootState, rootGetters) => {
        const authenticatedUser = rootGetters['authentication/authenticatedUser'];
        return authenticatedUser.roleId === RolesEnum.PROJECT_OWNER;
    },
    generalSortFields: (state) => state.generalSortFields,
    openUserReferAndEarnModal: (state) => state.openUserReferAndEarnModal,
    openUnverifiedAccountModal: (state) => state.openUnverifiedAccountModal,
    openUserBuildProfileModal: (state) => state.openUserBuildProfileModal,
    openAddNewSkillRedirectModal: (state) => state.openAddNewSkillRedirectModal,
    openEwalletConfirmInterestModal: (state) => state.openEwalletConfirmInterestModal,
    openEwalletThankYouModal: (state) => state.openEwalletThankYouModal,
    openWorkstationChooseModal: (state) => state.openWorkstationChooseModal,
    openWorkstationCompanyModal: (state) => state.openWorkstationCompanyModal,
    openWorkstationPlanModal: (state) => state.openWorkstationPlanModal,
    openWorkstationInviteModal: (state) => state.openWorkstationInviteModal,
    openWorkstationProfileModal: (state) => state.openWorkstationProfileModal,
    openWorkstationPaymentConfirmationModal: (state) => state.openWorkstationPaymentConfirmationModal,
    openProjectDescriptionModal: (state) => state.openProjectDescriptionModal,
    openProjectManageMembersModal: (state) => state.openProjectManageMembersModal,
    openProjectAttachmentsModal: (state) => state.openProjectAttachmentsModal,
    openProjectBudgetModal: (state) => state.openProjectBudgetModal,
    openAccountSetupModal: (state) => state.openAccountSetupModal,
    openPassportPreviewModal: (state) => state.openPassportPreviewModal,
    openPaymentSuccessfulModal: (state) => state.openPaymentSuccessfulModal,
    openSoleTraderBillinglModal: (state) => state.openSoleTraderBillinglModal,
    isLoading: (state) => state.isLoading,
    userMockData: (state) => state.userMockData,
    twoUserMockDataWithRole: (state) => state.twoUserMockDataWithRole,
    userMockDataWithRole: (state) => state.userMockDataWithRole,
    workstationSetupModal: (state) => state.workstationSetupModal,
    soleTraderSetupModal: (state) => state.soleTraderSetupModal,
    limitedCompanySetupModal: (state) => state.limitedCompanySetupModal,
};
