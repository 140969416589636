import $axios from '@/core/utils/axios-api-config';
export default {
    getUserSchedules(userId, options) {
        const params = new URLSearchParams(options);
        return $axios.get(`/users/${userId}/calendars?${params.toString()}`);
    },
    async createOrUpdateSchedule(userId, formData) {
        return $axios.post(`/users/${userId}/calendars`, formData);
    },
    deleteSchedule(userId, userCalendarId) {
        return $axios.delete(`/users/${userId}/calendars/${userCalendarId}`);
    },
};
