const ADMIN_VALUE = 1;
const EDIT_VALUE = 2;
const VIEW_VALUE = 3;
const ADMIN_LABEL = 'Admin';
const EDIT_LABEL = 'Edit';
const VIEW_LABEL = 'View';
export default {
    ADMIN_VALUE,
    EDIT_VALUE,
    VIEW_VALUE,
    ADMIN_LABEL,
    EDIT_LABEL,
    VIEW_LABEL
};
