export default {
    setOpenShareModal(state, value) {
        state.openShareModal = value;
    },
    setOpenRenameModal(state, value) {
        state.openRenameModal = value;
    },
    setOpenMoveModal(state, value) {
        state.openMoveModal = value;
    },
    setOpenDetailsModal(state, value) {
        state.openDetailsModal = value;
    },
    setOpenDeleteModal(state, value) {
        state.openDeleteModal = value;
    },
    setOpenNewFolderModal(state, value) {
        state.openNewFolderModal = value;
    },
    setOpenNewFileUploadModal(state, value) {
        state.openNewFileUploadModal = value;
    },
    setFolderList(state, value) {
        state.folderList = value;
    },
    setFileList(state, value) {
        state.fileList = value;
    },
    setIsPropertyDocument(state, value) {
        state.isPropertyDocument = value;
    },
    setOpenedFolder(state, value) {
        state.openedFolder = value;
    },
    setSelectedDocument(state, value) {
        state.selectedDocument = value;
    },
    setOpenDocumentBillingModal(state, value) {
        state.openDocumentBillingModal = value;
    },
    setDocumentList(state, value) {
        state.documentList = value;
    },
};
