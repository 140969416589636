export default {
    setTypeWorkstation(state, value) {
        state.typeWorkstation = value;
    },
    setSelectedWorkstationDetail(state, value) {
        state.selectedWorkstationDetail = value;
    },
    setUserWorkstations(state, value) {
        state.userWorkstations = value;
    },
    setActiveUserWorkstation(state, value) {
        state.activeUserWorkstation = value;
    },
    clearActiveUserWorkstation(state) {
        state.activeUserWorkstation = null;
    }
};
