export default {
    activeJobs: [],
    activeQuotes: [],
    graphSeriesDataLoaded: false,
    wallet: {},
    boxDetails: [],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    openMoneyInRightbar: false,
    openMoneyInPayManuallyRightbar: false,
    openMoneyInSuccessRightbar: false,
    openMoneyOutRightbar: false,
    openMoneyOutVerifyRightbar: false,
    openMoneyOutSuccessRightbar: false,
    openRegisterInterestModalConfirmation: false,
    openThankyouRegisterInterestModal: false,
    openCurrentMembersPlanModal: false,
    openConfirmDeleteMembersPlanModal: false,
    openTransactionDetailsDrawer: false,
    openMoneyInDrawer: false,
    openMoneyOutDrawer: false,
    withdrawalForm: {}
};
