import $axios from '@/core/utils/axios-api-config';
export default {
    /**
     * Get user's comtractors
     * @param userId number
     * @returns []
     */
    getUserContractors(userId) {
        return $axios.get(`/user/${userId}/contractors`);
    },
    /**
     * Create user contractor
     * @param userId number
     * @param userContractor UserContractor
     * @returns UserContractor[]
     */
    createUserContractor(userId, userContractor) {
        return $axios.post(`user/${userId}/contractors`, userContractor);
    },
    /**
     * Update user contractor
     * @param userId number
     * @param userContractorId number
     * @param userContractor UserContractor
     * @returns UserContractor
     */
    updateUserContractor(userId, userContractorId, userContractor) {
        return $axios.put(`user/${userId}/contractors/${userContractorId}`, userContractor);
    },
    /**
     * Delete user contractor
     * @param userId number
     * @param userContractorId number
     * @param userContractor UserContractor
     * @returns object
     */
    deleteUserContractor(userId, userContractorId) {
        return $axios.delete(`/user/${userId}/contractors/${userContractorId}`);
    },
};
