export const WORKSTATION_SETUP_ROUTE = 'workstation-setup';
export default {
    path: '/workstation-setup',
    name: WORKSTATION_SETUP_ROUTE,
    components: {
        default: () => import('@/modules/workstation-setup/pages/WorkstationSetupPage.vue'),
    },
    meta: {
        title: 'Your iknowa Workstation Setup',
        useSecondaryHeader: true
    },
};
