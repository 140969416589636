export const USER_PUBLIC_PROFILE_CACHE = 'userPublicProfile';
export const USER_PUBLIC_GALLERY_CACHE = 'userPublicGallery';
export const USER_CERTIFICATES_CACHE = 'userCertificates';
export const USER_INSURANCES_CACHE = 'userCertificates';
export const USER_RATING_REVIEWS_CACHE = 'userRatingReviews';
export const PROJECTS_CACHE = 'projects';
export const PROJECT_QUOTES_CACHE = 'projectQuotes';
export const PROJECT_STAGES_CACHE = 'projectStages';
export const USER_MESSAGES_CACHE = 'userMessages';
export const USER_DOCUMENTS_CACHE = 'userDocuments';
export const USER_MESSAGE_GROUP_CACHE = 'userMessageGroup';
export const PROPERTY_INSURANCE_CACHE = 'propertyInsurance';
