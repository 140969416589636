const VIDEO_CALL = 'VIDEO_CALL';
const IN_PERSON = 'IN_PERSON';
const STATUS_CONFIRMED = 'CONFIRMED';
const STATUS_PENDING = 'PENDING';
export default {
    VIDEO_CALL,
    IN_PERSON,
    STATUS_CONFIRMED,
    STATUS_PENDING
};
