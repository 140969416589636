export default {
    currentJourney: 'Status',
    journeyCompleted: [],
    status: {
        type: '',
        standardType: '',
        standardDate: [],
        routineDate: '',
        routineDuration: 0
    },
    invite: {
        members: []
    },
    description: {
        name: '',
        description: ''
    },
    projectBudget: {
        min: 0,
        max: 250000,
        hasChanged: 0
    },
    categories: [],
    unsavedProjectMembers: [],
    addedProjectMembers: false,
    leavePageModal: false,
    cancelPageModal: false,
    postProjectConfirmationModal: false
};
