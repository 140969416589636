import { ProjectStageStatusEnum } from '@/core/packages/shared-library';
import ProjectStagesTasksService from '@/core/services/project-stages-tasks.service';
export default {
    /**
     * Update project quote stage status
     * @param projectQuoteId
     * @param projectStageId
     * @param formData
     * @returns
     */
    updateProjectStageStatus(context, { projectQuoteId, projectStageId, formData }) {
        return ProjectStagesTasksService.updateProjectStageStatus(projectQuoteId, projectStageId, formData);
    },
    /**
     * Update project quote stage task status
     * @param projectQuoteId
     * @param projectStageId
     * @param projectStageTaskId
     * @param projectStageTaskStatus
     * @returns
     */
    updateProjectStageTaskStatus(context, { projectQuoteId, projectStageId, projectStageTaskId, projectStageTaskStatus }) {
        return ProjectStagesTasksService.updateProjectStageTaskStatus(projectQuoteId, projectStageId, projectStageTaskId, projectStageTaskStatus);
    },
    /**
     * Update project quote stage details
     * @param projectQuoteId
     * @param projectStageId
     * @param formData
     * @returns
     */
    updateProjectStage(context, { projectQuoteId, projectStageId, formData }) {
        return ProjectStagesTasksService.updateProjectStage(projectQuoteId, projectStageId, formData);
    },
    /**
     * Delete project stage attachment
     * @param projectQuoteId
     * @param projectStageId
     * @param projectStageAttachmentId
     * @returns
     */
    deleteProjectStageAttachment(context, { projectQuoteId, projectStageId, projectStageAttachmentId }) {
        return ProjectStagesTasksService.deleteProjectStageAttachment(projectQuoteId, projectStageId, projectStageAttachmentId);
    },
    /**
     * Check if all project stages are completed
     * @param projectQuoteId
     * @param projectStageId
     * @param formData
     * @returns
     */
    checkAllProjectStagesIfCompleted(context, projectStages) {
        let hasInProgressStage = true;
        if (projectStages) {
            hasInProgressStage = projectStages.some((p) => p.projectStageStatus?.id !== ProjectStageStatusEnum.STAGE_COMPLETE);
        }
        return hasInProgressStage;
    },
};
