export default {
    setNotificationList(state, data) {
        state.notificationList = data.reverse();
    },
    pushNotificationItem(state, record) {
        state.notificationList.unshift(record);
    },
    setToggleDrawer(state) {
        state.drawerOpen = !state.drawerOpen;
    },
    setHasNewNotifications(state, value) {
        state.hasNewNotifications = value;
    },
    updateNotificationState(state, record) {
        const index = state.notificationList.indexOf(state.notificationList.find((n) => n.id === record.id));
        state.notificationList[index].state = record.state;
    },
    removeNotification(state, record) {
        const index = state.notificationList.indexOf(state.notificationList.find((notification) => notification.id === record.id));
        state.notificationList.splice(index, 1);
    }
};
