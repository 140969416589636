import { RolesEnum } from '@/core/packages/shared-library';
import ManageJobsRoute from '@/modules/manage-job-children/routes';
export const MANAGE_JOB_ROUTE = 'manage-job';
export default {
    path: '/manage-jobs/',
    name: MANAGE_JOB_ROUTE,
    redirect: '/manage-jobs/jobs',
    components: {
        default: () => import('@/modules/manage-job/pages/ManageJobPage.vue'),
        headerMenu: () => import('@/modules/manage-job/components/ManageJobHeaderMenu.vue')
        // headerMenu: () => import('@/core/components/project/ProjectDetailsMenu.vue')
    },
    children: [
        ...ManageJobsRoute
    ],
    meta: {
        // title: 'Manage Jobs',
        authorize: [RolesEnum.TRADESPERSON],
    },
};
