const emailPrefix = 'iknowa.alias.';
export default {
    IsJsonString(value) {
        try {
            JSON.parse(value);
        }
        catch (e) {
            return false;
        }
        return true;
    },
    cleanPaymentReference(value) {
        return value.replace(/_/g, '').substring(0, 18);
    },
    isAliased(value) {
        return value.indexOf('iknowa.alias.') > -1;
    },
    removeEmailAlias(email) {
        return email.replace(emailPrefix, '');
    },
    isValidEmail(email) {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    capitalizeFirstLetter(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
};
