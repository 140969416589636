import { RolesEnum } from '@/core/packages/shared-library';
export default [
    {
        path: 'home',
        name: 'property-details/home',
        component: () => import('@/modules/property-details-children/pages/PropertyHomePage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'kyp',
        name: 'property-details/kyp',
        component: () => import('@/modules/property-details-children/pages/PropertyKypPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'documents',
        name: 'property-details/documents',
        component: () => import('@/modules/property-details-children/pages/PropertyDocumentsPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'members',
        name: 'property-details/members',
        component: () => import('@/modules/property-details-children/pages/PropertyMembersPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'wallet',
        name: 'property-details/wallet',
        component: () => import('@/modules/property-details-children/pages/PropertyWalletPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
    {
        path: 'projects',
        name: 'property-details/projects',
        component: () => import('@/modules/property-details-children/pages/PropertyProjectsPage.vue'),
        authorize: [RolesEnum.PROJECT_OWNER],
    },
];
