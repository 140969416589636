import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';
import submodules from './submodules/index';
export const PROJECT_QUOTES_STORE = 'projectQuotes';
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
    modules: {
        ...submodules
    }
};
