export default {
    setOpenConnectSmartMeterModal({ commit }, value) {
        commit('setOpenConnectSmartMeterModal', value);
    },
    setOpenConfirmationModal({ commit }, value) {
        commit('setOpenConfirmationModal', value);
    },
    setIsSmartMeterConnected({ commit }, value) {
        commit('setIsSmartMeterConnected', value);
    },
    setOpenUtilityModal({ commit }, value) {
        commit('setOpenUtilityModal', value);
    },
    setOpenEnergyEfficiencyModal({ commit }, value) {
        commit('setOpenEnergyEfficiencyModal', value);
    },
    setOpenEditPropertyDetailsModal({ commit }, value) {
        commit('setOpenEditPropertyDetailsModal', value);
    },
    setOpenEnergyEfficiencyRatingModalfalse({ commit }, value) {
        commit('setOpenEnergyEfficiencyRatingModalfalse', value);
    },
    setOpenFinanceModal({ commit }, value) {
        commit('setOpenFinanceModal', value);
    },
    setUtilities({ commit }, value) {
        commit('setUtilities', value);
    },
};
