import { RolesEnum } from '@/core/packages/shared-library';
import { PROJECTS_ROUTE } from '../../projects/routes';
/* Project Stage Deposit Route */
export const PROJECT_STAGE_DEPOSIT_ROUTE = 'project-stage-deposit';
export default {
    path: '/stage-deposit-payment',
    name: PROJECT_STAGE_DEPOSIT_ROUTE,
    props: (route) => ({ paymentInitiationId: route.query.paymentInitiationId }),
    component: () => import('@/modules/project-stages-tasks/pages/ProjectStageDepositPage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'Project Stage Deposit Payment',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: false
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-restricted-globals
        if (!to.query.paymentInitiationId) {
            next({ name: PROJECTS_ROUTE });
        }
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
