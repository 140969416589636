// Iknowa themes and app styles
import '@/assets/scss/themes/element-variables.scss';
// import 'element-plus/packages/theme-chalk/src/index.scss';
import 'element-plus/theme-chalk/src/display.scss';
// import '@/assets/scss/_global.scss';
import '@/assets/scss/app.scss';
import 'v-calendar/dist/style.css';
import ElementPlus from 'element-plus';
import VCalendar from 'v-calendar';
import { createApp } from 'vue';
import AsyncComputed from 'vue-async-computed';
import vueDebounce from 'vue-debounce';
import { dragscrollNext } from 'vue-dragscroll';
import InlineSvg from 'vue-inline-svg';
import appFilters from '@/filters';
import App from './App.vue';
import router from './router';
import store from './store';
const app = createApp(App);
// plugins
app.use(ElementPlus);
app.use(vueDebounce);
app.use(VCalendar, {
    componentPrefix: 'vc',
    titlePosition: 'left',
    masks: {
        weekdays: 'WWW'
    },
    screens: {
        mobile: '375px',
        tablet: '576px',
        laptop: '992px',
        desktop: '1200px',
    },
});
app.use(store);
app.use(router);
app.use(AsyncComputed);
app.directive('dragscroll', dragscrollNext);
// global filters
app.config.globalProperties.$filters = {
    ...appFilters,
};
// custom components
app.component('inline-svg', InlineSvg);
// inject Pusher plugin
/* app.use(PusherPlugin, {
  apiKey: PUSHER_API_KEY,
  options: {
    cluster: PUSHER_CLUSTER,
    authEndpoint: `${process.env.VUE_APP_API_URL}/user-notifications/authenticate`,
    auth: { headers: { Authorization: `Bearer ${getLocalStorageWithExpiry(AUTH_TOKEN)}` } },
  }
}); */
// mounts the vue into app
app.mount('#app');
export const appInstance = app;
