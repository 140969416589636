export default {
    jobClientForm: (state) => state.jobClientForm,
    confirmDeleteModal: (state) => state.confirmDeleteModal,
    confirmDeleteAddressModal: (state) => state.confirmDeleteAddressModal,
    selectedId: (state) => state.selectedId,
    selectedAddressId: (state) => state.selectedAddressId,
    selectedClient: (state) => state.selectedClient,
    selectedAddress: (state) => state.selectedAddress,
    addressForm: (state) => state.addressForm,
};
