import { createStore } from 'vuex';
import actions from './actions';
import getters from './getters';
import modules from './modules';
import mutations from './mutations';
import state from './state';
export default createStore({
    state,
    mutations,
    actions,
    getters,
    modules: {
        ...modules,
    },
});
