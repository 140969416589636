import preference from '@/core/constants/calendar';
export default {
    dateWithTime: [],
    scheduleForm: {
        preference: preference.IN_PERSON,
        schedule: null,
        note: '',
        recipients: [],
        type: '',
        title: '',
        address: '',
        meta: {}
    },
    defaultScheduleForm: {
        preference: preference.IN_PERSON,
        schedule: null,
        note: '',
        recipients: [],
        type: '',
        title: '',
        address: '',
        meta: {}
    },
    originalSchedule: null,
    viewEventDetailsModal: false,
};
//  selectedSchedule
