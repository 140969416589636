export default {
    setListingAmount(state, payload) {
        state.listingAmount = payload;
    },
    setHasPropertyListing(state, payload) {
        state.hasPropertyListing = payload;
    },
    setTotalMember(state, payload) {
        state.totalMember = payload;
    },
    setAddedMember(state, payload) {
        state.addedMember = payload;
    },
    setPaymentMethod(state, payload) {
        state.paymentMethod = payload;
    },
    setStoragePlan(state, payload) {
        state.storagePlan = payload;
    },
    setAccumulatedSize(state, payload) {
        state.accumulatedSize = payload;
    },
    setDefaultTrialDayse(state, payload) {
        state.defaultTrialDays = payload;
    },
    setDefaultPropertyBillingAmount(state, payload) {
        state.defaultPropertyBillingAmount = payload;
    }
};
