import $axios from '@/core/utils/axios-api-config';
export default {
    getMyJobTemplates() {
        return $axios.get('/job-templates');
    },
    getJobTemplate(id) {
        return $axios.get(`/job-templates/${id}`);
    },
    createMyJobTemplate(formData) {
        return $axios.post('/job-templates', formData);
    },
    updateMyJobTemplate(id, formData) {
        return $axios.put(`/job-templates/${id}`, formData);
    },
    deleteMyJobTemplate(id) {
        return $axios.delete(`/job-templates/${id}`);
    },
};
