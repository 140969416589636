import { ProjectQuoteStatusEnum } from '@/core/packages/shared-library';
import QuoteService from '@/core/services/project-quotes.service';
import ProjectsService from '@/core/services/projects.service';
export default {
    async initialize({ commit, dispatch }, payload) {
        try {
            const activeQuotes = await dispatch('getActiveQuotes', payload.userId);
            if (activeQuotes) {
                commit('setActiveQuotes', activeQuotes.data || []);
            }
            const activeJobs = await dispatch('getActiveJobs', payload.userId);
            if (activeJobs) {
                commit('setActiveJobs', activeJobs.data || []);
            }
            /* const incomingJobs = await dispatch('getIncomingJobs', payload.userId);
      
            if (incomingJobs) {
              const currentActiveJobs = getters.activeJobs || [];
      
              commit('setActiveJobs', currentActiveJobs.concat(incomingJobs.items || []));
            } */
            commit('setGraphSeriesDataLoaded', true);
            return true;
        }
        catch (e) {
            return Promise.reject(e);
        }
    },
    setTotalWithdrawal({ commit }, transactionWithdraw) {
        // Calculate Total Withdrawals
        commit('setTotalWithdrawal', transactionWithdraw);
    },
    getActiveQuotes() {
        return ProjectsService.getProjectPageList({
            projectQuoteStatusIds: [ProjectQuoteStatusEnum.QUOTE_SUBMITTED, ProjectQuoteStatusEnum.QUOTE_ACCEPTED]
        });
    },
    getActiveJobs() {
        return ProjectsService.getProjectPageList({
            projectQuoteStatusIds: [ProjectQuoteStatusEnum.IN_PROGRESS, ProjectQuoteStatusEnum.QUOTE_CONFIRMED]
        });
    },
    getIncomingJobs(context, userId) {
        return QuoteService.getProjectQuotes({
            projectQuoteCreatorId: userId,
            projectQuoteStatusId: ProjectQuoteStatusEnum.QUOTE_CONFIRMED
        });
    },
    setMoneyInRightbar({ commit }, value) {
        commit('setMoneyInRightbar', value);
    },
    setMoneyInPayManuallyRightbar({ commit }, value) {
        commit('setMoneyInPayManuallyRightbar', value);
    },
    setMoneyInSuccessRightbar({ commit }, value) {
        commit('setMoneyInSuccessRightbar', value);
    },
    setMoneyOutRightbar({ commit }, value) {
        commit('setMoneyOutRightbar', value);
    },
    setMoneyOutVerifyRightbar({ commit }, value) {
        commit('setMoneyOutVerifyRightbar', value);
    },
    setMoneyOutSuccessRightbar({ commit }, value) {
        commit('setMoneyOutSuccessRightbar', value);
    },
    setOpenRegisterInterestModalConfirmation({ commit }, value) {
        commit('setOpenRegisterInterestModalConfirmation', value);
    },
    setOpenThankyouRegisterInterestModal({ commit }, value) {
        commit('setOpenThankyouRegisterInterestModal', value);
    },
    setOpenCurrentMembersPlanModal({ commit }, value) {
        commit('setOpenCurrentMembersPlanModal', value);
    },
    setOpenConfirmDeleteMembersPlanModal({ commit }, value) {
        commit('setOpenConfirmDeleteMembersPlanModal', value);
    },
    setOpenTransactionDetailsDrawer({ commit }, value) {
        commit('setOpenTransactionDetailsDrawer', value);
    },
    setOpenMoneyInDrawer({ commit }, value) {
        commit('setOpenMoneyInDrawer', value);
    },
    setOpenMoneyOutDrawer({ commit }, value) {
        commit('setOpenMoneyOutDrawer', value);
    },
    setWithdrawalForm({ commit }, withdrawalForm) {
        commit('setWithdrawalForm', withdrawalForm);
    }
};
