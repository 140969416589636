export default {
    folderList: (state) => state.folderList,
    isPropertyDocument: (state) => state.isPropertyDocument,
    fileList: (state) => state.fileList,
    openedFolder: (state) => state.openedFolder,
    selectedDocument: (state) => state.selectedDocument,
    openShareModal: (state) => state.openShareModal,
    openRenameModal: (state) => state.openRenameModal,
    openMoveModal: (state) => state.openMoveModal,
    openDetailsModal: (state) => state.openDetailsModal,
    openDeleteModal: (state) => state.openDeleteModal,
    openNewFolderModal: (state) => state.openNewFolderModal,
    openNewFileUploadModal: (state) => state.openNewFileUploadModal,
    openDocumentBillingModal: (state) => state.openDocumentBillingModal,
    documentList: (state) => state.documentList,
};
