/**
 * Login route page
 */
export const LOGIN_ROUTE = 'login';
export default {
    path: '/login',
    name: LOGIN_ROUTE,
    component: () => import('@/modules/login/pages/LoginPage.vue'),
    meta: {
        validateAuth: true,
    }
};
