import { AUTH_TOKEN, AUTH_USER } from '@/core/constants';
import { getLocalStorageWithExpiry } from '../../../core/utils/common';
export default {
    /**
     * Checks if user is authenticated.
     * NOTE: AUTH_TOKEN will eventually set to null if expired. Handled in core/utils/axios-api-config.ts
     * @returns
     */
    isAuthenticated() {
        return !!getLocalStorageWithExpiry(AUTH_TOKEN);
    },
    authenticatedUser() {
        return getLocalStorageWithExpiry(AUTH_USER);
    },
    forgotPasswordTempUser: (state) => state.forgotPasswordTempUser,
};
