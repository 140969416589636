export default {
    setListingAmount({ commit }, value) {
        commit('setListingAmount', value);
    },
    setHasPropertyListing({ commit }, value) {
        commit('setHasPropertyListing', value);
    },
    setTotalMember({ commit }, value) {
        commit('setTotalMember', value);
    },
    setAddedMember({ commit }, value) {
        commit('setAddedMember', value);
    },
    setPaymentMethod({ commit }, value) {
        commit('setPaymentMethod', value);
    },
    setStoragePlan({ commit }, value) {
        commit('setStoragePlan', value);
    },
    setAccumulatedSize({ commit }, value) {
        commit('setAccumulatedSize', value);
    },
    setDefaultTrialDayse({ commit }, value) {
        commit('setDefaultTrialDayse', value);
    },
    setDefaultPropertyBillingAmount({ commit }, value) {
        commit('setDefaultPropertyBillingAmount', value);
    }
};
