import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import UserService from '@/core/services/users.service';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
// import { USERS_STORE } from '@/store/modules/users';
import { AUTHENTICATION_STORE } from '../authentication';
export default {
    /**
     * Get the user profile by token itself
     * @param userId
     * @returns {User} model
     */
    async getUser({ dispatch }, userId) {
        let id = userId;
        // we check ahead first as it is used in login as well, to prevent blocking error
        const isHosted = window.localStorage.getItem('hst');
        const viewed = window.localStorage.getItem('vw');
        if (isHosted && !viewed) {
            id = await dispatch('getAndIdentifyUser');
        }
        return UserService.getUser(id)
            .then((user) => {
            if (!user)
                return null;
            return user;
        });
    },
    updateUser(context, { userId, user }) {
        return UserService.updateUser(userId, user);
    },
    selectAccount(context, data) {
        return UserService.selectAccount(data);
    },
    selectRole(context, data) {
        return UserService.selectRole(data);
    },
    resetPassword(context, email) {
        return UserService.resetPassword(email);
    },
    /**
     * Set local storage for user
     * @param context
     * @param user
     */
    saveUserState({ commit }, user) {
        if (user) {
            commit('setUser', user);
            return user;
        }
        return null;
    },
    getAndIdentifyUser({ rootGetters }) {
        const authenticatedUser = rootGetters[`${AUTHENTICATION_STORE}/authenticatedUser`];
        const { userId } = authenticatedUser;
        let id = userId;
        const isHosted = window.localStorage.getItem('hst');
        if (isHosted) {
            const activeWs = rootGetters[`${USER_WORKSTATION}/activeUserWorkstation`];
            if (activeWs?.user?.id) {
                id = activeWs.user?.id;
            }
        }
        return id;
    },
    /**
     * Initialize user and set the
     * @param param
     * @returns
     */
    async initializeUserState({ commit, dispatch }) {
        const userId = await dispatch('getAndIdentifyUser');
        return UserService.getUser(userId)
            .then((user) => {
            if (!user)
                return null;
            commit('setUser', user);
            return user;
        });
    },
    setUser({ commit }, userId) {
        return UserService.getUser(userId)
            .then((user) => {
            if (!user)
                return null;
            commit('setUser', user);
            return user;
        });
    },
    setIsHosted({ commit }, value) {
        commit('setIsHosted', value);
    },
    setInvitedUser({ commit }, value) {
        commit('setInvitedUser', value);
    },
    setViewedUser({ commit }, value) {
        commit('setViewedUser', value);
    },
    setWsRole({ commit }, value) {
        commit('setWsRole', value);
    },
    getUserProjectAccountBalance(context, userId) {
        return UserService.getUserProjectAccountBalance(userId);
    },
    getUserOutstandingBalance(context, userId) {
        return UserService.getUserOutstandingBalance(userId);
    },
    getUserTransactionLogs(context, params) {
        const { userId, options } = params;
        return UserService.getUserTransactionLogs(userId, options);
    },
    getUserTransactionStatement(context, payload) {
        const { userId, formData } = payload;
        return UserService.getUserTransactionStatement(userId, formData);
    },
    /**
     * Get account statuses
     * @param context
     * @returns
     */
    async getAccountStatuses({ commit, dispatch }) {
        const userId = await dispatch('getAndIdentifyUser');
        return UserService.getAccountStatuses(userId)
            .then((statuses) => {
            commit('setAccountStatuses', statuses);
            let profileStatus = false;
            if (statuses && statuses.forms) {
                const totalForms = Object.values(statuses.forms).length;
                const checkAllFormsVerified = Object.values(statuses.forms).reduce((acc, form) => {
                    return (form === UserVerificationStatusEnum.PENDING_VERIFICATION || form === UserVerificationStatusEnum.VERIFIED) ? acc + 1 : acc;
                }, 0);
                profileStatus = checkAllFormsVerified >= totalForms;
            }
            commit('setIsProfileVerifiable', profileStatus);
            return statuses;
        });
    },
    /**
     * Activate User account
     * @param context
     * @param userId
     * @returns
     */
    activateUserAccount(context, userId) {
        return UserService.activateUserAccount(userId);
    },
    /**
     * Save user profile
     * @param getters
     * @param payload
     * @returns
     */
    saveUserProfile({ getters }, payload) {
        const { userId, profile, forceCreate } = payload;
        if (!getters.userPublicProfile || forceCreate) {
            delete profile?.wsState;
            return UserService.createUserProfile(userId, profile);
        }
        return UserService.updateUserProfile(userId, getters.userPublicProfile.id, profile);
    },
    /**
     * Get user profile
     * @param context
     * @param userId
     * @returns GetUserPublicProfileResponseDto
     */
    getUserProfile(context, { userId, userPublicProfileId }) {
        return UserService.getUserProfile(userId, userPublicProfileId);
    },
    /**
     * Get user business details
     * @param context
     * @param userId
     * @param userBusinessDetailsId
     * @returns GetUserBusinessDetailsResponseDto
     */
    getBusinessDetails(context, { userId, userBusinessDetailsId }) {
        return UserService.getBusinessDetails(userId, userBusinessDetailsId);
    },
    /**
     * Update/Create user business details
     * @param getters
     * @param userId
     * @param updateUserBusinessDetailsRequestDto
     * @returns CreateUserBusinessDetailsResponseDto | UpdateUserBusinessDetailsResponseDto
     */
    saveBusinessDetails({ getters }, { userId, formData, forceCreate }) {
        if (!getters.userBusinessDetails?.length || forceCreate) {
            return UserService.createBusinessDetails(userId, formData);
        }
        return UserService.updateBusinessDetails(userId, getters.userBusinessDetails[0].id, formData);
    },
    updateBusinessDetails(context, { id, userId, formData }) {
        return UserService.updateBusinessDetails(userId, id, formData); // test
    },
    /**
     * Initiate user identification process
     * @param context
     * @param userId
     * @returns InitiateIdentificationVerificationResponseDto
     */
    initiateUserIdentification(context, { userId }) {
        return UserService.initiateUserIdentification(userId);
    },
    /**
     * Get user identification acess
     * @param context
     * @param userId
     * @returns InitiateIdentificationVerificationResponseDto
     */
    getUserIdentificationAccess(context, { userId }) {
        return UserService.getUserIdentificationAccess(userId);
    },
    /**
     * Get user identification details
     * @param context
     * @param userId
     * @param userIdentificationId
     * @returns GetUserIdentificationResponseDto
     */
    getUserIdentification(context, { userId, userIdentificationId }) {
        return UserService.getUserIdentification(userId, userIdentificationId);
    },
    /**
     * Get user certificates
     * @param context
     * @param userId
     * @returns UserCertificateResponseDto[]
     */
    getUserCertificates({ commit }, userId) {
        return UserService.getUserCertificates(userId)
            .then((certificates) => {
            commit('setCertificates', certificates);
            return certificates;
        });
    },
    /**
     * Create new user certificate details
     * @param commit
     * @param certificate
     * @returns CreateUserCertificateRequestDto
     */
    addNewCertificate({ commit }, certificate) {
        return UserService.createCertificate(certificate)
            .then((response) => {
            commit('setNewUserCertificate', response);
            return response;
        });
    },
    /**
     * Update user certificate details
     * @param context
     * @param userId
     * @param userCertificateId
     * @param certificate
     * @param updateUserBusinessDetailsRequestDto
     * @returns UserCertificateResponseDto
     */
    updateUserCertificate(context, payload) {
        const { userId, certificate, id } = payload;
        return UserService.updateCertificate(userId, id, certificate);
    },
    /**
     * Remove/Delete user certificate details
     * @param context
     * @param payload
     * @returns number
     */
    removeUserCertificates(context, payload) {
        const { userId, userCertificateId } = payload;
        return UserService.deleteCertificate(userId, userCertificateId)
            .then((response) => {
            context.commit('removeUserCertificates', userCertificateId);
            return response;
        });
    },
    /**
     * Get user insurances
     * @param commit
     * @param userId
     * @returns UserInsuranceResponseDto[]
     */
    getUserInsurances({ commit }, userId) {
        return UserService.getUserInsurances(userId)
            .then((insurances) => {
            commit('setInsurances', insurances);
            return insurances;
        });
    },
    /**
     * Create new user insurance details
     * @param commit
     * @param createUserInsuranceRequestDto
     * @returns UserInsuranceResponseDto
     */
    addNewInsurance({ commit }, createUserInsuranceRequestDto) {
        return UserService.createUserInsurance(createUserInsuranceRequestDto)
            .then((response) => {
            commit('setNewUserInsurance', response);
            return response;
        });
    },
    /**
     * Update user insurance details
     * @param context
     * @param userId
     * @param userInsuranceId
     * @param insurance
     * @returns UserInsuranceResponseDto
     */
    updateUserInsurance(context, payload) {
        const { userId, insurance, id } = payload;
        return UserService.updateUserInsurance(userId, id, insurance);
    },
    /**
     * Remove/Delete user insurance details
     * @param context
     * @param payload
     * @returns number
     */
    removeUserInsurance({ commit }, payload) {
        const { userId, userInsuranceId } = payload;
        return UserService.deleteUserInsurance(userId, userInsuranceId)
            .then((response) => {
            if (response === 1) {
                commit('removeUserInsurance', userInsuranceId);
            }
            return response;
        });
    },
    /**
     * Update/Create user skill categories
     * @param context
     * @param userId
     * @returns UpdateUserSkillsCategoryResponseDto
     */
    saveUserSkills(context, payload) {
        const { userId, skills } = payload;
        return UserService.saveUserSkills(userId, skills);
    },
    /**
     * Get user skill categories
     * @param context
     * @param userId
     * @returns UserSkills
     */
    getUserSkills(context, userId) {
        return UserService.getUserSkills(userId);
    },
    /**
     * Get user skill categories verification status
     * @param userId
     * @returns UserSkillVerification
     */
    getUserVerificationStatus(context, userId) {
        return UserService.getUserSkillCategoriesVerificationStatus(userId);
    },
    verifyUserAccount(context, { formData, userId }) {
        return UserService.verifyWithdrawal(userId, formData);
    },
    sendVerificationWithdrawal(context, userId) {
        return UserService.sendVerificationWithdrawal(userId);
    },
    createUserRating(context, payload) {
        const { toUserId, rating } = payload;
        return UserService.createUserRating(toUserId, rating);
    },
    getUserRatingsPageList(context, payload) {
        const { userId, filter } = payload;
        return UserService.getUserRatingsPageList(userId, filter);
    },
    getUserRatingSummary(context, userId) {
        return UserService.getUserRatingSummary(userId);
    },
    getUserCustomerAccount(context, userId) {
        return UserService.getUserCustomerAccount(userId);
    },
    getUserCustomerAccountDetails(context, payload) {
        const { userId, accountId } = payload;
        return UserService.getUserCustomerAccountDetails(userId, accountId);
    },
    updateTransactionLogLastTimeCategory({ commit }, value) {
        commit('setTransactionLogLastTimeCategory', value);
    },
    updateAccountSetupCache({ commit }, value) {
        commit('setAccountSetupCache', value);
    },
    async updateUserAccountSetupCache({ getters, dispatch }) {
        const { user } = getters;
        const { accountSetupCache } = getters;
        let formData = {
            accountSetupCache: null
        };
        if (accountSetupCache && Object.keys(accountSetupCache).length) {
            formData = {
                accountSetupCache: JSON.stringify(accountSetupCache)
            };
        }
        await dispatch('updateUser', { user: formData, userId: user.id });
    },
    createUserWorkstation(context, payload) {
        const { userId, userWorkstation } = payload;
        return UserService.createUserWorkstation(userId, userWorkstation);
    },
    setCohort({ commit }, value) {
        commit('setCohort', value);
    },
    setNotInMainLayout({ commit }, value) {
        commit('setNotInMainLayout', value);
    },
    setPassportOverview({ commit }, value) {
        commit('setPassportOverview', value);
    },
    setMainLayoutInitialized({ commit }, value) {
        commit('setMainLayoutInitialized', value);
    },
};
