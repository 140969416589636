import ProjectStageTaskMemberService from '@/core/services/project-stage-task-member.service';
export default {
    getStageTaskMembers(context, taskId) {
        return ProjectStageTaskMemberService.getStageTaskMembers(taskId);
    },
    createStageTaskMembers(context, params) {
        const { taskId, data } = params;
        return ProjectStageTaskMemberService.createStageTaskMembers(taskId, data);
    }
};
