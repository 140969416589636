import { RolesEnum } from '@/core/packages/shared-library';
/**
 * Create property map route
 */
export const CREATE_PROPERTY_MAP_ROUTE = 'find-property';
export default {
    path: '/find-property',
    name: CREATE_PROPERTY_MAP_ROUTE,
    component: () => import('@/modules/create-property-map/pages/CreatePropertyMapPage.vue'),
    meta: {
        isRequireAuth: true,
        isValidateAuth: true,
        title: 'List new property',
        authorize: [RolesEnum.PROJECT_OWNER],
        showBackButton: true
    },
    beforeEnter: (to, from, next) => {
        // eslint-disable-next-line no-param-reassign
        to.meta.previousRoute = from || to;
        next();
    }
};
