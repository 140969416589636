import permissionConstant from '@/core/constants/permissions';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
export default {
    hasMainLayoutInitialized: (state) => state.hasMainLayoutInitialized,
    user: (state) => state.user,
    accountStatuses: (state) => state.accountStatuses,
    userCertificates: (state) => state.userCertificates,
    userInsurances: (state) => state.userInsurances,
    userBusinessDetails: (state) => state.user.userBusinessDetails,
    userPublicProfile: (state) => state.user.userPublicProfile,
    userTransactionLogLastTimeCategory: (state) => state.user.userTransactionLogLastTimeCategory,
    isProfileVerifiable: (state) => state.isProfileVerifiable,
    canUserAddWorkstation: (state) => state.canUserAddWorkstation,
    isCohort: (state) => state.isCohort,
    isHosted: (state) => state.isHosted,
    isOnPassportOverview: (state) => state.isOnPassportOverview,
    invitedUser: (state) => state.invitedUser,
    viewedUser: (state) => state.viewedUser,
    wsRole: (state) => state.wsRole,
    notInMainLayout: (state) => state.notInMainLayout,
    isWsAdmin: (state) => {
        return state.wsRole === permissionConstant.ADMIN_LABEL;
    },
    isWsEditor: (state) => {
        return state.wsRole === permissionConstant.EDIT_LABEL;
    },
    isWsViewer: (state) => {
        return state.wsRole === permissionConstant.VIEW_LABEL;
    },
    accountSetupCache: (state) => {
        const { user } = state;
        let data = {};
        if (user && user.accountSetupCache) {
            data = JSON.parse(user.accountSetupCache);
        }
        return data;
    },
    isUserVerificationActive: (state) => {
        let active = false;
        const statuses = state.accountStatuses;
        if (statuses && statuses.forms) {
            const totalForms = Object.values(state.accountStatuses.forms).length;
            const checkAllFormsVerified = Object.values(state.accountStatuses.forms).reduce((acc, form) => {
                return (form === UserVerificationStatusEnum.PENDING_VERIFICATION || form === UserVerificationStatusEnum.VERIFIED) ? acc + 1 : acc;
            }, 0);
            active = checkAllFormsVerified >= totalForms;
            return active;
        }
        return active;
    },
    certificateVerificationState: (state, getters) => {
        const { userCertificates } = state;
        const totalUserCertificates = userCertificates.length;
        if (totalUserCertificates === 0) {
            return UserVerificationStatusEnum.NOT_ADDED;
        }
        const pendingCertificateState = getters.pendingCertificateVerificationState;
        const verifiedCertificateState = getters.completedCertificateVerificationState;
        const rejectCertificateState = getters.rejectedCertificateVerificationState;
        if (rejectCertificateState.length) {
            return UserVerificationStatusEnum.REJECTED;
        }
        if (pendingCertificateState.length) {
            return UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
        if (totalUserCertificates === verifiedCertificateState.length) {
            return UserVerificationStatusEnum.VERIFIED;
        }
        return null;
    },
    pendingCertificateVerificationState(state) {
        return state.userCertificates.filter((certificate) => certificate.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION);
    },
    rejectedCertificateVerificationState(state) {
        return state.userCertificates.filter((certificate) => certificate.userVerificationStatus === UserVerificationStatusEnum.REJECTED);
    },
    completedCertificateVerificationState(state) {
        return state.userCertificates.filter((certificate) => certificate.userVerificationStatus === UserVerificationStatusEnum.VERIFIED);
    },
    insuranceVerificationState(state, getters) {
        const { userInsurances } = state;
        const totalUserInsurances = userInsurances.length;
        if (totalUserInsurances === 0) {
            return UserVerificationStatusEnum.NOT_ADDED;
        }
        const pendingInsuranceState = getters.pendingInsuranceVerificationState;
        const verifiedInsuranceState = getters.completedInsuranceVerificationState;
        const rejectInsuranceState = getters.rejectedInsuranceVerificationState;
        if (rejectInsuranceState.length) {
            return UserVerificationStatusEnum.REJECTED;
        }
        if (pendingInsuranceState.length) {
            return UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
        if (totalUserInsurances === verifiedInsuranceState.length) {
            return UserVerificationStatusEnum.VERIFIED;
        }
        return null;
    },
    pendingInsuranceVerificationState(state) {
        return state.userInsurances.filter((insurance) => insurance.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION);
    },
    rejectedInsuranceVerificationState(state) {
        return state.userInsurances.filter((insurance) => insurance.userVerificationStatus === UserVerificationStatusEnum.REJECTED);
    },
    completedInsuranceVerificationState(state) {
        return state.userInsurances.filter((insurance) => insurance.userVerificationStatus === UserVerificationStatusEnum.VERIFIED);
    }
};
