export const LOCKED_PAGE_ROUTE = 'locked-page';
export default {
    path: '/locked-page',
    name: LOCKED_PAGE_ROUTE,
    components: {
        default: () => import('@/modules/locked-page/pages/LockedPage.vue'),
    },
    meta: {
        noHeader: true
    },
};
