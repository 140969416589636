export default {
    setTotalNewMessages(state, total) {
        state.totalNewMessages = total;
    },
    setMobileToggle(state) {
        state.mobileBackToggle = !state.mobileBackToggle;
    },
    setOpenCreateMicroTaskModal(state, value) {
        state.openCreateMicroTaskModal = value;
    },
};
